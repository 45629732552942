/* eslint-disable*/
import * as THREE from 'three';
import { CSG } from 'three-csg-ts';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
import { boundingBox } from './helperFiles/boundingBoxCheck.js';
function generateWall(wallData, average, group, isMetric, bbox, objectOpacity){
    const lb1 = new THREE.Vector3(parseFloat(wallData.LeftBottomP1.split(",")[0]), parseFloat(wallData.LeftBottomP1.split(",")[2]), parseFloat(wallData.LeftBottomP1.split(",")[1]));
    const lb2 = new THREE.Vector3(parseFloat(wallData.LeftBottomP2.split(",")[0]), parseFloat(wallData.LeftBottomP2.split(",")[2]), parseFloat(wallData.LeftBottomP2.split(",")[1]));
    const rb1 = new THREE.Vector3(parseFloat(wallData.RightBottomP1.split(",")[0]), parseFloat(wallData.RightBottomP1.split(",")[2]), parseFloat(wallData.RightBottomP1.split(",")[1]));
    const rb2 = new THREE.Vector3(parseFloat(wallData.RightBottomP2.split(",")[0]), parseFloat(wallData.RightBottomP2.split(",")[2]), parseFloat(wallData.RightBottomP2.split(",")[1]));
    const lt1 = new THREE.Vector3(parseFloat(wallData.LeftTopP1.split(",")[0]), parseFloat(wallData.LeftTopP1.split(",")[2]), parseFloat(wallData.LeftTopP1.split(",")[1]));
    const lt2 = new THREE.Vector3(parseFloat(wallData.LeftTopP2.split(",")[0]), parseFloat(wallData.LeftTopP2.split(",")[2]), parseFloat(wallData.LeftTopP2.split(",")[1]));
    const rt1 = new THREE.Vector3(parseFloat(wallData.RightTopP1.split(",")[0]), parseFloat(wallData.RightTopP1.split(",")[2]), parseFloat(wallData.RightTopP1.split(",")[1]));
    const rt2 = new THREE.Vector3(parseFloat(wallData.RightTopP2.split(",")[0]), parseFloat(wallData.RightTopP2.split(",")[2]), parseFloat(wallData.RightTopP2.split(",")[1]));
    if(bbox){
        boundingBox(rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2, bbox);
    }
    const pointX1 = parseFloat(wallData.BoundaryPoints[0].split(",")[0]);
    const pointX2 = parseFloat(wallData.BoundaryPoints[1].split(",")[0]);
    const pointX3 = parseFloat(wallData.BoundaryPoints[2].split(",")[0]);
    const pointX4 = parseFloat(wallData.BoundaryPoints[3].split(",")[0]);
    const pointZ1 = parseFloat(wallData.BoundaryPoints[0].split(",")[1]);
    const pointZ2 = parseFloat(wallData.BoundaryPoints[1].split(",")[1]);
    const pointZ3 = parseFloat(wallData.BoundaryPoints[2].split(",")[1]);
    const pointZ4 = parseFloat(wallData.BoundaryPoints[3].split(",")[1]);
    // const vec = new THREE.Vector3(rb2.x - rb1.x, 0, rb2.z - rb1.z);
    // var vec1 = vec.normalize();
    // const radians = Math.atan2(-vec1.z, vec1.x);
    const y1 = wallData.AbsoluteElevationBottom;
    const y2 = wallData.Height + y1;
    let wall;
    let wallPreview = false;
    if(wallData.WhatIsThis === "WallPreview"){
        wallPreview = true;
    }
    let board;
    let outerBoard;
    let special = 0;
    let temp = wallData.MainWallSelectedColor;
    // let wallColor = temp.replace("#", "0x");
    let wallColor = 0xd8eced;
    let color = 0xd8eced;
    let edgePoints = [];
    let edgePointsOuter = [];
    let edges;
    let edgesOuter;
    if(wallData.Arc){
        wall = new THREE.Shape();
        wall.moveTo(parseFloat(wallData.BoundaryPoints[0].split(",")[0]), parseFloat(wallData.BoundaryPoints[0].split(",")[1]));
        edgePoints.push(new THREE.Vector3(parseFloat(wallData.BoundaryPoints[0].split(",")[0]), parseFloat(wallData.BoundaryPoints[0].split(",")[1]), 0));
        for(let j = 0; j < wallData.BoundaryPoints.length; j++){
            wall.lineTo(parseFloat(wallData.BoundaryPoints[j].split(",")[0]), parseFloat(wallData.BoundaryPoints[j].split(",")[1]));
            edgePoints.push(new THREE.Vector3(parseFloat(wallData.BoundaryPoints[j].split(",")[0]), parseFloat(wallData.BoundaryPoints[j].split(",")[1]), 0));
        }
        wall.lineTo(parseFloat(wallData.BoundaryPoints[0].split(",")[0]), parseFloat(wallData.BoundaryPoints[0].split(",")[1]));
        let extrudeSettings = {
            steps: 1,
            depth: Math.abs(y2 - y1),
            bevelEnabled: false,
        }
        const geometry = new THREE.ExtrudeGeometry(wall, extrudeSettings);
        geometry.computeBoundingBox();
        edges = new THREE.EdgesGeometry(geometry);
        board = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({color: wallColor,flatShading: true, side: THREE.DoubleSide}));
        board.position.set(0, y1 + Math.abs(y2 - y1), 0);
        board.rotation.x = Math.PI * 0.5; 
        board.position.setX(pointX1 - average.x);
        board.position.setZ(pointZ1 - average.z);
    }
    else {
        for(let i = 0; i < wallData.WallPositions.length; i++){
            let points = [];
            for (let j = 0; j < wallData.WallPositions[i].length; j++) {
              let point = {};
              point = {
                x: parseFloat(wallData.WallPositions[i][j].split(",")[0]) * 20,
                y: parseFloat(wallData.WallPositions[i][j].split(",")[1]) * 20,
                z: parseFloat(wallData.WallPositions[i][j].split(",")[2]) * 20,
              }
              points.push(point);
              edgePoints.push(new THREE.Vector3(point.x, point.y, point.z));
            //   Add the points to the scene 
            }
            let face;
            face = null;
            for(let j = 0; j < wallData.WallTriangleIndices[i].length; j+=3){
              let point1 = points[wallData.WallTriangleIndices[i][j]];
              let point2 = points[wallData.WallTriangleIndices[i][j+1]];
              let point3 = points[wallData.WallTriangleIndices[i][j+2]];
              let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
              let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
              let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
              const vertices = new Float32Array([
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z,
              ]);
              const geometry = new THREE.BufferGeometry();
              geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
              if(face == null){
                face = geometry;
              }
              else{
                if(geometry)
                    face = mergeBufferGeometries([face, geometry]);
              }
            }
            if(board == null){
              board = face;
            }
            else{
                if(face)
                    board = mergeBufferGeometries([board, face]);
            }
        }
        let yes = false;
        if(wallData.OuterWallPositions.length > 0){
            yes = true;
            for(let i = 0; i < wallData.OuterWallPositions.length; i++){
                let points = [];
                for (let j = 0; j < wallData.OuterWallPositions[i].length; j++) {
                    let point = {};
                    point = {
                        x: parseFloat(wallData.OuterWallPositions[i][j].split(",")[0]) * 20,
                        y: parseFloat(wallData.OuterWallPositions[i][j].split(",")[1]) * 20,
                        z: parseFloat(wallData.OuterWallPositions[i][j].split(",")[2]) * 20,
                    }
                    points.push(point);
                    edgePointsOuter.push(new THREE.Vector3(point.x, point.y, point.z));
                }
                let face;
                face = null;
                for(let j = 0; j < wallData.OuterWallTriangleIndices[i].length; j+=3){
                    let point1 = points[wallData.OuterWallTriangleIndices[i][j]];
                    let point2 = points[wallData.OuterWallTriangleIndices[i][j+1]];
                    let point3 = points[wallData.OuterWallTriangleIndices[i][j+2]];
                    let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
                    let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
                    let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
                    const vertices = new Float32Array([
                            pp1.x, pp1.y, pp1.z,
                            pp2.x, pp2.y, pp2.z,
                            pp3.x, pp3.y, pp3.z,
                    ]);
                    const geometry = new THREE.BufferGeometry();
                    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                    if(face == null){
                            face = geometry;
                    }
                    else{
                            face = mergeBufferGeometries([face, geometry]);
                    }
                }
                if(outerBoard == null){
                    outerBoard = face;
                }
                else{
                    outerBoard = mergeBufferGeometries([outerBoard, face]);
                }
            }
            outerBoard = new THREE.Mesh(outerBoard, new THREE.MeshPhongMaterial({color: 0xcb4154, flatShading: true, side: THREE.DoubleSide}));
            edgesOuter = new THREE.EdgesGeometry(outerBoard.geometry);
        }
        board = new THREE.Mesh(board, new THREE.MeshPhongMaterial({color: wallColor, flatShading: true, side: THREE.DoubleSide}));
        edges = new THREE.EdgesGeometry(board.geometry);
    }
    if(wallData.CornerPoints){
        for(let j = 0; j < wallData.OpeningPointsList.length; j++){
            const X5 = parseFloat(wallData.OpeningPointsList[j][0].split(",")[0]);
            const X6 = parseFloat(wallData.OpeningPointsList[j][1].split(",")[0]);
            const X7 = parseFloat(wallData.OpeningPointsList[j][2].split(",")[0]);
            const X8 = parseFloat(wallData.OpeningPointsList[j][3].split(",")[0]);
            const Z5 = parseFloat(wallData.OpeningPointsList[j][0].split(",")[1]);
            const Z6 = parseFloat(wallData.OpeningPointsList[j][1].split(",")[1]);
            const Z7 = parseFloat(wallData.OpeningPointsList[j][2].split(",")[1]);
            const Z8 = parseFloat(wallData.OpeningPointsList[j][3].split(",")[1]);
            // const Y5 = y1 + wallData.BottomOfWindow;
            // const Y6 = Y5 + wallData.OpenHeightList[j];
            if(wallData.OpeningTypeList[j] === 0 && wallData.HasPeakList === null){

                let wallCutout = new THREE.Shape();
                let extrudeSettings = {
                    steps: 2,
                    depth: wallData.Thickness,
                    bevelEnabled: false,
                }
                if((wallData.Height - wallData.OpenHeightList[j]) > 15){
                    let width;
                    let height = wallData.Height - wallData.OpenHeightList[j]; 
                    let positionX;
                    let positionZ;
                    let windowFrame;
                    let slope;
                    if(X5.toFixed(5) === X6.toFixed(5)){
                        width = Math.abs(Z5 - Z6); 
                        if(Z5 < Z6){
                        positionX = X5;
                        positionZ = Z5 - 3;
                        }
                        else if(Z5 > Z6){
                        positionX = X5 - 3;
                        positionZ = Z5;
                        }
                    }
                    else if(Z5.toFixed(5) === Z6.toFixed(5)){
                        width = Math.abs(X5 - X6);
                        if(X5 < X6){
                        positionX = X5 - 3;
                        positionZ = Z5 - 3;
                        }
                        else if(X5 > X6){
                        positionX = X5;
                        positionZ = Z5;
                        }
                    }
                    else {
                        const xHor = Math.abs(X5 - X6);
                        const zHor = Math.abs(Z5 - Z6);
                        width = Math.sqrt(xHor * xHor + zHor * zHor);
                        slope = Math.atan(zHor / xHor);
                        if(X5 < X6){
                        if(Z5 < Z6){
                            slope = Math.atan(zHor / xHor);
                            positionX = X5 + (3 * Math.sin(slope));
                            positionZ = Z5 - (3 * Math.cos(slope));
                            positionX -= (3 * Math.cos(slope));
                            positionZ -= (3 * Math.sin(slope));
                        }
                        else if(Z5 > Z6){
                            slope = -Math.atan(zHor / xHor);
                            positionX = X5 - (3 * Math.cos(slope));
                            positionZ = Z5 - (3 * Math.sin(slope));
                            positionX += (3 * Math.sin(slope));
                            positionZ -= (3 * Math.cos(slope));

                        }
                        }
                        else if(X5 > X6){
                        if(Z5 < Z6){
                            slope = -Math.atan(zHor / xHor) - Math.PI;
                            positionX = X5 + (3 * Math.sin(slope));
                            positionZ = Z5 - (3 * Math.cos(slope));
                            positionX -= (3 * Math.cos(slope));
                            positionZ -= (3 * Math.sin(slope));
                        }
                        else if(Z5 > Z6){
                            slope = Math.atan(zHor / xHor) + Math.PI;
                            positionX = X5 - (3 * Math.cos(slope));
                            positionZ = Z5 - (3 * Math.sin(slope));
                            positionX += (3 * Math.sin(slope));
                            positionZ -= (3 * Math.cos(slope));
                        }
                        }
                    }
                    const geometries = [];

                    (function generateWindowFrame(){
                        const frameRight = new THREE.Shape();
                        frameRight.moveTo(0, 0);
                        frameRight.lineTo(3, 0);
                        frameRight.lineTo(3, 3);
                        frameRight.lineTo(0, 3);
                        frameRight.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: height - 3.25,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameRight, extrudeSettings, new THREE.Vector3(0, 0, 0), geometries);
                        const frameLeft = new THREE.Shape();
                        frameLeft.moveTo(0, 0);
                        frameLeft.lineTo(3, 0);
                        frameLeft.lineTo(3, 3);
                        frameLeft.lineTo(0, 3);
                        frameLeft.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: height - 3.25,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameLeft, extrudeSettings, new THREE.Vector3(width + 3, 0, 0), geometries);
                        const frameBottom = new THREE.Shape();
                        frameBottom.moveTo(0, 0);
                        frameBottom.lineTo(width, 0);
                        frameBottom.lineTo(width, 3);
                        frameBottom.lineTo(0, 3);
                        frameBottom.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: 3,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameBottom, extrudeSettings, new THREE.Vector3(3, 0, height - 6.25), geometries);

                        const frameTop = new THREE.Shape();
                        frameTop.moveTo(0, 0);
                        frameTop.lineTo(width, 0);
                        frameTop.lineTo(width, 3);
                        frameTop.lineTo(0, 3);
                        frameTop.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: 3,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameTop, extrudeSettings, new THREE.Vector3(3, 0, 0), geometries);

                        const frameMiddleV = new THREE.Shape();
                        frameMiddleV.moveTo(0, 0);
                        frameMiddleV.lineTo(3, 0);
                        frameMiddleV.lineTo(3, 3);
                        frameMiddleV.lineTo(0, 3);
                        frameMiddleV.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: height - 3.25,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameMiddleV, extrudeSettings, new THREE.Vector3(width / 2 + 1.5, 0, 0), geometries);

                        const frameMiddleH = new THREE.Shape();
                        frameMiddleH.moveTo(0, 0);
                        frameMiddleH.lineTo(width, 0);
                        frameMiddleH.lineTo(width, 3);
                        frameMiddleH.lineTo(0, 3);
                        frameMiddleH.lineTo(0, 0);
                        extrudeSettings = {
                            steps: 2,
                            depth: 3,
                            bevelEnabled: false,
                        }
                        generateGeometry(frameMiddleH, extrudeSettings, new THREE.Vector3(3, 0, height / 2 - 1.5), geometries);
                        const windowFrame = new THREE.Mesh(mergeBufferGeometries(geometries), new THREE.MeshPhongMaterial({color: 0xffffff}));
                        windowFrame.rotateX(Math.PI / 2);
                        windowFrame.position.set(positionX - average.x, y1 + wallData.Height - 6.25, positionZ - average.z);
                        if(X5.toFixed(5) === X6.toFixed(5)) {
                            if(Z5 > Z6) {
                            windowFrame.rotation.z = Math.PI * 1.5;
                            windowFrame.position.set(positionX - average.x, y1 + wallData.Height - 6.25, positionZ - average.z + 3);
                            }
                            else {
                            windowFrame.rotation.z = Math.PI * 0.5;
                            windowFrame.position.set(positionX - average.x + 3, y1 + wallData.Height - 6.25, positionZ - average.z);
                            }
                        }
                        else if(Z5.toFixed(5) === Z6.toFixed(5)) {
                            if(X5 > X6) {
                            windowFrame.rotation.z = Math.PI;
                            windowFrame.position.set(positionX - average.x + 3, y1 + wallData.Height - 6.25, positionZ - average.z + 3);
                            }
                        }
                        else {
                            windowFrame.rotation.z = slope;
                        }
                        windowFrame.layers.set(0);
                        windowFrame.forRayTracing = false;
                        windowFrame.userData = {
                            Description: "Wall",
                            toScreen: "Wall",
                            idNumber: wallData.$id,
                            level: wallData.LevelName,
                            GUID: wallData.GUID,
                        }
                        group.add(windowFrame);
                    })();
                }
            }
            else if(wallData.OpeningTypeList[j] === 1 && wallData.HasPeakList === null) {
                let extrudeSettings = {
                    steps: 2,
                    depth: wallData.OpenHeightList[j],
                    bevelEnabled: false,
                }
                let doorFrame;
                let width;
                let positionX;
                let positionZ;
                let slope;
                if(X5.toFixed(5) === X6.toFixed(5)){
                    width = Math.abs(Z5 - Z6); 
                    if(Z5 < Z6){
                    positionX = X5;
                    positionZ = Z5 - 3;
                    }
                    else if(Z5 > Z6){
                    positionX = X5 - 3;
                    positionZ = Z5;
                    }
                }
                else if(Z5.toFixed(5) === Z6.toFixed(5)){
                    width = Math.abs(X5 - X6);
                    if(X5 < X6){
                    positionX = X5 - 3;
                    positionZ = Z5 - 3;
                    }
                    else if(X5 > X6){
                    positionX = X5;
                    positionZ = Z5;
                    }
                }
                else {
                    const xHor = Math.abs(X5 - X6);
                    const zHor = Math.abs(Z5 - Z6);
                    width = Math.sqrt(xHor * xHor + zHor * zHor);
                    slope = Math.atan(zHor / xHor);
                    if(X5 < X6){
                    if(Z5 < Z6){
                        slope = Math.atan(zHor / xHor);
                        positionX = X5 + (3 * Math.sin(slope));
                        positionZ = Z5 - (3 * Math.cos(slope));
                        positionX -= (3 * Math.cos(slope));
                        positionZ -= (3 * Math.sin(slope));
                    }
                    else if(Z5 > Z6){
                        slope = -Math.atan(zHor / xHor);
                        positionX = X5 - (3 * Math.cos(slope));
                        positionZ = Z5 - (3 * Math.sin(slope));
                        positionX += (3 * Math.sin(slope));
                        positionZ -= (3 * Math.cos(slope));

                    }
                    }
                    else if(X5 > X6){
                    if(Z5 < Z6){
                        slope = -Math.atan(zHor / xHor) - Math.PI;
                            positionX = X5 + (3 * Math.sin(slope));
                            positionZ = Z5 - (3 * Math.cos(slope));
                            positionX -= (3 * Math.cos(slope));
                            positionZ -= (3 * Math.sin(slope));
                    }
                    else if(Z5 > Z6){
                        slope = Math.atan(zHor / xHor) + Math.PI;
                        positionX = X5 - (3 * Math.cos(slope));
                        positionZ = Z5 - (3 * Math.sin(slope));
                        positionX += (3 * Math.sin(slope));
                        positionZ -= (3 * Math.cos(slope));
                    }
                    }
                }
                let geometries = [];
                (function generateDoorFrame(){
                    const frameRight = new THREE.Shape();
                    frameRight.moveTo(0, 0);
                    frameRight.lineTo(3, 0);
                    frameRight.lineTo(3, 3);
                    frameRight.lineTo(0, 3);
                    frameRight.lineTo(0, 0);
                    extrudeSettings = {
                        steps: 2,
                        depth: wallData.OpenHeightList[j] + 3,
                        bevelEnabled: false,
                    }
                    generateGeometry(frameRight, extrudeSettings, new THREE.Vector3(0, 0, 0), geometries);
                    // const frameRightMesh = new THREE.Mesh(new THREE.ExtrudeGeometry(frameRight, extrudeSettings), new THREE.MeshPhongMaterial({color: 0xffffff}));
                    // frameRightMesh.position.set(0, 0, 0);
                    // frameRightMesh.rotation.x = Math.PI/2;
                    // frameRightMesh.updateMatrix();

                    const frameLeft = new THREE.Shape();
                    frameLeft.moveTo(0, 0);
                    frameLeft.lineTo(3, 0);
                    frameLeft.lineTo(3, 3);
                    frameLeft.lineTo(0, 3);
                    frameLeft.lineTo(0, 0);
                    extrudeSettings = {
                        steps: 2,
                        depth: wallData.OpenHeightList[j] + 3,
                        bevelEnabled: false,
                    }
                    generateGeometry(frameLeft, extrudeSettings, new THREE.Vector3(width + 3, 0, 0), geometries);
                    // const frameLeftMesh = new THREE.Mesh(new THREE.ExtrudeGeometry(frameLeft, extrudeSettings), new THREE.MeshPhongMaterial({color: 0xffffff}));
                    // frameLeftMesh.position.set(width + 3, 0, 0);
                    // frameLeftMesh.rotation.x = Math.PI/2;
                    // frameLeftMesh.updateMatrix();
                    // doorFrame = CSG.union(frameRightMesh, frameLeftMesh);
                    
                    const frameTop = new THREE.Shape();
                    frameTop.moveTo(0, 0);
                    frameTop.lineTo(width, 0);
                    frameTop.lineTo(width, 3);
                    frameTop.lineTo(0, 3);
                    frameTop.lineTo(0, 0);
                    extrudeSettings = {
                        steps: 2,
                        depth: 3,
                        bevelEnabled: false,
                    }
                    generateGeometry(frameTop, extrudeSettings, new THREE.Vector3(3, 0, 0), geometries);
                    doorFrame = new THREE.Mesh(mergeBufferGeometries(geometries), new THREE.MeshPhongMaterial({color: 0xffffff}));
                    doorFrame.rotateX(Math.PI / 2);
                    doorFrame.position.set(positionX - average.x, y1 + wallData.OpenHeightList[j] + 3, positionZ - average.z);
                    if(X5.toFixed(5) === X6.toFixed(5)) {
                        if(Z5 > Z6) {
                        doorFrame.rotation.z = Math.PI * 1.5;
                        doorFrame.position.set(positionX - average.x, y1 + wallData.OpenHeightList[j] + 3, positionZ - average.z + 3);
                        }
                        else {
                        doorFrame.rotation.z = Math.PI * 0.5;
                        doorFrame.position.set(positionX - average.x + 3, y1 + wallData.OpenHeightList[j] + 3, positionZ - average.z);
                        }
                    }
                    else if(Z5.toFixed(5) === Z6.toFixed(5)) {
                        if(X5 > X6) {
                        doorFrame.rotation.z = Math.PI;
                        doorFrame.position.set(positionX - average.x + 3, y1 + wallData.OpenHeightList[j] + 3, positionZ - average.z + 3);
                        }
                    }
                    else {
                        doorFrame.rotation.z = slope;
                    }
                    // const frameTopMesh = new THREE.Mesh(new THREE.ExtrudeGeometry(frameTop, extrudeSettings), new THREE.MeshPhongMaterial({color: 0xffffff}));
                    // frameTopMesh.position.set(3, 0, 0);
                    // frameTopMesh.rotation.x = Math.PI/2;
                    // frameTopMesh.updateMatrix();
                    // doorFrame.updateMatrix();
                    // doorFrame = CSG.union(doorFrame, frameTopMesh);
                    doorFrame.layers.set(0);
                    doorFrame.forRayTracing = false;
                    doorFrame.userData = {
                        Description: "Wall",
                        toScreen: "Wall",
                        idNumber: wallData.$id,
                        level: wallData.LevelName,
                        GUID: wallData.GUID,
                    }
                    group.add(doorFrame);
                })();
            }
        }
    }
    board.layers.set( 0 );
    if(!special){
        board.position.x = -average.x;
        board.position.z = -average.z;
    }
    else{
        board.position.setX(pointX1 - average.x);
        board.position.setZ(pointZ1 - average.z);
    }
    if(wallPreview){
        board.material.opacity = 0.20;
        board.material.transparent = true;
    }
    board.forRayTracing = true;
    board.userData = {
        GUID: wallData.GUID,
        Description: "Wall",
        toScreen: "Wall", 
        level: wallData.LevelName,
        thickness: wallData.Thickness,
        color: wallColor,
        canChangeColor: true,
        normalColor: color,
        height: wallData.Height,
        tapeMeasure: true,
        edges: edges,
        points: edgePoints,
    }
    board.displayWindowInfo = {
        Type: "Wall",
        // GUID: wallData.GUID,
        Level: wallData.LevelName,
        Thickness: isMetric? ((wallData.Thickness * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.Thickness.toFixed(2).replace(/\.?0*$/,'')) + " in",
        Height: isMetric? ((wallData.Height * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.Height.toFixed(2).replace(/\.?0*$/,'')) + " in",
        Elevation: isMetric? ((wallData.AbsoluteElevationBottom * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.AbsoluteElevationBottom.toFixed(2).replace(/\.?0*$/,'')) + " in",    
    }
    if(objectOpacity.transparent){
        board.material.opacity = objectOpacity.opacity;
        board.material.transparent = true;
    }
    group.add(board);
    if(outerBoard){
        outerBoard.position.set(-average.x, 0, -average.z);
        outerBoard.layers.set(0);
        outerBoard.forRayTracing = true;
        outerBoard.userData = {
            GUID: wallData.GUID,
            Description: "Wall",
            toScreen: "Wall",
            level: wallData.LevelName,
            color: 0xcb4154,
            normalColor: 0xcb4154,
            edges: edgesOuter,
            points: edgePointsOuter,
            tapeMeasure: true,
        }
        outerBoard.displayWindowInfo = {
            Type: "OuterWall",
            Level: wallData.LevelName,
            Thickness: isMetric? ((wallData.Thickness * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.Thickness.toFixed(2).replace(/\.?0*$/,'')) + " in",
            Height: isMetric? ((wallData.Height * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.Height.toFixed(2).replace(/\.?0*$/,'')) + " in",
            Elevation: isMetric? ((wallData.AbsoluteElevationBottom * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (wallData.AbsoluteElevationBottom.toFixed(2).replace(/\.?0*$/,'')) + " in",    
        }
        if(objectOpacity.transparent){
            outerBoard.material.opacity = objectOpacity.opacity;
            outerBoard.material.transparent = true;
        }
        group.add(outerBoard);
    }
}
function generateGeometry(shape, extrudeSettings, position, geometries) {
    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    geometry.translate(position.x, position.y, position.z);

    geometries.push(geometry);
}
export {generateWall};
import * as THREE from 'three';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
// import { CSS2DRenderer,CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { OrbitControls } from '@/imports/OrbitControls.js';
import { checkIntersection } from '../ObjectCreateFolders/helperFiles/helperFunctions.js'
import dimensionTool from './dimensionTool.js';
function init(group, screenShot, items, onWindowResize, scrollValues, raycaster, mouse, planes, version, helperPlanes, average, selection, isMetric, dimensionLinesKind, buttonsOn, store, tapeMeasureOn){
    let container = document.getElementById( 'app' );
    let scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera( 50, container.clientWidth / container.clientHeight, 1, 10000 );
    camera.position.set( 0, 150, 500 );
    camera.layers.enableAll();
    scene.add( camera );
    const light = new THREE.PointLight( 0xf4f5f0, 0.55 );
    light.layers.enableAll();
    camera.add( light );
    const ambientLight = new THREE.AmbientLight( 0xf4f5f0, 0.35 );
    ambientLight.layers.enableAll();
    scene.add( ambientLight );
    scene.add( group );
    let groundPlane = new THREE.Mesh( new THREE.PlaneGeometry( 10000, 10000 ), new THREE.MeshPhongMaterial( { color: 0x567d46} ) );
    groundPlane.layers.enableAll();
    groundPlane.position.set( 0, -1, 0 );
    groundPlane.rotation.x = - Math.PI / 2;
    groundPlane.userData.Description = "ignore GroundLayer";
    groundPlane.layers.set(20);
    if(groundPlane && !screenShot){
        items.GroundLayer.inModel = true;
        scene.add(groundPlane);
        scene.background = new THREE.Color( 0xd8f2ff );
    }
    else if(screenShot){
        scene.background = new THREE.Color( 0xffffff );
    }
    else{
        scene.background = new THREE.Color( 0xd8f2ff );
    }

    let renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( container.clientWidth, container.clientHeight );
    renderer.domElement.addEventListener( 'pointermove', onPointerMove );
    renderer.localClippingEnabled = true;

    container.appendChild( renderer.domElement );
    window.addEventListener( 'resize', onWindowResize );
    let objectSelected = {
        object: null,
        on: false,
    }
    let controls = new OrbitControls( camera, renderer.domElement, group, camera, raycaster, mouse, objectSelected, planes, scrollValues, version, average, screenShot, tapeMeasureOn);
    scrollValues.controls = controls;
    scrollValues.groundPlane = groundPlane;
    let outlinePass = new OutlinePass( new THREE.Vector2( container.clientWidth, container.clientHeight ), scene, camera );
    const renderPass = new RenderPass( scene, camera );
    let effectFXAA = new ShaderPass( FXAAShader );
    effectFXAA.uniforms[ 'resolution' ].value.set( 1 / container.clientWidth, 1 / container.clientHeight );
    let composer = new EffectComposer( renderer );
    composer.addPass( renderPass );
    composer.addPass( outlinePass );
    composer.addPass( effectFXAA );
    raycaster.layers.enableAll();

    outlinePass.visibleEdgeColor.set( '#ffffff' );

    let stats = new Stats();
    // container.appendChild( stats.dom );
    stats.dom.style.top = null;
    stats.dom.style.left = null;
    stats.dom.style.bottom = '1px';
    stats.dom.style.right = '1px';
    function onPointerMove( event ) {
        
        if ( event.isPrimary === false ) return;
        if ( event.pointerType === "touch" ) return;
        if ( !selection.on ) {
            outlinePass.selectedObjects = [];
            return;
        }
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = -( event.clientY / window.innerHeight ) * 2 + 1;
        
        const value = checkIntersection(raycaster, mouse, camera, group);
        if(value){
            outlinePass.selectedObjects = [value];
        }
        else{
            outlinePass.selectedObjects = [];
        }
    }
    const tapeMeasureTool = new dimensionTool(renderer, camera, scene, group, mouse, raycaster, selection, onPointerMove, isMetric, controls, outlinePass, average, dimensionLinesKind, buttonsOn, store, tapeMeasureOn);
    let labelRenderer = tapeMeasureTool.getLabelRenderer();
    let dimensionLabels = tapeMeasureTool.getLabels();
    let stationaryToolObjects = tapeMeasureTool.getStationaryToolObjects();
    return {container, scene, camera, renderer, outlinePass, effectFXAA, composer, stats, labelRenderer, controls, objectSelected, stationaryToolObjects,dimensionLabels}
}
export {init};
<template>
  <header id = "loading_Screen">{{ msg }}</header>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 0 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0px;
}
a {
  color: #42b983;
}
/* Put the message in the middle and make it bigger also make the three dot after loading show one dot then two dots then three*/
#loading_Screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: bold;
  color: #000000;
}
</style>

import * as THREE from 'three';
function generateBackerBlock(positions, average, group, level, guid, id, layerNumber, type){
    for(let i = 0; i < positions.length; i++){
        let lt1 = new THREE.Vector3(parseFloat(positions[i][0].P1.split(",")[0]) - average.x, parseFloat(positions[i][0].P1.split(",")[2]), parseFloat(positions[i][0].P1.split(",")[1]) - average.z);
        let rt1 = new THREE.Vector3(parseFloat(positions[i][0].P2.split(",")[0]) - average.x, parseFloat(positions[i][0].P2.split(",")[2]), parseFloat(positions[i][0].P2.split(",")[1]) - average.z);
        let lb1 = new THREE.Vector3(parseFloat(positions[i][1].P1.split(",")[0]) - average.x, parseFloat(positions[i][1].P1.split(",")[2]), parseFloat(positions[i][1].P1.split(",")[1]) - average.z);
        let rb1 = new THREE.Vector3(parseFloat(positions[i][1].P2.split(",")[0]) - average.x, parseFloat(positions[i][1].P2.split(",")[2]), parseFloat(positions[i][1].P2.split(",")[1]) - average.z);
        let lt2 = new THREE.Vector3(parseFloat(positions[i][2].P1.split(",")[0]) - average.x, parseFloat(positions[i][2].P1.split(",")[2]), parseFloat(positions[i][2].P1.split(",")[1]) - average.z);
        let rt2 = new THREE.Vector3(parseFloat(positions[i][2].P2.split(",")[0]) - average.x, parseFloat(positions[i][2].P2.split(",")[2]), parseFloat(positions[i][2].P2.split(",")[1]) - average.z);
        let lb2 = new THREE.Vector3(parseFloat(positions[i][3].P1.split(",")[0]) - average.x, parseFloat(positions[i][3].P1.split(",")[2]), parseFloat(positions[i][3].P1.split(",")[1]) - average.z);
        let rb2 = new THREE.Vector3(parseFloat(positions[i][3].P2.split(",")[0]) - average.x, parseFloat(positions[i][3].P2.split(",")[2]), parseFloat(positions[i][3].P2.split(",")[1]) - average.z);
        const vertices = new Float32Array([
            //right side of cube
            rb1.x, rb1.y, rb1.z,
            rb2.x, rb2.y, rb2.z,
            rt1.x, rt1.y, rt1.z,
          
            rt1.x, rt1.y, rt1.z,
            rb2.x, rb2.y, rb2.z,
            rt2.x, rt2.y, rt2.z,
    
            //left side of cube
    
            lt1.x, lt1.y, lt1.z,
            lb2.x, lb2.y, lb2.z,
            lb1.x, lb1.y, lb1.z,
    
            lt1.x, lt1.y, lt1.z,
            lt2.x, lt2.y, lt2.z,
            lb2.x, lb2.y, lb2.z,
    
            //bottom of cube
            lb1.x, lb1.y, lb1.z,
            rb2.x, rb2.y, rb2.z,
            rb1.x, rb1.y, rb1.z,
    
            lb1.x, lb1.y, lb1.z,
            lb2.x, lb2.y, lb2.z,
            rb2.x, rb2.y, rb2.z,
    
            //top of cube
            rt1.x, rt1.y, rt1.z,
            rt2.x, rt2.y, rt2.z,
            lt1.x, lt1.y, lt1.z,
    
            rt2.x, rt2.y, rt2.z,
            lt2.x, lt2.y, lt2.z,
            lt1.x, lt1.y, lt1.z,
    
            //front of cube
            rb1.x, rb1.y, rb1.z,
            rt1.x, rt1.y, rt1.z,
            lt1.x, lt1.y, lt1.z,
            
            lt1.x, lt1.y, lt1.z,
            lb1.x, lb1.y, lb1.z,
            rb1.x, rb1.y, rb1.z,
    
            //back of cube
            lb2.x, lb2.y, lb2.z,
            rt2.x, rt2.y, rt2.z,
            rb2.x, rb2.y, rb2.z,
    
            lb2.x, lb2.y, lb2.z,
            lt2.x, lt2.y, lt2.z,
            rt2.x, rt2.y, rt2.z,
    
        ]);
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        geometry.computeVertexNormals();
        const material = new THREE.MeshStandardMaterial({color: 0x9C7CAA,flatShading: true,side: THREE.DoubleSide});
        const cube = new THREE.Mesh(geometry, material);
        cube.layers.set(layerNumber);
        cube.userData = {Description: type,level: level, GUID: guid, idNumber: id,};
        group.add(cube);

    }
}
export { generateBackerBlock };
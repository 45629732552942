/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
function generateHoles(member, degrees, px1, y2, pz1, px2, pz2, i, average, thickness,  memberColor){ 
    let oneHole;
    const vec = new THREE.Vector3(px2 - px1, 0, pz2 - pz1).normalize();
    const holeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, opacity: 0.0, transparent: true });
    let holeExtruded;
    const position = member.WebHoles[i].Location;
    const y = y2 + member.Depth / 2;
    const diskAdjust = thickness / 2;
    let newX = px1 + vec.x * position + vec.z * diskAdjust;
    let newZ = pz1 + vec.z * position + -vec.x * diskAdjust;
    if(member.WebHoles[i].IsObround){
      const hole = new THREE.Shape();
      const width = member.WebHoles[i].Width;
      const height = member.WebHoles[i].Height;
      const radius = height / 2;
      const newWidth = width - height;
      hole.moveTo(0, 0 - newWidth / 2);
      hole.absarc(0, 0 - newWidth / 2, radius, Math.PI, 0, false);
      hole.lineTo(0 + height / 2, 0 + newWidth / 2);
      hole.absarc(0,  0 + newWidth / 2, radius, 0, Math.PI, false);
      hole.lineTo(0 - height / 2, 0 - newWidth / 2);
      let extrudeSettings = {
        steps: 100,
        depth: thickness,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), Math.PI / 2);
    // For when the hole is Circular
    }
    else if(member.WebHoles[i].IsRound){
      const hole = new THREE.Shape();
      const radius = member.WebHoles[i].Diameter / 2;
      hole.absarc(0, 0, radius, 0, Math.PI * 2, false);
      let extrudeSettings = {
        steps: 1,
        depth: thickness,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), 0);
  
    }
    else{
      const hole = new THREE.Shape();
      const width = member.WebHoles[i].Width;
      const height = member.WebHoles[i].Height;
      hole.moveTo(0 + height / 2, 0 + width / 2);
      hole.lineTo(0 - height / 2 , 0 + width / 2);
      hole.lineTo(0 - height / 2 , 0 - width / 2);
      hole.lineTo(0 + height / 2 , 0 - width / 2);
      hole.lineTo(0 + height / 2, 0 + width / 2 );
      let extrudeSettings = {
        steps: 1,
        depth: thickness,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), Math.PI / 2);
    }
    let linewidth = 2.5; // change as needed
    let {line,line2} = generateDistanceLines(vec, px1, pz1, thickness, position, y + member.Depth / 2, average, linewidth);
    if (isDarkColor(memberColor)) {
      line.material.color.set(0xFFFFFF); 
      line2.material.color.set(0xFFFFFF); 
    } else {
      line.material.color.set(0x000000); 
      line2.material.color.set(0x000000); 
    }
    return {oneHole, line, line2};
    // return oneHole;
}
function isDarkColor(color) {
  let r = (color >> 16) & 255;
  let g = (color >> 8) & 255;
  let b = color & 255;
  let darkness = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return darkness > 0.5;
} 
function generateDistanceLines(vec, px1, pz1, thickness, distance, y, average, linewidth){
  let xStart = px1 - vec.z * ((thickness / 2) + 0.1);
  let zStart = pz1 + vec.x * ((thickness / 2) + 0.1);
  let lineStart = new THREE.Vector3(xStart, y, zStart);
  let lineEnd = new THREE.Vector3(xStart + vec.x * distance, y, zStart + vec.z * distance);
  let geometry = new LineGeometry();
  geometry.setPositions([lineStart.x, lineStart.y, lineStart.z, lineEnd.x, lineEnd.y, lineEnd.z]);

  const material = new LineMaterial({ 
    color: 0x000000, 
    linewidth: linewidth, // Here is the change
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight) // This is important when defining linewidth
  });

  let line = new Line2(geometry, material);
  line.computeLineDistances();
  line.position.set(-average.x, 0, -average.z);
  
  let xStart2 = px1 - vec.z * ((thickness / 2) + 0.1) * -1;
  let zStart2 = pz1 + vec.x * ((thickness / 2) + 0.1) * -1;
  let lineStart2 = new THREE.Vector3(xStart2, y, zStart2);
  let lineEnd2 = new THREE.Vector3(xStart2 + vec.x * distance, y, zStart2 + vec.z * distance);
  let geometry2 = new LineGeometry();
  geometry2.setPositions([lineStart2.x, lineStart2.y, lineStart2.z, lineEnd2.x, lineEnd2.y, lineEnd2.z]);

  let line2 = new Line2(geometry2, material);
  line2.computeLineDistances();
  line2.position.set(-average.x, 0, -average.z);
  return {line, line2};
}
// function generateDistanceArrows(vec, px1, pz1, thickness, distance, y, average){
//   let xStart = px1 - vec.z * ((thickness / 2) + 0.1);
//   let zStart = pz1 + vec.x * ((thickness / 2) + 0.1);
//   let lineStart = new THREE.Vector3(xStart + vec.x * distance, y, zStart + vec.z * distance);
//   let lineEnd = new THREE.Vector3(xStart, y, zStart);
//   let origin = new THREE.Vector3(xStart + vec.x * distance, y, zStart + vec.z * distance);
//   let direction = new THREE.Vector3().subVectors(lineEnd, lineStart).normalize();
//   let arrow = new THREE.ArrowHelper(direction, origin, 1000, 0x000000);
//   arrow.position.set(-average.x, 0, -average.z);
//   let xStart2 = px1 - vec.z * ((thickness / 2) + 0.1) * -1;
//   let zStart2 = pz1 + vec.x * ((thickness / 2) + 0.1) * -1;
//   let lineStart2 = new THREE.Vector3(xStart2 + vec.x * distance, y, zStart2 + vec.z * distance);
//   let lineEnd2 = new THREE.Vector3(xStart2, y, zStart2);
//   let origin2 = new THREE.Vector3(xStart2 + vec.x * distance, y, zStart2 + vec.z * distance);
//   let direction2 = new THREE.Vector3().subVectors(lineEnd2, lineStart2).normalize();
//   let arrow2 = new THREE.ArrowHelper(direction2, origin2, 1000, 0x000000);
//   arrow2.position.set(-average.x, 0, -average.z);
//   return {arrow, arrow2};
// }
export {generateHoles}


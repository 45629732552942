/* eslint-disable */
import {createApp, reactive} from 'vue'
import App from './App.vue'
import data from '../JSONTestExamples/needsWork.json';
import { createStore } from 'vuex'
let app = createApp(App);
app.mount('#app');
import * as THREE from 'three'

import { electronInialization, portalInialization, addMembersMethod } from './mainsHelperFunctions/intialization.js';
import { checkSaveSettings } from './mainsHelperFunctions/checkSaveSettings.js';
import { finishInit } from './mainsHelperFunctions/finishInit.js';
import { generateHelperPlanes } from './mainsHelperFunctions/generateHelperPlanes.js';
import { loadHangers } from './mainsHelperFunctions/loadHangers.js';
import { init } from './mainsHelperFunctions/initFile.js';
import { calling } from './mainsHelperFunctions/highlightMembers.js';

import { generateConstructionLines } from './ObjectCreateFolders/constructionLines';

let container, loadingScreen;
let camera, scene, renderer, controls;
let group = new THREE.Group();
let average = {x: null, z: null};
let bbox = {minX: Infinity, minY: Infinity, minZ: Infinity, maxX: -Infinity, maxY: -Infinity, maxZ: -Infinity};
let raycaster = new THREE.Raycaster();
let mouse = new THREE.Vector2();
let outlinePass, composer, effectFXAA;
let stats;
let levelNames = [];
let boundaryPoints = {};
let groundPlane;
let wheelForClipping = false;
let scroll = {};
let negativeXpoint, negativeYpoint, negativeZpoint, positiveXpoint, positiveYpoint, positiveZpoint, closestPoint, horizontalMin, horizontalMax, verticalMin, verticalMax;
let xMove, yMove, zMove, clippingDone, cameraBeforeClipping, controlsBefore, scrollSpeedSave, distanceFromPlane = 400;
let stationaryToolObjects;
let objectSelected = null;
let planes = [];
let version = 1;
let thingToHighlight, firstTime = true;
let isColor = true;
let counts = {minCounts: {}, maxCounts: {}, memberCount: {count: 0}};
let helperPlanes = new THREE.Group();
let selection = {on: true};
let windowLoaded = false;
let constructionLineGUID = [], levelN;
let test = false;
let highlightedMembers = [];
let currentTransparencyOutside = 3;
let hangerNames = new Set();
let geometryCache = {};
let didntWork = {};
let tapeMeasureOn = {value: false};
let url = null;
let labelRenderer;
let dimensionLabels = null;
let key = null;
let isMetric = null, production = false, screenShot = null;
let newLayout = false;
let pdfFile = null;
let t1 = null;
let t2 = null;
let buttonsOn = {w: false};
let lowPerformance = false;
let saveSettings = null;
let infoWindow = {"Company Name": "n/a", "Project Number": "n/a", "Layout Name": "n/a", "Project": "n/a", "Address": "n/a", "Design Method": "n/a"}
let dimensionLinesKind = {allEdges: true, endEdges: false, cornerPoints: false};
let items = {
    "backerBlocks": { id: "backerBlocks", name: "Backer", layer: 18, visibility: true, opacity: false, inModel: false, count: 0 },
    "BeamByOthers": { id: "BeamByOthers", name: "Beams", layer: 6, visibility: true, opacity: false, inModel: false, count: 0 },
    "Blocking": { id: "Blocking", name: "Blocking", layer: 22, visibility: true, opacity: false, inModel: false, count: 0 },
    // "BlockingLine": { id: "BlockingLine", name: "Blocking Line", layer: 11, visibility: true, opacity: false, inModel: false, count: 0 },
    "Column": { id: "Column", name: "Column", layer: 4, visibility: true, opacity: false, inModel: false, count: 0 },
    "Decking": { id: "Decking", name: "Decking", layer: 11, visibility: true, opacity: false, inModel: false, count: 0 },
    "Glulam": { id: "Glulam", name: "Glulam", layer: 21, visibility: true, opacity: false, inModel: false, count: 0 },
    "GroundLayer": { id: "GroundLayer", name: "Ground Layer", layer: 20, visibility: true, opacity: false, inModel: false, count: 0 },
    "iJoist": { id: "iJoist", name: "I-Joist", layer: 2, visibility: true, opacity: false, inModel: false, count: 0 },
    "JoistClips": { id: "JoistClips", name: "Joist Clips", layer: 19, visibility: true, opacity: false, inModel: false, count: 0 },
    "Lumber": { id: "Lumber", name: "Lumber", layer: 7, visibility: true, opacity: false, inModel: false, count: 0 },
    "LVL": { id: "LVL", name: "LVLs", layer: 5, visibility: true, opacity: false, inModel: false, count: 0 },
    "noggin": { id: "noggin", name: "Noggin", layer: 17, visibility: true, opacity: false, inModel: false, count: 0 },
    "Obstruction": { id: "Obstruction", name: "Obstruction", layer: 13, visibility: true, opacity: false, inModel: false, count: 0 },
    "OpenJoist": { id: "OpenJoist", name: "OpenJoists", layer: 8, visibility: true, opacity: false, inModel: false, count: 0 },
    "Plate": { id: "Plate", name: "Plate", layer: 23, visibility: true, opacity: false, inModel: false, count: 0 },
    "PonyWall": { id: "PonyWall", name: "Pony Wall", layer: 10, visibility: true, opacity: false, inModel: false, count: 0 },
    "Rimboard": { id: "Rimboard", name: "Rimboards", layer: 3, visibility: true, opacity: false, inModel: false, count: 0 },
    "Roof": { id: "Roof", name: "Roof", layer: 1, visibility: true, opacity: false, inModel: false, count: 0 },
    "SquashBlockLine": { id: "SquashBlockLine", name: "Squash Block", layer: 12, visibility: true, opacity: false, inModel: false, count: 0 },
    "Steel": { id: "Steel", name: "Steel", layer: 16, visibility: true, opacity: false, inModel: false, count: 0 },
    "Strongback": { id: "Strongback", name: "Strongback", layer: 15, visibility: true, opacity: false, inModel: false, count: 0 },
    "Stud": { id: "Stud", name: "Stud", layer: 9, visibility: true, opacity: false, inModel: false, count: 0 },
    "Truss": { id: "Truss", name: "Truss", layer: 14, visibility: true, opacity: false, inModel: false, count: 0 },
    "Wall": { id: "Wall", name: "Walls", layer: 0, visibility: true, opacity: false, inModel: false, count: 0 },
};
let store = createStore({
    state: reactive({
        group: null,
        camera: null,
        raycaster: null,
        levels: null,
        scene: null,
        scroll: null,
        objectSelected: null,
        planes: null,
        items: null,
        location: null,
        selection: null,
        screenShot: null,
        storeReady:false,
        url: null,
        key: null,
        version: null,
        pdfFile: null,
        infoWindow: null,
        isMetric: null,
        highlightedMembers: null,
        currentTransparency: null,
        saveSettings: null,
        dimensionLinesKind: 'allEdges',
        buttonsToggleOn: false,
        buttonsToggleFreeView: false,
    }),
    mutations: {
        setGroup(state, group){
            state.group = group;
        },
        setCamera(state, camera){
            state.camera = camera;
        },
        setRaycaster(state, raycaster){
            state.raycaster = raycaster;
        },
        setLevels(state, levels){
            state.levels = levels;
        },
        setBoundaryPoints(state, boundaryPoints){
            state.boundaryPoints = boundaryPoints;
        },
        setObjectsVis(state, objectsVis){
            state.objectsVis = objectsVis;
        },
        setScene(state, scene){
            state.scene = scene;
        },
        setScroll(state, scroll){
            state.scroll = scroll;
        },
        setObjectSelected(state, objectSelected){
            state.objectSelected = objectSelected;
        },
        setPlanes(state, planes){
            state.planes = planes;
        },
        setItems(state, items){
            state.items = items;
        },
        setLocation(state, location){
            state.location = location;
        },
        setSelection(state, selection){
            state.selection = selection;
        },
        setScreenShot(state, screenShot){
            state.screenShot = screenShot;
        },
        setStoreReady(state, storeReady){
            state.storeReady = storeReady;
        },
        setUrl(state, url){
            state.url = url;
        },
        setKey(state, key){
            state.key = key;
        },
        setVersion(state, version){
            state.version = version;
        },
        setModel(state, item){
            state.items[item].inModel = true;
        },
        removeModel(state, item){
            state.items[item].inModel = false;
        },
        enableAll(state) {
            for (let key in state.items) {
                state.items[key].visibility = true;
                state.items[key].opacity = false;
            }
        },
        disableAll(state) {
            for (let key in state.items) {
                state.items[key].visibility = false;
                state.items[key].opacity = false;
            }
        },
        setPDF(state, pdfFile){
            state.pdfFile = pdfFile;
        },
        setInfoWindow(state, infoWindow){
            state.infoWindow = infoWindow;
        },
        setIsMetric(state, isMetric){
            state.isMetric = isMetric;
        },
        lowPerformance(state, lowPerformance){
            state.lowPerformance = lowPerformance;
        },
        highlightedMembers(state, highlightedMembers){
            state.highlightedMembers = highlightedMembers;
        },
        currentTransparency(state, currentTransparency){
            state.currentTransparency = currentTransparency;
            currentTransparencyOutside = currentTransparency;
        },
        saveSettings(state, saveSettings){
            state.saveSettings = saveSettings;
        },
        SET_ITEM(state, { key, property, value }) {
            if (state.items[key]) {
                state.items[key] = { ...state.items[key], [property]: value };
            }
        },
        SET_SELECTED_DIMENSION_LINE_KIND(state, value) {
            state.dimensionLinesKind = value;
            for(const key in dimensionLinesKind) {
                dimensionLinesKind[key] = false;
            }
            // Set the selected property to true
            dimensionLinesKind[value] = true;
        },
        SET_DIMENSION_TOOL_ON(state, value) {
            state.buttonsToggleOn = value;
            buttonsOn['w'] = value;
        },
    }
});
async function electronWindow(){
    if(version === 0){
        if(!newLayout){
            data = await listenerBeginningData();
        }
        if(url === null  && data.url !== null){
            url = data.url;
        }
        await fetchRamInfo();
        console.log("here")
    }
    if(screenShot === null){
        screenShot = data.ScreenShot === true ? true : screenShot;
    }
    t1 = performance.now();
    if(isMetric === null){
        isMetric = data.IsMetric === true ? true : isMetric;
    }
    if (data.CompanyName && infoWindow['Company Name'] === "n/a") {
        infoWindow['Company Name'] = data.CompanyName;
    }
    if (data.ProjectNumber && infoWindow['Project Number'] === "n/a") {
        infoWindow['Project Number'] = data.ProjectNumber;
    }
    if (data.LayoutName && infoWindow['Layout Name'] === "n/a") {
        infoWindow['Layout Name'] = data.LayoutName;
    }
    if (data.Project && infoWindow['Project'] === "n/a") {
        infoWindow['Project'] = data.Project;
    }
    if (data.Address && infoWindow['Address'] === "n/a") {
        infoWindow['Address'] = data.Address;
    }
    if (data.DesignMethod && infoWindow['Design Method'] === "n/a") {
        infoWindow['Design Method'] = data.DesignMethod;
    }

    checkSaveSettings(data.settings, currentTransparencyOutside, items);
    saveSettings = data.settings;
    generateLevels();
    scroll = {
        groundPlane: groundPlane,
        wheelForClipping: wheelForClipping,
        negativeXpoint: negativeXpoint,
        negativeYpoint: negativeYpoint,
        negativeZpoint: negativeZpoint,
        positiveXpoint: positiveXpoint,
        positiveYpoint: positiveYpoint,
        positiveZpoint: positiveZpoint,
        closestPoint: closestPoint,
        horizontalMin: horizontalMin,
        horizontalMax: horizontalMax,
        verticalMin: verticalMin,
        verticalMax: verticalMax,
        xMove: xMove,
        yMove: yMove,
        zMove: zMove,
        clippingDone: clippingDone,
        cameraBeforeClipping: cameraBeforeClipping,
        controlsBefore: controlsBefore,
        scrollSpeedSave: scrollSpeedSave,
        speedDecimal: 0.95,
        distanceFromPlane: distanceFromPlane,
        previousCamera: null,
        enableClipping: false,
        enableScrollClipping: false,
        clippingSpeed: 1,
    };

    ({ container, scene, camera, renderer, outlinePass, effectFXAA, composer, stats, labelRenderer, controls, objectSelected, groundPlane, stationaryToolObjects, dimensionLabels} = init(group, screenShot, items, onWindowResize, scroll , raycaster, mouse, planes, version, helperPlanes, average, selection, isMetric, dimensionLinesKind, buttonsOn, store, tapeMeasureOn));

    await intialization(null);
    if(!newLayout){
        await new Promise(resolve => {
            async function animateOnce() {
                camera.updateProjectionMatrix();
                controls.update();
                composer.render();
                stats.update();
                labelRenderer.render(scene, camera);
                await new Promise(resolve => setTimeout(resolve, 1));
                // After first render, resolve the promise.
                resolve();
            }
            animateOnce();
        });
    }
}
async function csdPortal() {
    function getKeyFromURL() {
        const key = window.location.pathname.slice(1);
        return key;
    }
    key = getKeyFromURL();
    try {
        // Replace 'fds10f3r13' with the key you want to fetch
        // Generate a variable that will hold either localhost or csdportal depending on where the app is running
        let address = window.location.hostname;
        let port = process.env.NODE_ENV === 'production' ? '' : ':4000';
        address = window.location.protocol + '//' + address + port + '/data/';
        address += key;

        const response = await fetch(address);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        version = 2;
        let responseData = await response.json();

        data = responseData.data;
        // data = responseData;
        // console.log(data);
        pdfFile = responseData.pdf;
        isMetric = data.IsMetric === true ? true : isMetric;
        if (data.CompanyName && infoWindow['Company Name'] === "n/a") {
            infoWindow['Company Name'] = data.CompanyName;
        }
        if (data.ProjectNumber && infoWindow['Project Number'] === "n/a") {
            infoWindow['Project Number'] = data.ProjectNumber;
        }
        if (data.LayoutName && infoWindow['Layout Name'] === "n/a") {
            infoWindow['Layout Name'] = data.LayoutName;
        }
        if (data.Project && infoWindow['Project'] === "n/a") {
            infoWindow['Project'] = data.Project;
        }
        if (data.Address && infoWindow['Address'] === "n/a") {
            infoWindow['Address'] = data.Address;
        }
        if (data.DesignMethod && infoWindow['Design Method'] === "n/a") {
            infoWindow['Design Method'] = data.DesignMethod;
        }
        generateLevels();
        // calculateBoundingBox();
        // let isTouchDevice = false;
        // console.log('ontouchstart' in window)
        // console.log(navigator.maxTouchPoints)
        // isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0,
        scroll = {
            groundPlane: groundPlane,
            wheelForClipping: wheelForClipping,
            negativeXpoint: negativeXpoint,
            negativeYpoint: negativeYpoint,
            negativeZpoint: negativeZpoint,
            positiveXpoint: positiveXpoint,
            positiveYpoint: positiveYpoint,
            positiveZpoint: positiveZpoint,
            closestPoint: closestPoint,
            horizontalMin: horizontalMin,
            horizontalMax: horizontalMax,
            verticalMin: verticalMin,
            verticalMax: verticalMax,
            xMove: xMove,
            yMove: yMove,
            zMove: zMove,
            clippingDone: clippingDone,
            cameraBeforeClipping: cameraBeforeClipping,
            controlsBefore: controlsBefore,
            scrollSpeedSave: scrollSpeedSave,
            speedDecimal: 0.95,
            distanceFromPlane: distanceFromPlane,
            previousCamera: null,
            enableClipping: false,
            enableScrollClipping: false,
            clippingSpeed: 1,
        };
        ({ container, scene, camera, renderer, outlinePass, effectFXAA, composer, stats, labelRenderer, controls, objectSelected, groundPlane, stationaryToolObjects, dimensionLabels } = init(group, screenShot, items, onWindowResize, scroll , raycaster, mouse, planes, version, helperPlanes, average, selection, isMetric,dimensionLinesKind, buttonsOn, store, tapeMeasureOn));
        animate();
        intializationCSD();
        windowLoaded = true;
        await loadHangers(geometryCache, didntWork, hangerNames, version, lowPerformance, group);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
async function fetchRamInfo(){
    let ramInfo = await window.api.getRamInfo();
    if(ramInfo < 8){
        lowPerformance = true;
        items['Stud'].visibility = !lowPerformance;
    }
}
function startVue(){
    store.commit('setGroup', group);
    store.commit('setCamera', camera);
    store.commit('setRaycaster', raycaster);
    store.commit('setLevels', levelNames);
    store.commit('setBoundaryPoints', boundaryPoints);
    store.commit('setScene', scene);
    store.commit('setScroll', scroll);
    store.commit('setObjectSelected', objectSelected);
    store.commit('setPlanes', planes);
    store.commit('setItems', items);
    store.commit('setLocation', location);
    store.commit('setSelection', selection);
    store.commit('setScreenShot', screenShot);
    store.commit('setStoreReady', true);
    store.commit('setUrl', url);
    store.commit('setKey', key);
    store.commit('setVersion', version);
    store.commit('setPDF', pdfFile);
    store.commit('setInfoWindow', infoWindow);
    store.commit('setIsMetric', isMetric);
    store.commit('lowPerformance', lowPerformance);
    store.commit('highlightedMembers', highlightedMembers);
    store.commit('currentTransparency', currentTransparencyOutside);
    store.commit('saveSettings', saveSettings);
    const event = new CustomEvent('storeReady');
    window.dispatchEvent(event);
    app.use(store);
}
function initHTML(){
    loadingScreen = document.getElementById('loading_Screen');
    loadingScreen.style.display = 'none';
}
function generateLevels(){
    for(let i = 0; i < data.Levels.length; i++){
      levelNames[i] = {id: i, name: data.Levels[i].LevelName, visible: true, bottom: data.Levels[i].WallBottomElevation, top: data.Levels[i].WallTopElevation};
    }
    // console.log(levelNames)
}
function intializationCSD(){
    try{
        portalInialization(data, items, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, camera, raycaster, tapeMeasureOn);
        bbox.minX -= 20;
        bbox.maxX += 20;
        bbox.minZ -= 20;
        bbox.maxZ += 20;
        bbox.maxY += 20;
        average.x = (bbox.maxX + bbox.minX) / 2;
        average.z = (bbox.maxZ + bbox.minZ) / 2;
        bbox.minY = -1;
        boundaryPoints = {
            bbox: bbox,
            average: average,
        }
        group.children.forEach(element => {
            element.position.x -= average.x;
            element.position.z -= average.z;
        });
        generateHelperPlanes(counts, helperPlanes);
        startVue();
        // initHTML();
        finishInit(bbox, average, controls, camera, raycaster, items);
        windowLoaded = true;
        loadingScreen = document.getElementById('loading_Screen');
        loadingScreen.style.display = 'none';
        data.ConstructionLines.forEach(element => {
            generateConstructionLines(element, average, group, planes, constructionLineGUID, levelN, isMetric);
        });
    }catch(err){
        console.log(err);
    }
}
async function intialization(AddOn, lastOne){
    try{
        if(AddOn){
            data = AddOn;
        }
        electronInialization(data, items, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, saveSettings, camera, raycaster);
        if(lastOne || test){
            bbox.minX -= 20;
            bbox.maxX += 20;
            bbox.minZ -= 20;
            bbox.maxZ += 20;
            bbox.maxY += 20;
            average.x = (bbox.maxX + bbox.minX) / 2;
            average.z = (bbox.maxZ + bbox.minZ) / 2;
            bbox.minY = -1;
            boundaryPoints = {
            bbox: bbox,
            average: average,
            }
            group.children.forEach(element => {
                element.position.x -= average.x;
                element.position.z -= average.z;
            });
            generateHelperPlanes(counts, helperPlanes);
            startVue();
            // initHTML();
            finishInit(bbox, average, controls, camera, raycaster, items);
            if(lowPerformance){
                camera.layers.toggle(9);
                raycaster.layers.toggle(9);
            }
            windowLoaded = true;
            if(!newLayout){
                animate(); // Start your usual animation loop.
            }
            await loadHangers(geometryCache, didntWork, hangerNames, version, lowPerformance, group)
            t2 = performance.now();
            console.log("Call to doSomething took " + (t2 - t1) + " milliseconds.");
            loadingScreen = document.getElementById('loading_Screen');
            loadingScreen.style.display = 'none';
        }
        else if(AddOn){
            const boundingbox = new THREE.BoxGeometry(bbox.maxX - bbox.minX, bbox.maxY - bbox.minY, bbox.maxZ - bbox.minZ);
            const boundingboxmesh = new THREE.Mesh(boundingbox, new THREE.MeshBasicMaterial({color: 0x000000, wireframe: true}));
            boundingboxmesh.position.set(0, (bbox.minY + bbox.maxY) / 2, 0);
            boundingboxmesh.layers.set(1);
            boundingboxmesh.Description = "Bounding Box";
            let bb = new THREE.Box3().setFromObject(boundingboxmesh);
            if(bbox.maxX > bbox.maxZ){
                const value = bbox.maxX / bbox.maxZ;
                camera.position.set(bbox.maxX / value, bbox.maxY * 2, bbox.maxZ * value);}
            else{
                const value = bbox.maxZ / bbox.maxX;
                camera.position.set(bbox.maxX * value, bbox.maxY * 2, bbox.maxZ / value);}
            bb.getCenter(controls.target);
            for (const [key, value] of Object.entries(items)) {
                if (!value.visibility) {
                    raycaster.layers.disable(value.layer);
                    camera.layers.disable(value.layer);
                }
            }
            await new Promise(resolve => {
                async function animateOnce() {
                    camera.updateProjectionMatrix();
                    controls.update();
                    composer.render();
                    stats.update();
                    labelRenderer.render(scene, camera);
                    await new Promise(resolve => setTimeout(resolve, 1));
                    resolve();
                }
                animateOnce();
            });
        }
        // For each child in the group move the position to the center of the model
        data.ConstructionLines.forEach(element => {
            generateConstructionLines(element, average, group, planes, constructionLineGUID, levelN, isMetric);
        });
        // data.SquashBlockLine.forEach(element => {
        //     objectsVis[12] = true;
        //     generateSquashBlockLine(element, bbminX, bbmaxX, bbminY, bbmaxY, bbminZ, bbmaxZ, average.x, average.z, group);
        // });
    }catch(err){
        console.log(err);
    }
}
function onWindowResize() {
    const width = container.clientWidth;
    const height = container.clientHeight;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize( width, height);
    composer.setSize( width, height);
    labelRenderer.setSize(width, height);
}
function animate() {
    requestAnimationFrame(animate);
    camera.updateProjectionMatrix();
    controls.update();
    composer.render();
    stats.update();
    labelRenderer.render(scene, camera);
    stationaryToolObjects.children.forEach(child => {
        child.lookAt(camera.position);
    });
}
async function listenerBeginningData(){
    return new Promise(resolve => {
        window.api.receive("allData", (information) => {
            console.log(information)
            if(information.allMember)
            {
                resolve(information.allMember)
            }
            else if(information.delete)
            {
                removeObjects(information.delete);
            }
            else if(information.add)
            {
                addObjects(information.add);
            }
            else if(information.selected)
            {
                ({firstTime, isColor} = calling(information.selected, group, highlightedMembers, firstTime, isColor, items, store, camera, raycaster, levelNames, currentTransparencyOutside));

            }
            else if(information.change_Window)
            {
                location.reload();
            }
            else if(information.NewLayout){
                newLayout = true;
                resetEverything()
                data = information.NewLayout;
                electronWindow();
            }
            else if(information.AddOn){
                intialization(information.AddOn, false);
            }
            else if(information.LastOne){
                intialization(information.LastOne, true);
            }
        });
        if(!windowLoaded){
            window.api.send("started_again", "send");
        }
    });
}
function addObjects(data){
    let opacityObject = {
        transparent: false,
        opacity: currentTransparencyOutside / 10
    }
    try{
        addMembersMethod(data, items, store, opacityObject, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, camera, raycaster, planes, constructionLineGUID, levelN, scroll);
    }catch(err){
        console.log(err);
        console.log("error occured in adding objects");
    }
}
function removeObjects(response){
    if(constructionLineGUID[0] === response[0]){
        planes.pop();
        group.traverse( function ( object ) {
            if(object.thisIsConstructionLine){
                group.remove(object)
            }
        });
        constructionLineGUID = [];
        if(levelN){
            for(let i = 0; i < group.children.length; i++){
                group.children[i].material.clippingPlanes = planes;
            }
        }
    }
    else{
        for(let i = 0; i < response.length; i++){
            for(let j = 0; j < group.children.length; j++){
                if(group.children[j].userData.GUID === response[i]){
                    let desc = group.children[j].userData.Description;
                    group.remove(group.children[j]);
                    j--;
                    if(items.hasOwnProperty(desc)){
                        items[desc].count--;
                        if(items[desc].count === 0){
                            store.commit('removeModel', desc);
                            items[desc].inModel = false;
                        }
                    }
                }
            }
        }
        for(let i = 0; i < group.children.length; i++){
            group.children[i].material.opacity = 1;
            group.children[i].material.transparent = false;
            group.children[i].needsUpdate = true;
        }
    }
}
function resetEverything(){
    items = {
        "backerBlocks": { id: "backerBlocks", name: "Backer", layer: 18, visibility: true, opacity: false, inModel: false, count: 0 },
        "BeamByOthers": { id: "BeamByOthers", name: "Beams", layer: 6, visibility: true, opacity: false, inModel: false, count: 0 },
        "Blocking": { id: "Blocking", name: "Blocking", layer: 22, visibility: true, opacity: false, inModel: false, count: 0 },
        // "BlockingLine": { id: "BlockingLine", name: "Blocking Line", layer: 11, visibility: true, opacity: false, inModel: false, count: 0 },
        "Column": { id: "Column", name: "Column", layer: 4, visibility: true, opacity: false, inModel: false, count: 0 },
        "Decking": { id: "Decking", name: "Decking", layer: 11, visibility: true, opacity: false, inModel: false, count: 0 },
        "Glulam": { id: "Glulam", name: "Glulam", layer: 21, visibility: true, opacity: false, inModel: false, count: 0 },
        "GroundLayer": { id: "GroundLayer", name: "Ground Layer", layer: 20, visibility: true, opacity: false, inModel: false, count: 0 },
        "iJoist": { id: "iJoist", name: "I-Joist", layer: 2, visibility: true, opacity: false, inModel: false, count: 0 },
        "JoistClips": { id: "JoistClips", name: "Joist Clips", layer: 19, visibility: true, opacity: false, inModel: false, count: 0 },
        "Lumber": { id: "Lumber", name: "Lumber", layer: 7, visibility: true, opacity: false, inModel: false, count: 0 },
        "LVL": { id: "LVL", name: "LVLs", layer: 5, visibility: true, opacity: false, inModel: false, count: 0 },
        "noggin": { id: "noggin", name: "Noggin", layer: 17, visibility: true, opacity: false, inModel: false, count: 0 },
        "Obstruction": { id: "Obstruction", name: "Obstruction", layer: 13, visibility: true, opacity: false, inModel: false, count: 0 },
        "OpenJoist": { id: "OpenJoist", name: "OpenJoists", layer: 8, visibility: true, opacity: false, inModel: false, count: 0 },
        "Plate": { id: "Plate", name: "Plate", layer: 23, visibility: true, opacity: false, inModel: false, count: 0 },
        "PonyWall": { id: "PonyWall", name: "Pony Wall", layer: 10, visibility: true, opacity: false, inModel: false, count: 0 },
        "Rimboard": { id: "Rimboard", name: "Rimboards", layer: 3, visibility: true, opacity: false, inModel: false, count: 0 },
        "Roof": { id: "Roof", name: "Roof", layer: 1, visibility: true, opacity: false, inModel: false, count: 0 },
        "SquashBlockLine": { id: "SquashBlockLine", name: "Squash Block", layer: 12, visibility: true, opacity: false, inModel: false, count: 0 },
        "Steel": { id: "Steel", name: "Steel", layer: 16, visibility: true, opacity: false, inModel: false, count: 0 },
        "Strongback": { id: "Strongback", name: "Strongback", layer: 15, visibility: true, opacity: false, inModel: false, count: 0 },
        "Stud": { id: "Stud", name: "Stud", layer: 9, visibility: !lowPerformance, opacity: false, inModel: false, count: 0 },
        "Truss": { id: "Truss", name: "Truss", layer: 14, visibility: true, opacity: false, inModel: false, count: 0 },
        "Wall": { id: "Wall", name: "Walls", layer: 0, visibility: true, opacity: false, inModel: false, count: 0 },
    };
    geometryCache = {};
    didntWork = {};
    selection = {on: true};
    constructionLineGUID = [], levelN;
    hangerNames = new Set();
    geometryCache = {};
    didntWork = {};
    helperPlanes.remove(...helperPlanes.children);
    helperPlanes = new THREE.Group();
    counts = {minCounts: {}, maxCounts: {}, memberCount: {count: 0}};
    planes = [];
    objectSelected = null;
    boundaryPoints = {};
    levelNames = [];
    outlinePass, composer, effectFXAA;
    mouse = new THREE.Vector2();
    raycaster = new THREE.Raycaster();
    bbox = {minX: Infinity, minY: Infinity, minZ: Infinity, maxX: -Infinity, maxY: -Infinity, maxZ: -Infinity};
    average = {x: null, z: null};
    scene.remove(dimensionLabels);
    for (let i = 0; i < dimensionLabels.children.length; i++) {
        dimensionLabels.children[i].element.remove();
    }
    scene.remove(...scene.children);
    group.remove(...group.children);
    group = new THREE.Group();
    container = null;
    loadingScreen = null;
    camera = null, scene = null, renderer = null, controls = null;
    app.unmount();
    app = createApp(App);
    app.mount('#app');
    url = null;
    labelRenderer = null;
    dimensionLabels = null;
    key = null;
    isMetric = null, production = false, screenShot = null;
    pdfFile = null;
    t1 = null;
    t2 = null;
    infoWindow = {"Company Name": "n/a", "Project Number": "n/a", "Layout Name": "n/a", "Project": "n/a", "Address": "n/a", "Design Method": "n/a"}
}
if(process.env.NODE_ENV === 'production'){
    production = true;
    version = 0;
}
else{
    test = true;
}
if( process.env.IS_ELECTRON){
    electronWindow();
}
else{
    csdPortal();
}

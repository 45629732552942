import * as THREE from 'three';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateObstruction(ob, average, group, objectOpacity){
    let Diameter = ob.Diameter;
    let cp1 = new THREE.Vector3(parseFloat(ob.ConstructionPoints[0].split(",")[0]), parseFloat(ob.ConstructionPoints[0].split(",")[2]), parseFloat(ob.ConstructionPoints[0].split(",")[1]));
    let cp2 = new THREE.Vector3(parseFloat(ob.ConstructionPoints[1].split(",")[0]), parseFloat(ob.ConstructionPoints[1].split(",")[2]), parseFloat(ob.ConstructionPoints[1].split(",")[1]));
    let height = cp2.y - cp1.y; 
    const extractedPoints = [];
    extractedPoints.push(cp1);
    extractedPoints.push(cp2);
    let geometry = new THREE.CylinderGeometry(Diameter/2, Diameter/2, height, 64);
    let material = new THREE.MeshPhongMaterial({color: 0xffffff, flatShading: true, side: THREE.DoubleSide});
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(cp1.x - average.x, height / 2 + cp1.y,cp1.z - average.z);
    mesh.layers.set(13);
    mesh.forRayTracing = true;
    mesh.level = ob.Level;
    // let circleLineMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });

    let circleGeometry = new THREE.CircleGeometry(Diameter / 2, 64);
    circleGeometry.rotateX(Math.PI / 2);

    let positions = [cp1.y, cp1.y + height / 2, cp2.y];

    let geometries = []; 

    for (let posY of positions) {
      let tmpGeometry = circleGeometry.clone();
      tmpGeometry.translate(cp1.x - average.x, posY, cp1.z - average.z);
      geometries.push(tmpGeometry);
    }
    
    let mergedGeometry = mergeBufferGeometries(geometries, false);

    let edgesGeo = new THREE.EdgesGeometry(mergedGeometry);
    
    mesh.userData = {
      level: ob.Level,
      GUID: ob.UndoGuid,
      Description: "Obstruction", 
      id: ob.$id,
      color: 0xffffff,
      normalColor: 0xffffff,
      tapeMeasure: true,
      edges: edgesGeo,
      points: extractedPoints,
    };
    mesh.displayWindowInfo = {
      Type: "Obstruction",
      // ID: ob.$id,
      Level: ob.Level
    }
    if(objectOpacity.transparent){
      mesh.material.transparent = true;
      mesh.material.opacity = objectOpacity.opacity;
    }
    group.add(mesh);
}
export {generateObstruction}
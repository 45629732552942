<!-- eslint-disable  -->
<template>
    <div id = "GUI" class = "no-select">
        <div id = control_Panel v-if="controlPanelVisible">
            <button class = "control_Buttons" id = "dots_Button" v-on:click="hideControls()"><img class = "img-btn" src='../../public/imagesGUI/threeDots.png'/></button>
            <button class = "control_Buttons" id = "Visibility_Button" v-on:click="this.toggleMenus('visShow')"><img class = "img-btn" src='../../public/imagesGUI/Visibility_Icon.png'/></button>
            <button class = "control_Buttons" id = "Levels_Button" v-on:click="this.toggleMenus('levShow')"><img class = "img-btn" src='../../public/imagesGUI/Levels.png' /></button>
            <button class = "control_Buttons" id = "Seperate_Button" v-on:click="this.toggleMenus('sepShow')"><img id= "sep-btn" src='../../public/imagesGUI/seperation.png' /></button>
            <button class = "control_Buttons" id = "Clipping_Button" ref ="clippingStuff" v-on:click="this.toggleMenus('clipShow')"><img class = "img-btn" src='../../public/imagesGUI/clipping.png' /></button>
            <button class = "control_Buttons" id = "Random_Toggle" v-on:click="this.toggleMenus('randomShow')"><img class = "img-btn" src='../../public/imagesGUI/random.png' /></button>
            <button class = "control_Buttons" id = "Sharing_Icon" v-if="showShare()" v-on:click="this.toggleMenus('shareShow')"><img class = "img-btn" src='../../public/imagesGUI/sharing_Icon.png' /></button>
            <button class = "control_Buttons" id = "dimensionTool" v-if="showDimension()" v-on:click="this.toggleMenus('dimensionShow')"><img class= "img-btn" src='../../public/imagesGUI/dimensionTool.png' /></button>
            <button class = "control_Buttons" id = "PDF_Button" v-if="showPDF()" v-on:click="this.openPDF()"><img class = "img-btn" src='../../public/imagesGUI/pdf.png' /></button>
            <button class = "control_Buttons" id = "Reload_Button" v-on:click="forceReload"><img class = "img-btn" src='../../public/imagesGUI/reload.png' /></button>
            <button class = "control_Buttons" id = "Info_Button" v-on:click="newInfoShow = true, buttonClicked = true"><img class = "img-btn" src='../../public/imagesGUI/info.png' /></button>

        </div>
        <div class = "setting-panel" v-if="visShow">
            <div id = "closeBox">
                <button id = "closeButton" v-on:click="this.closePanel('visShow')"></button>
            </div>
            <div top="closedImages">
                <button class="reset-button" v-on:click="toggleAllVisibility">
                    <img id="eyeTwo" src='../../public/imagesGUI/Eyetwo.png' alt="Eye Two" />
                </button>
                <button class="reset-button" v-on:click="toggleAllOpacity">
                    <img id="transparency" src='../../public/imagesGUI/TransparencyIcon.png' alt="Transparency Icon" />
                </button><br>
            </div>
            <div v-for="(item, key) in itemsInModel" :key="key" style="text-align: left;">
                <input type="checkbox" v-model="item.visibility" @input="toggleVisibilityObjects(item)" ref="visibilityCheckboxes">
                <input type="checkbox" v-model="item.opacity" @input="toggleOpacity(item)" ref="opacityCheckboxes" style="margin-right: 7px;">
                <label :for="item.id">{{ item.name }}</label><br>
            </div><br>
            <div id="button-container">
                <div>
                    <div id="enable_all">
                        <button id="enable_all" v-on:click="enableAll">Enable All</button>
                    </div>
                    <div id="disable_all">
                        <button id="disable_all" v-on:click="disableAll">Disable All</button>
                    </div>
                </div>
                <div id="save_button" v-if="showSave()">
                    <button id="save" v-on:click="saveSaveSettings">Save</button>
                </div>
                <!-- <div>
                    <div id="enable_all">
                        <button id="enable_all" v-on:click="enableAll">Enable All</button>
                    </div>
                    <div id="disable_all">
                        <button id="disable_all" v-on:click="disableAll">Disable All</button>
                    </div>
                </div> -->
            </div><br>
            <div id="transparency_slider">
                <label for="transparency">Transparency:</label><br>
                <input type="range" min="1" max="9" step="1" v-model="currentTransparency" @input="updateTransparency"><br><br>
            </div>
        </div>
        <div class = "setting-panel" v-if="levShow">
            <div id = "closeBox">
                <button id = "closeButton" v-on:click="this.closePanel('levShow')"></button>
            </div>
            <div v-for="level in levels" :key="level.id" style="text-align: left">
                <input type="checkbox" v-model="level.visible" ref="levelCheckboxes" @input="selectLevel" style="margin-right: 10px;">
                <label :for="level.name">{{ capitalizeEachWord(level.name) }}</label><br>
            </div><br>
            <div id = "enable_all_Levels">
                <button id = "all_Levels" v-on:click="allLevels">Show All</button>
            </div><br>
            <div id = "disable_all_Levels">
                <button id = "all_Levels" v-on:click="noLevels">Hide All</button>
            </div>
        </div>
        <div class = "setting-panel" v-if="sepShow">
            <div id = "closeBox">
                <button id = "closeButton" v-on:click="this.closePanel('sepShow')"></button>
            </div>
            <!-- Add a slider-->
            <div id = "slider">
                <label for="myRange">Vertical</label>
                <input type="range" min="0" max="10" v-model = "vertSeperation" @input="verticalSep"><br>
                <label for="myRange">Horizontal</label>
                <input type="range" min="0" max="10" v-model = "horSeperation" @input="horizontalSep"><br>
                <button id = "restoreSeperation" v-on:click="restoreSeperation">Restore</button><br>
            </div>
        </div>
        <div class="setting-panel" v-if="dimensionShow">
            <div id="closeBox">
                <button id="closeButton" @click="closePanel('dimensionShow')"></button>
            </div>
            <div id = "AllControls" v-if="buttonsToggleOn">
                <label for="freeViewToggle" id="freeViewLabel">Dimension Tool</label><br>
                <div id="radioGroup">
                    <label :for="'allEdges'" class="image-label" @mouseover="showTooltip" @mouseout="hideTooltip">
                        <input type="radio" id="allEdges" value="allEdges" v-model="dimensionLinesKind" class="image-input"/>
                        <img class="img-btnDim" src='../../public/imagesGUI/AllLines.png'/>
                    </label>

                    <label :for="'endEdges'" class="image-label" @mouseover="showTooltip" @mouseout="hideTooltip">
                        <input type="radio" id="endEdges" value="endEdges" v-model="dimensionLinesKind" class="image-input"/>
                        <img class="img-btnDim" src='../../public/imagesGUI/EdgeLines.png'/>
                    </label>

                    <label :for="'cornerPoints'" class="image-label" @mouseover="showTooltip" @mouseout="hideTooltip">
                        <input type="radio" id="cornerPoints" value="cornerPoints" v-model="dimensionLinesKind" class="image-input"/>
                        <img class="img-btnDim" src='../../public/imagesGUI/EdgePoints.png'/>
                    </label>
                </div>
                <div>
                    <button id="clearLinesBtn" @click="clearDimensionLines" @mouseover="showTooltip" @mouseout="hideTooltip">Clear Lines</button>
                </div>
            </div>

        </div>
        <div class="tooltip" id="tooltip-allEdges">Snap To All Edges</div>
        <div class="tooltip" id="tooltip-endEdges">Snap To End Edges</div>
        <div class="tooltip" id="tooltip-cornerPoints">Snap To End Points</div>
        <div class="tooltip" id="tooltip-clearLines">'E' Key Clears Lines</div>
        <div class = "setting-panel" v-if="clipShow">
            <div id = "closeBox">
                <button id = "closeButton" v-on:click="this.closePanel('clipShow')"></button>
            </div><br>
            <div id = "showClipSettings" v-if="!this.scroll.enableScrollClipping">
                <label>Enable Clipping</label><br>
                <input type = "checkbox"  v-model = "this.scroll.enableClipping" @change = "addClippingPlanes">
            </div>
            <template v-if="this.scroll.enableClipping">
                <div v-for="(item, index) in clippingElements" :key="index">
                    <label v-if="item.label" :for="item.id">{{ item.label }}</label>
                    <input v-if="item.type === 'checkbox'" v-model="item.value" type="checkbox" :id="item.id" @input="item.onChange(item.value)">
                    <input v-if="item.type === 'range'" v-model="item.valueNumber" type="range" :id="item.id" :min="item.min" :max="item.max" @input="item.onChange(item.valueNumber)">
                </div>
                <div id="Restore_Clipping">
                    <button @click="restoreClipping">Restore Clipping</button>
                </div>
            </template>
            <div id = "scrollWheelClipping" v-if="!this.scroll.enableClipping && showDimension()">
                <label>Scroll Wheeling Clipping</label><br>
                <input type = "checkbox"  v-model = "this.scroll.enableScrollClipping" ref="scrollWheelCheckbox" @change = "scrollWheelClipping">
            </div>
            <template v-if="this.scroll.enableScrollClipping">
                <div id = "scrollWheelClippingSettings">
                    <label>Free View</label><br>
                    <input type = "checkbox" ref="freeViewCheckbox" @change = "freeView"><br>
                    <label>Distance From Plane</label>
                    <input type = "range" min="50" max="700" v-model = "this.scroll.distanceFromPlane" @input="changeDistance">
                    <label>Clipping Speed</label>
                    <input type = "range" min="1" max="6" v-model = "this.scroll.clippingSpeed" @input="changeScrollSpeed">
                </div>

            </template>
        </div>
        <div class = "setting-panel" v-if="shareShow">
            <button id = "closeButton" v-on:click="this.closePanel('shareShow')"></button>
            <div id = "shareLink">
                <br>
                <label>Sharing Options</label><br><br>
                <button id = "copyLink" v-on:click="sendLink">Copy Link</button><br><br>
                <button @click="generate = !generate">Generate QR Code</button>
                <div v-if="generate" class="qr-wrapper">
                    <qrcode-vue class="qr-code" :value="this.url"></qrcode-vue>
                    <img src="../../public/imagesGUI/isView.jpg" class="overlay-logo" alt="Logo" />
                </div>
            </div>
        </div>
        <div id = "displayWindow" v-if = "displayInfoShow">
            <table>
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in objectSelected.object.displayWindowInfo" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ this.convertToFeetInches(key,value) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="infoWindow" v-if="newInfoShow">
            <table>
                <thead>
                    <tr>
                        <th>Property</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in windowInfo" :key="key">
                        <td>{{ key }}</td>
                        <td>{{ value }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class = "setting-panel" v-if = "randomShow">
            <div id = "closeBox">
                <button id = "closeButton" v-on:click="this.closePanel('randomShow')"></button>
            </div><br>
            <div id="hangers_Toggle">
                <input type="checkbox" v-model="hangerOn" id="hangersToggle" @change="hangersToggle" style="margin-bottom: 10px;">
                <label>Hangers</label><br>
            </div>
            <div id = "selection_Toggle">
                <input type="checkbox" v-model="selection.on" id = "selectionToggle" @change="selectionToggle" style="margin-bottom: 10px;" checked>
                <label>Selection</label><br>
            </div>
            <div id = "selection_Toggle" v-if = "!this.isMetric">
                <input type="checkbox" v-model="formatFeet" id = "formatFeetToggle" @change="!this.formatFeet" style="margin-bottom: 10px;" checked>
                <label>In Feet</label><br>
            </div>
            <button @click="forceReload">Force Reload</button>
        </div>
        <div id="insert-static-image" class="insert-static-image" v-if="screenShotTabOn() && showInsertStaticImage">
            <span class="static-image-text">Insert static image</span>
            <button id="okButton" v-on:click="screenShotOkay" class="btn btn-primary">OK</button>
            <button id="cancelButton" v-on:click="screenShotCancel" class="btn btn-secondary">Cancel</button>
        </div>
        <div id="rotate-screen" v-if ="isPortrait && isTouchDevice">
            <h1>Please rotate your device to landscape mode for a better viewing experience</h1>
        </div>
        <div id = "panelButton" v-if="panelButtonControl">
            <button class = "control_Buttons1" v-on:click="panelButtonToggle()"><img class = "img-btn1" src = '../../public/imagesGUI/updots.png'/></button>
        </div>
        <button id = full @click="goFullScreen" v-show="enableFullButton()"><img id = fullScreenButton src = '../../public/imagesGUI/fullScreen.png'/></button>
        <button id = empty @click="exitFullScreen" v-show="exitFullButton()"><img id = fullScreenButton src = '../../public/imagesGUI/nonFullScreen.png'/></button>
    </div>
</template>
<script>
import * as THREE from 'three'
import QrcodeVue from 'qrcode.vue';
export default {
    /* eslint-disable */
    name: 'GUI',
    data(){
        return {
            hideTimeout: null,
            visShow: false,
            formatFeet: true,
            levShow: false,
            sepShow: false,
            clipShow: false,
            shareShow: false,
            pdfShow: false,
            dimensionShow: false,
            showInsertStaticImage: true,
            controlPanelVisible: true,
            displayInfoShow: false,
            newInfoShow: false,
            newWindowOpen: false,
            buttonClicked: false,
            randomShow: false,
            vertSeperation: 0,
            horSeperation: 0,
            previousVU: 0,
            previousValueU: 0,
            totalU: 0,
            previousVA: 0,
            previousValueA: 0,
            totalA: 0,
            clippingSettingsVis: false,
            // enableClipping: false,
            planeX: null,
            planeY: null,
            planeZ: null,
            bbminX: 0,
            bbminZ: 0,
            bbmaxX: 0,
            bbmaxZ: 0,
            bbmaxY: 0,
            averageX: 0,
            averageZ: 0,
            // updateTransparencyValue: 3,
            // enableScrollClipping: false,
            hangerOn: !this.$store.state.lowPerformance,
            params: {
                planeX: {
                    constant: 0,
                    displayHelper: false,
                    negated: false,
                },
                planeY: {
                    constant: 0,
                    displayHelper: false,
                },
                planeZ: {
                    constant: 0,
                    displayHelper: false,
                    negated: false,
                },
            },
            clippingElements: [],
            generate: false,
            isPortrait: this.checkOrientation(),
            isTouchDevice: 'ontouchstart' in window || navigator.msMaxTouchPoints,
            isFullScreen: false,
            panelButtonControl: false,
            timeoutId: null,
            isTouched:true,
        }
    },
    components: {
        QrcodeVue
    },
    mounted(){
        document.addEventListener('click', this.update)
        window.addEventListener("resize", this.checkOrientation);
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
        document.addEventListener('mozfullscreenchange', this.exitHandler, false);
        document.addEventListener('fullscreenchange', this.exitHandler, false);
        document.addEventListener('MSFullscreenChange', this.exitHandler, false);
        this.startTimeout();
        window.addEventListener('touchstart', this.clearTimeout);
        window.addEventListener('mousedown', this.clearTimeout);
    },
    beforeUnmount(){
        document.removeEventListener('click', this.update)
        window.addEventListener("resize", this.checkOrientation);
        document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
        document.addEventListener('mozfullscreenchange', this.exitHandler, false);
        document.addEventListener('fullscreenchange', this.exitHandler, false);
        document.addEventListener('MSFullscreenChange', this.exitHandler, false);
        window.removeEventListener('touchstart', this.clearTimeout);
        window.removeEventListener('mousedown', this.clearTimeout);
        window.removeEventListener('keydown', this.handleKeyPress);
    },
    computed: {
        group(){
            return this.$store.state.group
        },
        camera(){
            return this.$store.state.camera
        },
        raycaster(){
            return this.$store.state.raycaster
        },
        levels(){
            return this.$store.state.levels;
        },
        boundaryPoints(){
            return this.$store.state.boundaryPoints;
        },
        scene(){
            return this.$store.state.scene;
        },
        scroll(){
            return this.$store.state.scroll;
        },
        objectSelected(){
            return this.$store.state.objectSelected;
        },
        planes(){
            return this.$store.state.planes;
        },
        items(){
            return this.$store.state.items;
        },
        location(){
            return this.$store.state.location;
        },
        selection(){
            return this.$store.state.selection;
        },
        screenShot(){
            return this.$store.state.screenShot;
        },
        version(){
            return this.$store.state.version;
        },
        url(){
            return this.$store.state.url;
        },
        itemsInModel() {
            return Object.values(this.$store.state.items).filter(item => item.inModel);
        },
        pdfFile(){
            return this.$store.state.pdfFile;
        },
        windowInfo(){
            return this.$store.state.infoWindow;
        },
        isMetric(){
            return this.$store.state.isMetric;
        },
        lowPerformance(){
            return this.$store.state.lowPerformance;
        },
        highlightedMembers(){
            return this.$store.state.highlightedMembers;
        },
        currentTransparency: {
            get() {
                return this.$store.state.currentTransparency;
            },
            set(value) {
                this.$store.commit('currentTransparency', value);
            }
        },
        saveSettings(){
            return this.$store.state.saveSettings;
        },
        dimensionLinesKind: {
            get() {
                return this.$store.state.dimensionLinesKind;
            },
            set(value) {
                this.$store.commit('SET_SELECTED_DIMENSION_LINE_KIND', value);
            }
        },
        buttonsToggleOn: {
            get() {
                return this.$store.state.buttonsToggleOn;
            },
            set(value) {
                this.$store.commit('SET_DIMENSION_TOOL_ON', value);
            }
        },
        buttonsToggleFreeView: {
            get() {
                return this.$store.state.buttonsToggleFreeView;
            },
            set(value) {
                this.$store.commit('SET_DIMENSION_TOOL_FREE_VIEW', value);
            }
        },
    },
    methods: {
        toggleMenus: function(whichOne){
            if(whichOne === 'shareShow'){
                window.api.send('push_To_Database', 'push_To_Database');
            }
            if(whichOne === 'dimensionShow'){
                if(this.buttonsToggleOn && this.dimensionShow){
                    const event = new KeyboardEvent('keydown', { key: 'w' });
                    document.dispatchEvent(event);
                } else if(!this.buttonsToggleOn && !this.dimensionShow){
                    const event = new KeyboardEvent('keydown', { key: 'w' });
                    document.dispatchEvent(event);
                }
            }
            const openAlready = this[whichOne];
            this.visShow = false;
            this.sepShow = false;
            this.levShow = false;
            this.clipShow = false;
            this.randomShow = false;
            this.shareShow = false;
            this.dimensionShow = false;
            if(openAlready){
                this[whichOne] = false;
            }
            else{
                this[whichOne] = true;
            }
        },
        closePanel: function(whichOne){
            if(whichOne === 'dimensionShow'){
                const event = new KeyboardEvent('keydown', { key: 'w' });
                document.dispatchEvent(event);
            }
            this[whichOne] = false;
        },
        selectLevel: function(){
            this.group.traverse(object => {
                if (object.userData.Description) {
                    for( let i = 0; i < this.$refs.levelCheckboxes.length; i++){
                        if (this.$refs.levelCheckboxes[i].checked && object.userData.level.includes(this.levels[i].name)) {
                            if(object.userData.isHanger && this.hangerOn){
                                object.material.visible = true
                            }
                            else if(!object.userData.isHanger){
                                object.material.visible = true
                            }
                        }
                        else if(!this.$refs.levelCheckboxes[i].checked && object.userData.level.includes(this.levels[i].name)){
                            if(object.userData.isHanger && this.hangerOn){
                                object.material.visible = false
                            }
                            else if(!object.userData.isHanger){
                                object.material.visible = false
                            }
                        }
                    }
                }
            })
        },
        allLevels: function(){
            this.group.traverse(object => {
                if (object.userData.Description) {
                    if(object.userData.isHanger && this.hangerOn){
                        object.material.visible = true
                    }
                    else if(!object.userData.isHanger){
                        object.material.visible = true
                    }
                }
            })
            for ( let i = 0; i < this.levels.length; i++){
                this.levels[i].visible = true
            }
        },
        noLevels: function(){
            this.group.traverse(object => {
                if (object.userData.Description) {
                    object.material.visible = false
                }
            })
            for ( let i = 0; i < this.levels.length; i++){
                this.levels[i].visible = false
            }
        },
        toggleAllVisibility: function(){
            let visOffForAtleastOne = false;
            for(let i = 0; i < this.$refs.visibilityCheckboxes.length; i++){
                if(!this.$refs.visibilityCheckboxes[i].checked){
                    visOffForAtleastOne = true;
                }
            }
            if(visOffForAtleastOne){
                this.enableAll();
            }
            else{
                this.disableAll();
            }
        },
        toggleAllOpacity: function(){
            let opOffForAtleastOne = false;
            for(let i = 0; i < this.$refs.opacityCheckboxes.length; i++){
                if(!this.$refs.opacityCheckboxes[i].checked && this.$refs.visibilityCheckboxes[i].checked){
                    opOffForAtleastOne = true;
                }
            }
            if(opOffForAtleastOne){
                for(let i = 0; i < this.$refs.opacityCheckboxes.length; i++){
                    if(this.$refs.visibilityCheckboxes[i].checked){
                        this.$refs.opacityCheckboxes[i].checked = true;
                        this.toggleOpacity(this.itemsInModel[i], true);
                    }
                }
            }
            else{
                for(let i = 0; i < this.$refs.opacityCheckboxes.length; i++){
                    if(this.$refs.visibilityCheckboxes[i].checked){
                        this.$refs.opacityCheckboxes[i].checked = false;
                        this.toggleOpacity(this.itemsInModel[i], false);
                    }
                }
            }
        },
        enableAll: function(){
            this.camera.layers.enableAll();
            this.raycaster.layers.enableAll();
            this.group.traverse(object => {
                if (object.userData.Description && object.userData.Description !== "WebHole") {
                    object.material.transparent = false
                    object.material.opacity = 0.25
                    object.material.needsUpdate = true
                }
            })
            this.$store.commit('enableAll');
        },
        disableAll: function(){
            this.camera.layers.disableAll();
            this.raycaster.layers.disableAll();
            this.$store.commit('disableAll');
        },
        toggleVisibilityObjects: function(item) {
            // console.log(item)
            if(item.visibility && item.opacity){
                this.group.traverse(object => {
                    if (object.userData.Description && object.userData.Description.includes(item.id)) {
                        object.material.transparent = false
                        object.material.opacity = 0.25
                        object.material.needsUpdate = true
                    }
                })
                if(item.id === "groundLayer"){
                    console.log(this.scroll.groundPlane.material)
                    this.scroll.groundPlane.material.transparent = false
                    this.scroll.groundPlane.material.opacity = 0.25
                    this.scroll.groundPlane.material.needsUpdate = true
                }
                item.opacity = false
            }
            this.camera.layers.toggle(item.layer);
            this.raycaster.layers.toggle(item.layer);
        },
        toggleOpacity: function(item, toToggle){
            if(!item.visibility){
                this.camera.layers.toggle(item.layer);
                this.raycaster.layers.toggle(item.layer);
                item.visibility = true
            }
            this.group.traverse(object => {
                if (object.userData.Description && object.userData.Description.includes(item.id)) {
                    if(toToggle !== null){
                        object.material.transparent = !object.material.transparent
                        object.material.opacity = this.currentTransparency / 10
                        object.material.needsUpdate = true
                    }
                    else{
                        object.material.transparent = toToggle
                        object.material.opacity = this.currentTransparency / 10
                        object.material.needsUpdate = true
                    }
                }
            })
            if(item.id === "groundLayer"){
                if(toToggle != null){
                    this.scroll.groundPlane.material.transparent = !this.scroll.groundPlane.material.transparent
                    this.scroll.groundPlane.material.opacity = this.currentTransparency / 10
                    this.scroll.groundPlane.material.needsUpdate = true
                }
                else{
                    this.scroll.groundPlane.material.transparent = toToggle
                    this.scroll.groundPlane.material.opacity = this.currentTransparency / 10
                    this.scroll.groundPlane.material.needsUpdate = true
                }
            }
        },
        updateTransparency: function(){
            this.itemsInModel.forEach(item => {
                if (item.opacity) {
                    this.group.traverse(object => {
                        if (object.userData.Description && object.userData.Description.includes(item.id) && !this.highlightedMembers.includes(object.userData.GUID)) {
                            object.material.transparent = true;
                            object.material.opacity = this.currentTransparency / 10;
                            object.material.needsUpdate = true;
                        }
                    });
                }
            });
        },
        verticalSep: function(){
            let currentValue = 0;
            let levels = this.levels;
            this.vertSeperation = parseInt(this.vertSeperation);
            if(this.vertSeperation < this.previousVU){
                switch(this.vertSeperation){
                    case 9: currentValue = 450 - this.totalU; this.totalU = 450; break;
                    case 8: currentValue = 400 - this.totalU; this.totalU = 400; break;
                    case 7: currentValue = 350 - this.totalU; this.totalU = 350; break;
                    case 6: currentValue = 300 - this.totalU; this.totalU = 300; break;
                    case 5: currentValue = 250 - this.totalU; this.totalU = 250; break;
                    case 4: currentValue = 200 - this.totalU; this.totalU = 200; break;
                    case 3: currentValue = 150 - this.totalU; this.totalU = 150; break;
                    case 2: currentValue = 100 - this.totalU; this.totalU = 100; break;
                    case 1: currentValue = 50 - this.totalU; this.totalU = 50; break;
                    case 0: currentValue = 0 - this.totalU; this.totalU = 0; break;
                }
                this.previousVU = this.vertSeperation;
                this.previousValueU = this.totalU;
            }
            else if(this.vertSeperation > this.previousVU){
                switch(this.vertSeperation){
                    case 1: currentValue = 50; this.totalU = 50; break;
                    case 2: currentValue = 100 - this.previousValueU; this.totalU = 100; break;
                    case 3: currentValue = 150 - this.previousValueU; this.totalU = 150; break;
                    case 4: currentValue = 200 - this.previousValueU; this.totalU = 200; break;
                    case 5: currentValue = 250 - this.previousValueU; this.totalU = 250; break;
                    case 6: currentValue = 300 - this.previousValueU; this.totalU = 300; break;
                    case 7: currentValue = 350 - this.previousValueU; this.totalU = 350; break;
                    case 8: currentValue = 400 - this.previousValueU; this.totalU = 400; break;
                    case 9: currentValue = 450 - this.previousValueU; this.totalU = 450; break;
                    case 10: currentValue = 500 - this.previousValueU; this.totalU = 500; break;
                }
                this.previousValueU = this.totalU;
                this.previousVU = this.vertSeperation;
            }
            else{
                this.previousVU = this.vertSeperation;
            }
            this.group.traverse( function ( object ) {
                if(object.userData.Description){
                    for(let i = 0; i < levels.length; i++){
                        if(object.userData.level === levels[i].name){
                            object.position.y += currentValue * (i * 0.5);
                        }
                    }
                }
            });
        },
        horizontalSep: function(){
            let currentValue = 0;
            let levels = this.levels;
            this.horSeperation = parseInt(this.horSeperation);
            if(this.horSeperation < this.previousVA){
                switch(this.horSeperation){
                    case 9: currentValue = 450 - this.totalA; this.totalA = 450; break;
                    case 8: currentValue = 400 - this.totalA; this.totalA = 400; break;
                    case 7: currentValue = 350 - this.totalA; this.totalA = 350; break;
                    case 6: currentValue = 300 - this.totalA; this.totalA = 300; break;
                    case 5: currentValue = 250 - this.totalA; this.totalA = 250; break;
                    case 4: currentValue = 200 - this.totalA; this.totalA = 200; break;
                    case 3: currentValue = 150 - this.totalA; this.totalA = 150; break;
                    case 2: currentValue = 100 - this.totalA; this.totalA = 100; break;
                    case 1: currentValue = 50 - this.totalA; this.totalA = 50; break;
                    case 0: currentValue = 0 - this.totalA; this.totalA = 0; break;
                }
                this.previousVA = this.horSeperation;
                this.previousValueA = this.totalA;
            }
            else if(this.horSeperation > this.previousVU){
                switch(this.horSeperation){
                    case 1: currentValue = 50; this.totalA = 50; break;
                    case 2: currentValue = 100 - this.previousValueA; this.totalA = 100; break;
                    case 3: currentValue = 150 - this.previousValueA; this.totalA = 150; break;
                    case 4: currentValue = 200 - this.previousValueA; this.totalA = 200; break;
                    case 5: currentValue = 250 - this.previousValueA; this.totalA = 250; break;
                    case 6: currentValue = 300 - this.previousValueA; this.totalA = 300; break;
                    case 7: currentValue = 350 - this.previousValueA; this.totalA = 350; break;
                    case 8: currentValue = 400 - this.previousValueA; this.totalA = 400; break;
                    case 9: currentValue = 450 - this.previousValueA; this.totalA = 450; break;
                    case 10: currentValue = 500 - this.previousValueA; this.totalA = 500; break;
                }
                this.previousValueA = this.totalA;
                this.previousVA = this.horSeperation;
            }
            else{
                this.previousVA = this.horSeperation;
            }
            this.group.traverse( function ( object ) {
                if(object.userData.Description){
                    for(let i = 0; i < levels.length; i++){
                        if(object.userData.level === levels[i].name){
                            object.position.x += currentValue * i;
                            object.position.z += currentValue * i;
                        }
                    }
                }
            });
        },
        restoreSeperation: function(){
            let levels = this.levels;
            let verted = this.totalU
            let horzed = this.totalA
            if(this.vertSeperation !== 0){
                this.vertSeperation = 0;
                this.group.traverse( function ( object ) {
                    if(object.userData.Description){
                        for(let i = 0; i < levels.length; i++){
                            if(object.userData.level === levels[i].name){
                                object.position.y -= verted * (i*0.5);
                            }
                        }
                    }
                });
                this.totalU = 0;
                this.previousVU = 0;
                this.previousValueU = 0;
            }
            if(this.horSeperation !== 0){
                this.horSeperation = 0;
                this.group.traverse( function ( object ) {
                    if(object.userData.Description){
                        for(let i = 0; i < levels.length; i++){
                            if(object.userData.level === levels[i].name){
                                object.position.x -= horzed * i;
                                object.position.z -= horzed * i;
                            }
                        }
                    }
                });
                this.totalA = 0;
                this.previousVA = 0;
                this.previousValueA = 0;
            }
        },
        initClippingPlane: function(){
            this.planes.push(new THREE.Plane( new THREE.Vector3( -1, 0, 0 ),(this.bbmaxX - this.averageX + 20)))
            this.planes.push(new THREE.Plane( new THREE.Vector3( 0, -1, 0 ),(this.bbmaxY + 10)))
            this.planes.push(new THREE.Plane( new THREE.Vector3( 0, 0, -1 ),(this.bbmaxZ - this.averageZ + 20)))
            this.params.planeX.constant = (this.bbmaxX - this.averageX + 20);
            this.params.planeY.constant = (this.bbmaxY + 10);
            this.params.planeZ.constant = (this.bbmaxZ - this.averageZ + 20);
        },
        addClippingPlanes: function(){
            this.clippingSettingsVis = !this.clippingSettingsVis;
            this.scroll.enableClipping = true;
            if(this.clippingSettingsVis){
                this.scene.remove(this.scroll.groundPlane);
                if(this.planes.length !== 0){
                    for(let i = 0; i < this.planes.length; i++){
                        this.planes.pop();
                    }
                    this.group.children.forEach( (object) => {
                        if(object.userData.Description){
                            object.material.clippingPlanes = null;
                        }
                    });
                }
                this.bbminX = this.boundaryPoints.bbox.minX
                this.bbmaxY = this.boundaryPoints.bbox.maxY
                this.bbmaxZ = this.boundaryPoints.bbox.maxZ
                this.bbmaxX = this.boundaryPoints.bbox.maxX
                this.bbminZ = this.boundaryPoints.bbox.minZ
                try{
                this.averageX = this.boundaryPoints.average.x
                this.averageZ = this.boundaryPoints.average.z
                }catch{
                    console.log(this.boundaryPoints)
                }
                this.initClippingPlane();
                this.initClippingElements();
                this.group.children.forEach( (object) => {
                    if(object.userData.Description && object.userData.Description !== "ignore groundLayer"){
                        object.material.clippingPlanes = this.planes;
                    }
                });
            }
            else{
                this.scene.add(this.scroll.groundPlane);
                for(let i = 0; i < this.planes.length;){
                    this.planes.pop();
                }
                this.group.children.forEach( (object) => {
                    if(object.userData.Description){
                        object.material.clippingPlanes = null;
                    }
                });
                this.scroll.enableClipping = false;
            }
        },
        initClippingElements() {
            this.clippingElements = [
            {
                id: 'planeX-constant',
                label: 'Plane X',
                type: 'range',
                min: this.bbminX - this.averageX - 20,
                max: this.bbmaxX - this.averageX + 20,
                step: 0.2,
                valueNumber: this.bbmaxX - this.averageX + 20,
                onChange: (value) => {
                    this.planes[0].constant = value;
                }
            },
            {
                id: 'planeX-negated',
                label: 'Negate Plane X',
                type: 'checkbox',
                value: false,
                onChange: () => {
                    this.planes[0].negate();
                    this.params.planeX.negated = true;
                    this.params.planeX.constant = this.planes[0].constant + this.bbmaxX - this.averageX;
                }
            },
            {
                id: 'planeY-constant',
                label: 'Plane Y',
                type: 'range',
                min: -20,
                max: this.bbmaxY + 10,
                step: 0.2,
                valueNumber: this.bbmaxY + 10,
                onChange: (value) => {
                    this.planes[1].constant = value;
                }
            },
            {
                id: 'planeZ-constant',
                label: 'Plane Z',
                type: 'range',
                min: this.bbminZ - this.averageZ - 20,
                max: this.bbmaxZ - this.averageZ + 20,
                step: 0.2,
                valueNumber: this.bbmaxZ - this.averageZ + 20,
                onChange: (value) => {
                    this.planes[2].constant = value;
                }
            },
            {
                id: 'planeZ-negated',
                label: 'Negate Plane Z',
                type: 'checkbox',
                value: false,
                onChange: () => {
                    this.planes[2].negate();
                    this.params.planeZ.negated = true;
                    this.params.planeZ.constant = this.planes[2].constant + this.bbmaxZ - this.averageZ;
                }
            },

            ];
        },
        restoreClipping: function(){
            if(this.planes[0].constant !== this.clippingElements[0].max){
                if(this.params.planeX.negated){
                    this.planes[0].negate();
                    this.params.planeX.negated = false;
                    this.clippingElements[1].value = false;
                }
                this.planes[0].constant = this.clippingElements[0].max
            }
            if(this.planes[1].constant !== this.params.planeY.constant){
                this.planes[1].constant = this.params.planeY.constant;
            }
            if(this.planes[2].constant !== this.clippingElements[4].max){
                if(this.params.planeZ.negated){
                    this.planes[2].negate();
                    this.params.planeZ.negated = false;
                    this.clippingElements[4].value = false;

                }
                this.planes[2].constant = this.clippingElements[4].max;
            }
            this.group.children.forEach( (object) => {
                if(object.userData.Description){
                    object.material.clippingPlanes = this.planes;
                }
            });
            this.clippingElements.forEach((item) => item.valueNumber = item.max);
            this.scroll.enableClipping = true;
        },
        updateSpheres() {
            // Calculate new boundary limits
            const newMinBoundX = Math.max(this.planes[3].point.x, this.boundaryPoints.bbox.minX - this.boundaryPoints.average.x - 20);
            const newMinBoundZ = Math.max(this.planes[5].point.z, this.boundaryPoints.bbox.minZ - this.boundaryPoints.average.z - 20);
            const newMaxBoundX = Math.min(this.planes[0].point.x, this.boundaryPoints.bbox.maxX - this.boundaryPoints.average.x + 20);
            const newMaxBoundZ = Math.min(this.planes[2].point.z, this.boundaryPoints.bbox.maxZ - this.boundaryPoints.average.z + 20);
            const newMinBoundY = Math.max(this.planes[4].point.y, -1);
            const newMaxBoundY = Math.min(this.planes[1].point.y, this.boundaryPoints.bbox.maxY + 10);

            // Center point for X and Z
            const centerX = (newMinBoundX + newMaxBoundX) / 2;
            const centerZ = (newMinBoundZ + newMaxBoundZ) / 2;

            // Update plane points
            this.planes[0].point.set(newMaxBoundX, (newMaxBoundY + newMinBoundY) / 2, centerZ); // Max X plane
            this.planes[1].point.set(centerX, newMaxBoundY, centerZ);                        // Max Y plane
            this.planes[2].point.set(centerX, (newMaxBoundY + newMinBoundY) / 2, newMaxBoundZ); // Max Z plane
            this.planes[3].point.set(newMinBoundX, (newMaxBoundY + newMinBoundY) / 2, centerZ); // Min X plane
            this.planes[4].point.set(centerX, newMinBoundY, centerZ);                        // Min Y plane
            this.planes[5].point.set(centerX, (newMaxBoundY + newMinBoundY) / 2, newMinBoundZ); // Min Z plane
        },
        findClosestSphere: function(){
            let closestDistance = this.camera.position.distanceTo(this.planes[0].point.clone());
            this.scroll.closestPoint = this.planes[0];
            this.planes.forEach((plane) => {
                if(this.camera.position.distanceTo(plane.point) < closestDistance){
                    closestDistance = this.camera.position.distanceTo(plane.point);
                    this.scroll.closestPoint = plane;
                }
            });
        },
        setPlane(){
            if(this.scroll.closestPoint.name === "planeMaxX"){
                this.scroll.whichPlane = 0;
                this.camera.position.set(this.scroll.closestPoint.point.x + this.scroll.distanceFromPlane, this.scroll.closestPoint.point.y, this.scroll.closestPoint.point.z);
                this.scroll.controls.target.set(this.planes[3].point.x, this.planes[3].point.y, this.planes[3].point.z);
            }
            else if(this.scroll.closestPoint.name === "planeMaxY"){
                this.scroll.whichPlane = 1;
                this.camera.position.set(this.scroll.closestPoint.point.x, this.scroll.closestPoint.point.y + this.scroll.distanceFromPlane, this.scroll.closestPoint.point.z);
                this.scroll.controls.target.set(this.planes[4].point.x, this.planes[4].point.y, this.planes[4].point.z);
            }
            else if(this.scroll.closestPoint.name === "planeMaxZ"){
                this.scroll.whichPlane = 2;
                this.camera.position.set(this.scroll.closestPoint.point.x, this.scroll.closestPoint.point.y, this.scroll.closestPoint.point.z + this.scroll.distanceFromPlane);
                this.scroll.controls.target.set(this.planes[5].point.x, this.planes[5].point.y, this.planes[5].point.z);
            }
            else if(this.scroll.closestPoint.name === "planeMinX"){
                this.scroll.whichPlane = 3;
                this.camera.position.set(this.scroll.closestPoint.point.x - this.scroll.distanceFromPlane, this.scroll.closestPoint.point.y, this.scroll.closestPoint.point.z);
                this.scroll.controls.target.set(this.planes[0].point.x, this.planes[0].point.y, this.planes[0].point.z);
            }
            else if(this.scroll.closestPoint.name === "planeMinY"){
                this.scroll.whichPlane = 4;
                this.camera.position.set(this.scroll.closestPoint.point.x, this.scroll.closestPoint.point.y - this.scroll.distanceFromPlane, this.scroll.closestPoint.point.z);
                this.scroll.controls.target.set(this.planes[1].point.x, this.planes[1].point.y, this.planes[1].point.z);
            }
            else if(this.scroll.closestPoint.name === "planeMinZ"){
                this.scroll.whichPlane = 5;
                this.camera.position.set(this.scroll.closestPoint.point.x, this.scroll.closestPoint.point.y, this.scroll.closestPoint.point.z - this.scroll.distanceFromPlane);
                this.scroll.controls.target.set(this.planes[2].point.x, this.planes[2].point.y, this.planes[2].point.z);
            }
            this.scroll.previousCamera = this.camera.position.clone();
        },
        scrollWheelClipping: function(){
            this.clippingSettingsVis = !this.clippingSettingsVis;
            if(this.clippingSettingsVis){
                if(this.planes.length !== 0){
                    for(let i = 0; i < this.planes.length; i++){
                        this.planes.pop();
                    }
                    this.group.children.forEach( (object) => {
                        if(object.userData.Description){
                            object.material.clippingPlanes = null;
                        }
                    });
                }
                this.initScrollPlanes();
                this.scroll.wheelForClipping = true;
                this.scene.remove(this.scroll.groundPlane);
                this.findClosestSphere();
                this.setPlane();
                this.scroll.clippingDone = false;
                this.scroll.cameraBeforeClipping = this.camera.position.clone();
                this.scroll.controlsBefore = this.scroll.controls.target.clone();
                if(!this.scroll.clippingDone && this.scroll.wheelForClipping){
                    switch(this.scroll.scrollSpeedSave){
                        case 1: this.scroll.speedDecimal = 0.995; break;
                        case 2: this.scroll.speedDecimal = 0.99; break;
                        case 3: this.scroll.speedDecimal = 0.98; break;
                        case 4: this.scroll.speedDecimal = 0.97; break;
                        case 5: this.scroll.speedDecimal = 0.96; break;
                        case 6: this.scroll.speedDecimal = 0.95; break;
                    }
                }
                this.scroll.distanceFromPlane = parseInt(this.scroll.distanceFromPlane);
                this.group.children.forEach( (object) => {
                    if(object.userData.Description){
                        object.material.clippingPlanes = this.planes;
                    }
                });
                window.addEventListener('keydown', this.handleKeyPress);
            }
            else{
                this.scroll.wheelForClipping = false;
                this.scroll.clippingDone = true;
                this.scene.add(this.scroll.groundPlane);
                while (this.planes.length > 0){
                    this.planes.pop();
                }
                this.group.children.forEach( (object) => {
                    if(object.userData.Description){
                        object.material.clippingPlanes = null;
                    }
                });
                this.scroll.controls.target.set(this.scroll.controlsBefore.x, this.scroll.controlsBefore.y, this.scroll.controlsBefore.z);
                this.camera.position.set(this.scroll.cameraBeforeClipping.x, this.scroll.cameraBeforeClipping.y, this.scroll.cameraBeforeClipping.z);
                window.removeEventListener('keydown', this.handleKeyPress);
            }
        },
        initScrollPlanes: function() {
            const planeMaxX  = new THREE.Plane(new THREE.Vector3(-1, 0, 0), (this.boundaryPoints.bbox.maxX - this.boundaryPoints.average.x + 20))
            const planeMaxY  = new THREE.Plane(new THREE.Vector3(0, -1, 0), (this.boundaryPoints.bbox.maxY + 10))
            const planeMaxZ  = new THREE.Plane(new THREE.Vector3(0, 0, -1), (this.boundaryPoints.bbox.maxZ - this.boundaryPoints.average.z + 20))
            const planeMinX  = new THREE.Plane(new THREE.Vector3(1, 0, 0), -(this.boundaryPoints.bbox.minX - this.boundaryPoints.average.x - 20))
            const planeMinY  = new THREE.Plane(new THREE.Vector3(0, 1, 0), -(this.boundaryPoints.bbox.minY - 10))
            const planeMinZ  = new THREE.Plane(new THREE.Vector3(0, 0, 1), -(this.boundaryPoints.bbox.minZ - this.boundaryPoints.average.z - 20))
            planeMaxX.point = new THREE.Vector3(this.boundaryPoints.bbox.maxX - this.boundaryPoints.average.x + 20, (this.boundaryPoints.bbox.maxY + 10) / 2, 0);
            planeMaxY.point = new THREE.Vector3(0, this.boundaryPoints.bbox.maxY + 10, 0);
            planeMaxZ.point = new THREE.Vector3(0, (this.boundaryPoints.bbox.maxY + 10) / 2, this.boundaryPoints.bbox.maxZ - this.boundaryPoints.average.z + 20);
            planeMinX.point = new THREE.Vector3(this.boundaryPoints.bbox.minX - this.boundaryPoints.average.x - 20, (this.boundaryPoints.bbox.maxY + 10) / 2, 0);
            planeMinY.point = new THREE.Vector3(0, -1, 0);
            planeMinZ.point = new THREE.Vector3(0, (this.boundaryPoints.bbox.maxY + 10) / 2, this.boundaryPoints.bbox.minZ - this.boundaryPoints.average.z - 20)
            planeMaxX.name = "planeMaxX";
            planeMaxY.name = "planeMaxY";
            planeMaxZ.name = "planeMaxZ";
            planeMinX.name = "planeMinX";
            planeMinY.name = "planeMinY";
            planeMinZ.name = "planeMinZ";
            this.planes.push(planeMaxX);
            this.planes.push(planeMaxY);
            this.planes.push(planeMaxZ);
            this.planes.push(planeMinX);
            this.planes.push(planeMinY);
            this.planes.push(planeMinZ);
        },
        freeView: function(event, isKeyPress) {
            let isChecked;
            if (isKeyPress) {
                this.$refs.freeViewCheckbox.checked = !this.$refs.freeViewCheckbox.checked;
                isChecked = this.$refs.freeViewCheckbox.checked;
            } else {
                isChecked = event.target.checked;
            }

            if (isChecked) {
                this.beforeFreeCamera = this.camera.position.clone();
                this.beforeFreeControls = this.scroll.controls.target.clone();
                this.scroll.wheelForClipping = false;
                this.camera.position.y += 50; // or any other adjustments for free view
            } else {
                this.updateSpheres();
                this.scroll.wheelForClipping = true;
                this.findClosestSphere();
                this.setPlane();
            }
        },
        handleKeyPress(event) {
            if (event.key === 'f' || event.key === 'F') {
                this.freeView(null, true);
            }
        },
        changeDistance: function(event){
            const distance = parseInt(event.target.value);
            if(this.scroll.closestPoint.name === "planeMaxX"){
                this.camera.position.setX(this.planes[0].constant + distance);
            }
            else if(this.scroll.closestPoint.name === "planeMaxY"){
                this.camera.position.setY(this.planes[1].constant + distance);
            }
            else if(this.scroll.closestPoint.name === "planeMaxZ"){
                this.camera.position.setZ(this.planes[2].constant + distance);
            }
            else if(this.scroll.closestPoint.name === "planeMinX"){
                this.camera.position.setX(-this.planes[3].constant - distance);
            }
            else if(this.scroll.closestPoint.name === "planeMinY"){
                this.camera.position.setY(-this.planes[4].constant - distance);
            }
            else if(this.scroll.closestPoint.name === "planeMinZ"){
                this.camera.position.setZ(-this.planes[5].constant - distance);
            }
            this.scroll.previousCamera = this.camera.position.clone();
        },
        changeScrollSpeed: function(event){
            switch(parseInt(event.target.value)){
                case 6: this.scroll.speedDecimal = 0.995;break;
                case 5: this.scroll.speedDecimal = 0.99;break;
                case 4: this.scroll.speedDecimal = 0.98;break;
                case 3: this.scroll.speedDecimal = 0.97;break;
                case 2: this.scroll.speedDecimal = 0.96;break;
                case 1: this.scroll.speedDecimal = 0.95;break;
            }

        },
        update: function(){
            this.displayInfoShow = false;
            if(this.buttonClicked){
                if(!this.newWindowOpen){
                    this.newWindowOpen = true;
                }
                else{
                    this.newWindowOpen = false;
                    this.newInfoShow = false;
                }
            }
            else{
                this.newInfoShow = false;
                this.newWindowOpen = false;
                if(this.objectSelected.on){
                    this.displayInfoShow = true;
                }
            }
            this.buttonClicked = false;
        },
        hangersToggle: function(event){
            console.log(this.hangerOn)
            this.group.traverse( (object) => {
                if(object.userData.Description){
                    if(object.userData.isHanger){
                        for(let i = 0; i < this.levels.length; i++){
                            if(this.levels[i].visible && object.userData.level === this.levels[i].name){
                                if(event.target.checked){
                                    object.material.visible = true;
                                }else{
                                    object.material.visible = false;
                                }
                            }
                        }
                    }
                }
            });
        },
        forceReload: function(){
            if(this.version === 0){
                window.api.send("Reload", "Reload");
            }
            else {
                location.reload();
            }
        },
        selectionToggle: function(){
            
        },
        panelButtonToggle: function(){
            this.panelButtonControl = !this.panelButtonControl;
            this.controlPanelVisible = !this.controlPanelVisible;
        },
        screenShotOkay: function(){
            this.showInsertStaticImage = false;
            this.controlPanelVisible = false;
            this.panelButtonControl = false;
            setTimeout(() => {
                window.api.send("Screenshot", "Screenshot");
            }, 100);
        },
        screenShotCancel: function(){
            window.api.send("Screenshot", "Screenshot_cancel");
        },
        screenShotTabOn: function(){
            return this.screenShot;
        },
        async sendLink(){
            try{
                await navigator.clipboard.writeText(this.url);
                console.log("Copied to clipboard");
            }
            catch(err){
                console.log("Failed to copy to clipboard", err);
            }
        },
        showShare: function(){
            return !this.screenShot && this.version === 0;
        },
        showPDF: function(){
            return this.version === 2 && this.pdfFile;
        },
        showSave: function(){
            return this.version === 0;
        },
        showDimension: function(){
            return !this.isTouchDevice;
        },
        capitalizeEachWord: function(string) {
            return string
                .split(/(\s|\/)/)  // split the string at each space or slash
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // capitalize the first letter of each word
                .join('');  // join the words back together
        },
        checkOrientation() {
            if(this.isTouchDevice){
                let isPortrait = window.innerHeight > window.innerWidth;
                this.isPortrait = isPortrait;
                return isPortrait;
            }
        },
        goFullScreen() {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen();
            }
            this.isFullScreen = true;
        },
        exitFullScreen(){
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
            this.isFullScreen = false;
        },
        checkFullScreen() {
            this.isFullScreen = !!(document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement);
        },
        exitHandler() {
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                this.isFullScreen = false;
            }
        },
        enableFullButton(){
            return !this.isFullScreen && (this.version === 2);
        },
        exitFullButton(){
            return this.isFullScreen && (this.version === 2);
        },
        hideControls(){
            this.controlPanelVisible = false;
            this.panelButtonControl = true;
            this.visShow = false;
            this.sepShow = false;
            this.levShow = false;
            this.clipShow = false;
            this.randomShow = false;
            this.shareShow = false;
        },
        showControls(){
            this.controlPanelVisible = true;
        },
        isInModel(item) {
            return item.inModel;
        },
        startTimeout() {
            this.timeoutId = setTimeout(() => {
                this.isTouched = false;
            }, 1000); // 10 seconds
        },
        clearTimeout() {
            clearTimeout(this.timeoutId);
            this.isTouched = true;
            this.startTimeout();
        },
        async openPDF(){
            let response = await fetch('data:application/pdf;base64,' + this.pdfFile);
            let blob = await response.blob();
            let url = URL.createObjectURL(blob);
            window.open(url);
        },
        openNewInfo(){
            this.displayInfoShow = false;
        },
        convertToFeetInches(key, value) {
            const keysToConvert = ["Width", "Depth", "Elevation", "Length", "Thickness", "Height", "Diameter", "Distance"];

            if (!this.isMetric && keysToConvert.includes(key) && this.formatFeet) {
                // Assuming value is in inches
                const numValue = parseFloat(value);
                if (!isNaN(numValue)) {
                    const feet = Math.floor(numValue / 12);
                    const inches = Math.floor(numValue % 12);
                    const sixteenths = Math.round((numValue % 1) * 16);

                    return `${feet}-${inches}-${sixteenths}`;
                }
            }
            return value;
        },
        saveSaveSettings(){
            this.itemsInModel.forEach((item) => {
                this.saveSettings[item.id + "Visibility"] = item.visibility;
                this.saveSettings[item.id + "Opacity"] = item.opacity;
            });
            this.saveSettings["Opacity"] = this.currentTransparency;
            const flattenedObject = {...this.saveSettings};
            const jsonString = JSON.stringify(flattenedObject);
            window.api.send("saveSettings", jsonString);
        },
        computeItemsInModel(){
            return Object.values(this.$store.state.items).filter(item => item.inModel);
        },
        clearDimensionLines(){
            const event = new KeyboardEvent('keydown', { key: 'e' });
            document.dispatchEvent(event);
        },
        toggleDimensionTool(){
            this.buttonsToggleOn = !this.buttonsToggleOn;
            const event = new KeyboardEvent('keydown', { key: 'w' });
            document.dispatchEvent(event);
            if(!this.buttonsToggleOn){
              this.dimensionShow = false;
            }
        },
        showTooltip(event) {
            let target = event.target;
            while (target && !target.classList.contains('image-label') && !target.id.includes('dimensionToolButtons') && !target.id.includes('clearLinesBtn')) {
                target = target.parentElement;
            }

            let tooltipId;
            if(target.id.includes('dimensionToolButtons')) {
                tooltipId = "tooltip-dimensionToolButtons";
            } else if(target.id.includes('clearLinesBtn')) {
                tooltipId = "tooltip-clearLines";
            } else {
                tooltipId = "tooltip-" + target.querySelector('.image-input').id;
            }

            const tooltip = document.getElementById(tooltipId);
            clearTimeout(this.hideTimeout);

            const settingPanel = document.querySelector('.setting-panel');
            const settingPanelRect = settingPanel.getBoundingClientRect();

            tooltip.style.top = (settingPanelRect.bottom + 1) + "px";

            if (tooltipId === 'tooltip-allEdges') {
                tooltip.style.left = (target.offsetLeft - 4) + "px";
            } else if (tooltipId === 'tooltip-endEdges') {
                tooltip.style.left = (target.offsetLeft + (target.offsetWidth / 2) - (tooltip.offsetWidth / 2) - 50) + "px";
            } else if (tooltipId === 'tooltip-cornerPoints') {
                tooltip.style.left = (target.offsetLeft + (target.offsetWidth / 2) - (tooltip.offsetWidth / 2) - 64) + "px";
            } else if (tooltipId === 'tooltip-dimensionToolButtons'){
                tooltip.style.left = (target.offsetLeft + (target.offsetWidth / 2) - (tooltip.offsetWidth / 2) - 74) + "px";
            } else if (tooltipId === 'tooltip-clearLines'){
                tooltip.style.left = (target.offsetLeft + (target.offsetWidth / 2) - (tooltip.offsetWidth / 2) - 60) + "px";
            } else {
                tooltip.style.left = (target.offsetLeft + (target.offsetWidth / 2) - (tooltip.offsetWidth / 2)) + "px";
            }

            this.hideTimeout = setTimeout(() => {
                tooltip.style.display = 'block';
            }, 300);
        },
        hideTooltip(event) {
            clearTimeout(this.hideTimeout);

            const tooltips = document.querySelectorAll('.tooltip');
            tooltips.forEach(tooltip => {
                tooltip.style.display = 'none';
            });
        }
    },
    watch: {
        '$store.state.items': {
          deep: true,
          handler() {
              this.itemsInModel = this.computeItemsInModel();
          }
        },
      buttonsToggleOn(newValue, oldValue) {
          if(!newValue){
            this.dimensionShow = false;
          }
        }
    },
    created(){
        this.itemsInModel = this.computeItemsInModel();
    }
}
</script>
<style scoped>
.no-select {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}
.setting-panel {
  position: absolute;
  padding: 12px;
  top: 3.5vw;
  left: 0;
  width: 160px;
  height: auto;
  max-height: 90vh;
  font-weight: bold;
  background-color: rgba(256, 256, 256, 0.5);
  border-radius: 2px;
  overflow-y: auto;
}

/* For non-touch devices */
@media not all and (hover: none) {
  .setting-panel {
    /* Initially, set the scrollbar width to zero to hide it */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  .setting-panel::-webkit-scrollbar {
    width: 6px;
  }

  .setting-panel::-webkit-scrollbar-thumb {
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.05), transparent);
    border-radius: 4px;
  }

  .setting-panel:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: background 0.3s;
  }

  .setting-panel::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.01);
    border-radius: 4px;
  }
}

/* For touch devices */
@media (hover: none) {
  .setting-panel {
    max-height: 55vh;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: auto;
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
  }
}
#level_selector{
    position: relative;
    text-align: left;
    width: 150px;
    height: 100%;
    font-weight: bold;
    background-color: rgba(256, 256, 256, 0.5);
}
#control_Panel{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.5vw;
    transform: translate(0, 0);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 2px;
}
.img-btn{
    width: 2.5vw;
    height: 2.5vw;
    margin: 0 !important;
    border: 0px;
    padding: 0px;
    background-color: #ffffff;
}
.img-btn1{
    width: 2.5vw;
    height: 2.5vw;
    margin: 0 !important;
    border: 0px;
    padding: 0.50vw;
    background-color: rgba(256, 256, 256);
    border-radius: 5px;
}
#eyeTwo{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 13px;
    transform: translate(0, 0);
    border: none;
    transition: transform 0.7s ease-in-out;
}
#transparency{
    width: 18px;
    height: 18px;
    position: absolute;
    left:37px;
    transform: translate(0, 0);
    border: none;
    transition: transform 0.7s ease-in-out;
}
#fullScreenButton{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.7s ease-in-out;
}
#fullScreenButton:active{
    transform: scale(1.2);
}
#fullScreenButton:focus{
    outline: none;
}
#full{
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 15px;
    left: 15px;
}
#empty{
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    width: 26px;
    height: 26px;
    position: absolute;
    bottom: 15px;
    left: 15px;
}
#fullScreenButton:hover{
    cursor: pointer;
}
#nonFullScreenButton{
    bottom: 15px;
    left: 15px;
    z-index: 1000;
    width: 26px;
    height: 26px;
    position: absolute;
    transform: translate(0, 0);
    border: none;
    transition: transform 0.7s ease-in-out;
}
#nonFullScreenButton:active{
    transform: scale(1.2);
}
#nonFullScreenButton:focus{
    outline: none;
}
#closeButton{
    position: absolute;
    top: 0;
    right: 0;
    width: 17px;
    height: 17px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
}
#closeButton:before{
    content: "x";
    color: white;
    font-size: 12px;
    font-weight: bold;
}
#displayWindow{
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 999;
    background-color:white;
    border: 1px solid #ccc;
    width: relative;
    height: relative;
    overflow: auto;
    max-height: 200px; /* adjust this value based on your needs */
    overflow-y: auto;  /* enables vertical scrolling when the content height exceeds the max-height */
    -webkit-overflow-scrolling: touch;
}
#infoWindow{
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 999;
    background-color:white;
    border: 1px solid #ccc;
    width: relative;
    height: relative;
    overflow: auto;
}
#random_Toggles{
    position: absolute;
    padding-bottom: 7px;
    top: 5.5vw;
    left: 0;
    width: 150px;
    height: auto;
    font-weight: bold;
    background-color: rgba(256, 256, 256, 0.5);
}
#panelButton{
    position: fixed;
    top: 0;
    left: 0;
    /* width: 17px; */
    /* height: 17px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border : none;
    cursor: pointer;
    margin: 0;
    padding: 0;

}
table {
  border-collapse: collapse;
  width: 100%;
  font-size: 14px;
}
th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
.insert-static-image {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #f8f9fa;
    padding: 15px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.static-image-text {
    font-weight: bold;
    font-size: 16px;
    margin-right: 10px;
}
.btn {
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 5px;
    transition: background-color 0.2s, box-shadow 0.2s;
}
.btn-primary {
    background-color: #007bff;
    color: white;
}
.btn-primary:hover {
    background-color: #0069d9;
}
.btn-primary:active {
    background-color: #0056b3;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.btn-secondary {
    background-color: #6c757d;
    color: white;
}
.btn-secondary:hover {
    background-color: #5a6268;
}
.btn-secondary:active {
    background-color: #4e555b;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.qr-wrapper {
    position: relative;
    display: inline-block; /* Ensures container wraps tightly around the QR code */
}

.qr-code {
    display: block; /* Ensures that the QR code takes up its full space */
}

.overlay-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the logo */
    width: 46px; /* Adjust as required */
    height: 35px; /* Adjust as required */
    z-index: 10; /* Ensures the logo appears above the QR code */
}
.control_Buttons {
  padding: 2px;
  border: none;
  background-color: rgba(256, 256, 256, 0);
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 5px;
}
.control_Buttons1 {
  padding: 0vw;
  border: none;
  background-color: rgba(256, 256, 256, 0);
  font-weight: bold;
  cursor: pointer;
}
#sep-btn{
    width: 1.9vw;
    height: 1.9vw;
}
#button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

#save_button {
    margin-right: 5px;
    margin-bottom: 1px;
}

#enable_all {
    /* margin-right: 5px; */
    margin-bottom: 5px;
}
#disable_all{
    margin-bottom: 1px;
}

#rotate-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.90);
  color: #fff;
  z-index: 9999;
}
#rotate-screen h1 {
  font-size: 2em;
  color: #fff;
  text-align: center;
  padding: 20px;
}
button.reset-button {
    background: none;
    padding: 0;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: inline-block;
    vertical-align: top;  /* This can help align the button with other inline elements */
    overflow: hidden;     /* Ensures no extra space inside the button */
    line-height: 0;      /* Removes potential added line-height */
}

/* If the images have a default margin, reset it */
button.reset-button img {
    margin: 0;
    display: block;
    transition: transform 0.3s, opacity 0.3s, box-shadow 0.2s; /* Added box-shadow transition */
}

/* Enhanced hover effect */
button.reset-button:hover img {
    transform: scale(1.05);  /* Further enlarging the image */
    opacity: 0.8;           /* Reduced opacity more */
}

/* Active (Click) effect */
button.reset-button:active img {
    transform: scale(1.04); /* Slightly reduced scale to simulate a press effect */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); /* Shadow for depth */
}
#radioGroup {
    position: relative;
}
.image-label {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px; /* You can adjust or remove this if you no longer want rounded corners on the label */
  transition: background-color 0.3s ease;
}
.image-input {
  display: none;
}

.image-input:checked + .img-btnDim {
  border-color: black; /* Border turns black when the input is checked */
}
.img-btnDim {
  display: block;
  width: 38px;
  height: 38px;
  background-color: transparent; /* Default background is transparent */
  border: 3px solid transparent; /* 2px border that is transparent by default */
  box-sizing: border-box; /* includes border in total width/height */
  transition: border-color 0.2s ease; /* For a smooth border color transition */
}
.tooltip {
    display: none;
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    background-color: rgba(247, 235, 235, 0.40);
    color: black;
    font-size: 12px;
    white-space: nowrap;
    z-index: 999; /* ensures the tooltip is on top of other elements */
}
</style>

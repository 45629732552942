/* eslint-disable */ 
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generatePonyWall(ponyWall, average, group, objectOpacity){
    let ponyWallMesh = null;
    let extractedPoints = [];
    for(let i = 0; i < ponyWall.PonyWallPositions.length; i++){
        let points = [];
        for (let j = 0; j < ponyWall.PonyWallPositions[i].length; j++) {
          let point = {};
          point = {
            x: parseFloat(ponyWall.PonyWallPositions[i][j].split(",")[0]) * 20 - average.x,
            y: parseFloat(ponyWall.PonyWallPositions[i][j].split(",")[1]) * 20,
            z: parseFloat(ponyWall.PonyWallPositions[i][j].split(",")[2]) * 20 - average.z,
          }
          points.push(point);
          extractedPoints.push(new THREE.Vector3(point.x, point.y, point.z)); // Step 2: Push into extractedPoints
        }
        let face;
        face = null;
        for(let j = 0; j < ponyWall.PonyWallTriangleIndices[i].length; j+=3){
          let point1 = points[ponyWall.PonyWallTriangleIndices[i][j]];
          let point2 = points[ponyWall.PonyWallTriangleIndices[i][j+1]];
          let point3 = points[ponyWall.PonyWallTriangleIndices[i][j+2]];
          let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
          let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
          let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
          const vertices = new Float32Array([
            pp1.x, pp1.y, pp1.z,
            pp2.x, pp2.y, pp2.z,
            pp3.x, pp3.y, pp3.z,
          ]);
          const geometry = new THREE.BufferGeometry();
          geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
          if(face == null){
            face = geometry;
          }
          else{
            face = mergeBufferGeometries([face, geometry]);
          }
        }
        if(ponyWallMesh == null){
          ponyWallMesh = face;
        }
        else{
          ponyWallMesh = mergeBufferGeometries([ponyWallMesh, face]);
        }
    }
    const material = new THREE.MeshStandardMaterial({ color: 0xd8eced, flatShading: true, side: THREE.DoubleSide });
    const edges = new THREE.EdgesGeometry( ponyWallMesh );
    const mesh = new THREE.Mesh(ponyWallMesh, material);
    mesh.layers.set(10);
    mesh.forRayTracing = true;
    mesh.userData = {
      GUID: ponyWall.GUID,
      idNumber: ponyWall.$id,
      Description: "PonyWall",
      toScreen: "PonyWall",
      level: ponyWall.LevelName,
      color: 0xd8eced,
      canChangeColor: false,
      normalColor: 0xd8eced,
      edges: edges,
      points: extractedPoints,
      tapeMeasure: true,
    }
    mesh.displayWindowInfo = {
      Type: "PonyWall",
      // ID: ponyWall.$id,
      Level: ponyWall.LevelName,
    }
    if(objectOpacity.transparent){
      mesh.material.transparent = true;
      mesh.material.opacity = objectOpacity.opacity;
    }
    group.add(mesh);
}
export {generatePonyWall};
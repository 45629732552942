

import * as THREE from 'three';
/* eslint-disable  no-unused-vars */
function generateHoles(member, degrees, px1, y2, pz1, px2, pz2, i, average){
    let oneHole;
    let holeExtruded;
    const holeMaterial = new THREE.MeshPhongMaterial({color: 0x000000, flatShading: true});
    const position = member.WebHoles[i].Location;
    const y = y2 + member.Depth / 2; 
    const vec = new THREE.Vector3(px2 - px1, 0, pz2 - pz1);
    vec.normalize();
    let newX = px1 + vec.x * position;
    let newZ = pz1 + vec.z * position;
    let perp = new THREE.Vector3(vec.z, 0, -vec.x);
    if(degrees === 0 || degrees === 180){
      perp = new THREE.Vector3(-vec.z, 0, vec.x);
    }
    if(degrees === 90 || degrees === 270){
      perp = new THREE.Vector3(vec.z, 0, -vec.x);
    }
    if(degrees > 0 && degrees < 46 || degrees < 0 && degrees > -90){
      perp = new THREE.Vector3(-vec.z, 0, vec.x);
    }
    newX -= perp.x * ((member.Width / 1.5));
    newZ -= perp.z * ((member.Width / 1.5));
    if(member.WebHoles[i].IsObround){
      const hole = new THREE.Shape();
      const width = member.WebHoles[i].Width;
      const height = member.WebHoles[i].Height;
      const radius = height / 2;
      const newWidth = width - height;
      hole.moveTo(0, 0 - newWidth / 2);
      hole.absarc(0, 0 - newWidth / 2, radius, Math.PI, 0, false);
      hole.lineTo(0 + height / 2, 0 + newWidth / 2);
      hole.absarc(0,  0 + newWidth / 2, radius, 0, Math.PI, false);
      hole.lineTo(0 - height / 2, 0 - newWidth / 2);
      let extrudeSettings = {
        steps: 1,
        depth: member.Width * 1.5,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), Math.PI / 2);
    // For when the hole is Circular
    }
    else if(member.WebHoles[i].IsRound){
      const hole = new THREE.Shape();
      const radius = member.WebHoles[i].Diameter / 2;
      hole.absarc(0, 0, radius, 0, Math.PI * 2, false);
      let extrudeSettings = {
        steps: 1,
        depth: member.Width * 1.5,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), 0);
  
    }
    // // For when the hole is Rectangular
    else{
      const hole = new THREE.Shape();
      const width = member.WebHoles[i].Width;
      const height = member.WebHoles[i].Height;
      hole.moveTo(0 + height / 2, 0 + width / 2);
      hole.lineTo(0 - height / 2 , 0 + width / 2);
      hole.lineTo(0 - height / 2 , 0 - width / 2);
      hole.lineTo(0 + height / 2 , 0 - width / 2);
      hole.lineTo(0 + height / 2, 0 + width / 2 );
      let extrudeSettings = {
        steps: 1,
        depth: member.Width * 1.5,
        bevelEnabled: false,
      }
      holeExtruded = new THREE.ExtrudeGeometry(hole, extrudeSettings);
      oneHole = new THREE.Mesh( holeExtruded, holeMaterial );
      // oneHole.position.set(newX + fixPosition * newWebX * constant + additionalX - averageX, y + member.Depth / 2, newZ - fixPosition * newWebZ * constant + additionalZ - averageZ);
      oneHole.position.set(newX - average.x, y + member.Depth / 2, newZ - average.z);
      oneHole.rotation.y = Math.PI / 2;
      oneHole.rotation.x = Math.PI / 2;
      oneHole.rotation.set(0,-(degrees * Math.PI / 180), Math.PI / 2);
    }
    return oneHole;
}
function checkIntersection(raycaster, mouse, camera, group) {

    raycaster.setFromCamera( mouse, camera );
  
    let intersects = raycaster.intersectObject( group );
    for(let i = 0; i < intersects.length; i++){
      if( intersects[i].object.material.clippingPlanes && intersects[i].object.material.clippingPlanes.length > 0){
        intersects = intersects.filter(function(elem){
          return intersects[i].object.material.clippingPlanes.every(function(elem2){
            elem2.constant = parseInt(elem2.constant);
            return elem2.distanceToPoint(elem.point) > 0;
          });
        });
      }
    }
    if ( intersects.length > 0) {
      for(let i = 0; i < intersects.length; i++){
        if(intersects[i].object.forRayTracing && intersects[i].object.material.visible){
          return intersects[ i ].object;
        }
      }
    }
  
}
function generateGroudFloor(floorOutline, average, group,){
  let floor = new THREE.Shape();
  let yCoordinate = floorOutline.Elevation;
  let start1X1 = parseFloat(floorOutline.Points[0].split(",")[0]) - average.x;
  let start1Z1 = parseFloat(floorOutline.Points[0].split(",")[1]) - average.z;
  floor.moveTo(start1X1, start1Z1);
  for(let i = 1; i < floorOutline.Points.length; i++){
    const x = parseFloat(floorOutline.Points[i].split(",")[0]) - average.x;
    const z = parseFloat(floorOutline.Points[i].split(",")[1]) - average.z;
    floor.lineTo(x, z);
  }
  const geometry = new THREE.ShapeGeometry(floor);
  const material = new THREE.MeshStandardMaterial({color: 0x808076, side: THREE.DoubleSide});
  const mesh = new THREE.Mesh(geometry, material);
  mesh.rotateX(Math.PI / 2);
  mesh.position.set(0, yCoordinate - 0.5, 0);
  mesh.layers.set(11);
  mesh.userData = {
    Description: "Decking",
    level: floorOutline.Level,
    GUID: floorOutline.UndoGUID,
  }
  group.add(mesh);
  // if(deckingAfter){
  //   scene.remove(groundPlane);
  // }
}

export { checkIntersection, generateHoles, generateGroudFloor };
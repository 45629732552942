import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateEndDevices(positions,  indices, average , group, member, type, name){
    let points = [];
    for(let j = 0; j < positions.length; j++){
        let point = {}
        point = {
            x: parseFloat(positions[j].split(",")[0]) * 20 - average.x,
            y: parseFloat(positions[j].split(",")[1]) * 20,
            z: parseFloat(positions[j].split(",")[2]) * 20 - average.z
        }
        points.push(point);
    }
    let face = null;
    for(let j = 0; j < indices.length; j+=3){
        let point1 = points[indices[j]];
        let point2 = points[indices[j+1]];
        let point3 = points[indices[j+2]];
        let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
        let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
        let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
        const vertices = new Float32Array( [
            pp1.x, pp1.y, pp1.z,
            pp2.x, pp2.y, pp2.z,
            pp3.x, pp3.y, pp3.z
        ] );
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
        if(face == null){
            face = geometry;
        }else{
            face = mergeBufferGeometries([face, geometry]);
        }
    }
    const material = new THREE.MeshStandardMaterial( { color: 0xfafafa, flatShading: true, side: THREE.DoubleSide  } );
    const mesh = new THREE.Mesh( face, material );
    mesh.layers.set( type );
    mesh.forRayTracing = true;
    mesh.userData = {Description: "iJoist",level: member.LevelName, idNumber: member.$id, GUID: member.GUID};
    mesh.displayWindowInfo = {
        Type: name,
        Level: member.LevelName,
    }
    group.add( mesh );
}
export { generateEndDevices };
function checkSaveSettings(settings, currentTransparencyOutside, items){
    if(settings){
        currentTransparencyOutside = settings.Opacity;
        items['backerBlocks'].visibility = settings.BackerBlocksVisibility;
        items['backerBlocks'].opacity = settings.BackerBlocksOpacity;
        items['BeamByOthers'].visibility = settings.BeamByOthersVisibility;
        items['BeamByOthers'].opacity = settings.BeamByOthersOpacity;
        items['Blocking'].visibility = settings.BlockingVisibility;
        items['Blocking'].opacity = settings.BlockingOpacity;
        items['Column'].visibility = settings.ColumnVisibility;
        items['Column'].opacity = settings.ColumnOpacity;
        items['Decking'].visibility = settings.DeckingVisibility;
        items['Decking'].opacity = settings.DeckingOpacity;
        items['Glulam'].visibility = settings.GlulamVisibility;
        items['Glulam'].opacity = settings.GlulamOpacity;
        items['GroundLayer'].visibility = settings.GroundLayerVisibility;
        items['GroundLayer'].opacity = settings.GroundLayerOpacity;
        items['iJoist'].visibility = settings.iJoistVisibility;
        items['iJoist'].opacity = settings.iJoistOpacity;
        items['JoistClips'].visibility = settings.JoistClipsVisibility;
        items['JoistClips'].opacity = settings.joistClipsOpacity;
        items['Lumber'].visibility = settings.LumberVisibility;
        items['Lumber'].opacity = settings.LumberOpacity;
        items['LVL'].visibility = settings.LVLVisibility;
        items['LVL'].opacity = settings.LVLOpacity;
        items['noggin'].visibility = settings.NogginVisibility;
        items['noggin'].opacity = settings.NogginOpacity;
        items['Obstruction'].visibility = settings.ObstructionVisibility;
        items['Obstruction'].opacity = settings.ObstructionOpacity;
        items['OpenJoist'].visibility = settings.OpenJoistVisibility;
        items['OpenJoist'].opacity = settings.OpenJoistOpacity;
        items['Plate'].visibility = settings.PlateVisibility;
        items['Plate'].opacity = settings.PlateOpacity;
        items['PonyWall'].visibility = settings.PonyWallVisibility;
        items['PonyWall'].opacity = settings.PonyWallOpacity;
        items['Rimboard'].visibility = settings.RimboardVisibility;
        items['Rimboard'].opacity = settings.RimboardOpacity;
        items['Roof'].visibility = settings.RoofVisibility;
        items['Roof'].opacity = settings.RoofOpacity;
        items['SquashBlockLine'].visibility = settings.SquashBlockVisibility;
        items['SquashBlockLine'].opacity = settings.SquashBlockOpacity;
        items['Steel'].visibility = settings.SteelVisibility;
        items['Steel'].opacity = settings.SteelOpacity;
        items['Strongback'].visibility = settings.StrongbackVisibility;
        items['Strongback'].opacity = settings.StrongbackOpacity;
        items['Stud'].visibility = settings.StudVisibility;
        items['Stud'].opacity = settings.StudOpacity;
        items['Truss'].visibility = settings.TrussVisibility;
        items['Truss'].opacity = settings.TrussOpacity;
        items['Wall'].visibility = settings.WallVisibility;
        items['Wall'].opacity = settings.WallOpacity;
    }
}
export { checkSaveSettings };
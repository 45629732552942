/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
import { generateIJoist } from './helperFiles/iJoist.js';
import { generateGatorJoist } from './helperFiles/gatorJoist.js';
import { generateZClip } from './helperFiles/zclip.js';
import {generateHoles} from './helperFiles/holes.js';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
import { boundingBox } from './helperFiles/boundingBoxCheck.js';
import { generateReinforcement } from './helperFiles/reinforcement.js';
function generateMember(member, average, group, version, isMetric, hangerNames, counts, bbox, objectOpacity){
    const rb1 = new THREE.Vector3(parseFloat(member._rightBottomP1.split(",")[0]), parseFloat(member._rightBottomP1.split(",")[2]), parseFloat(member._rightBottomP1.split(",")[1]));
    const rb2 = new THREE.Vector3(parseFloat(member._rightBottomP2.split(",")[0]), parseFloat(member._rightBottomP2.split(",")[2]), parseFloat(member._rightBottomP2.split(",")[1]));
    const rt1 = new THREE.Vector3(parseFloat(member._rightTopP1.split(",")[0]), parseFloat(member._rightTopP1.split(",")[2]), parseFloat(member._rightTopP1.split(",")[1]));
    const rt2 = new THREE.Vector3(parseFloat(member._rightTopP2.split(",")[0]), parseFloat(member._rightTopP2.split(",")[2]), parseFloat(member._rightTopP2.split(",")[1]));
    const lb1 = new THREE.Vector3(parseFloat(member._leftBottomP1.split(",")[0]), parseFloat(member._leftBottomP1.split(",")[2]), parseFloat(member._leftBottomP1.split(",")[1]));
    const lb2 = new THREE.Vector3(parseFloat(member._leftBottomP2.split(",")[0]), parseFloat(member._leftBottomP2.split(",")[2]), parseFloat(member._leftBottomP2.split(",")[1]));
    const lt1 = new THREE.Vector3(parseFloat(member._leftTopP1.split(",")[0]), parseFloat(member._leftTopP1.split(",")[2]), parseFloat(member._leftTopP1.split(",")[1]));
    const lt2 = new THREE.Vector3(parseFloat(member._leftTopP2.split(",")[0]), parseFloat(member._leftTopP2.split(",")[2]), parseFloat(member._leftTopP2.split(",")[1]));
    const endPoints1 = [
        rb1,
        rt1,
        lt1,
        lb1,
    ]
    const endPoints2 = [
        rb2,
        rt2,
        lt2,
        lb2,
    ]
    const points = [rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2]
    const middlePoints = [
        new THREE.Vector3((rb1.x + lb1.x) / 2, (rb1.y + lb1.y) / 2, (rb1.z + lb1.z) / 2),
        new THREE.Vector3((rt1.x + lt1.x) / 2, (rt1.y + lt1.y) / 2, (rt1.z + lt1.z) / 2),
        new THREE.Vector3((rt2.x + lt2.x) / 2, (rt2.y + lt2.y) / 2, (rt2.z + lt2.z) / 2),
        new THREE.Vector3((rb2.x + lb2.x) / 2, (rb2.y + lb2.y) / 2, (rb2.z + lb2.z) / 2),

      ]
    let xyz = getMinMaxPoints(rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2);
    if(bbox){
        boundingBox(rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2, bbox);
    }
    updateCounts(xyz,counts);
    counts.memberCount.count++;
    const lineUp = 0.05;
    let distance = [
        rb1.distanceTo(rb2),
        rt1.distanceTo(rt2),
        lb1.distanceTo(lb2),
        lt1.distanceTo(lt2),
    ];

    let maxDistance = Math.max(...distance);

    const y1 = parseFloat(member.Line.P1.split(",")[2]);
    const y2 = (y1 + member.Depth/2) - member.PlumbDepth;
    const lx1 = parseFloat(member.Line.P1.split(",")[0]);
    const lz1 = parseFloat(member.Line.P1.split(",")[1]);
    const ly1 = parseFloat(member.Line.P1.split(",")[2]);
    const lx2 = parseFloat(member.Line.P2.split(",")[0]);
    const lz2 = parseFloat(member.Line.P2.split(",")[1]);
    const ly2 = parseFloat(member.Line.P2.split(",")[2]);
    const vec = new THREE.Vector3(lx2 - lx1, ly2 - ly1, lz2 - lz1);
    vec.normalize();
    const degrees = Math.atan2(vec.z, vec.x) * 180 / Math.PI;
    var angleRotation = vec.angleTo(new THREE.Vector3(0, -1, 0));
    if(ly1 === ly2){
        if(degrees === 90 || degrees === -90 || degrees === 0 || degrees === 180){
        angleRotation = Math.atan2(vec.z, vec.x);
        }
        else if (degrees < 0){
        angleRotation = Math.PI;
        }
    }
    else{
    if(lx1 === lx2){
        angleRotation = vec.angleTo(new THREE.Vector3(0, 1, 0));
    }
    else if(lz1 === lz2){
        angleRotation = vec.angleTo(new THREE.Vector3(1, 0, 0));
    }
    }
    let compBoard;
    let board;
    let color, colorWrong;
    let description;
    let layerNumber;
    let memberColor;
    if(member.ChosenColor == null){
        colorWrong = member.BasicColor;
    }
    else if (member.ChosenColor){
        colorWrong = member.ChosenColor;
    }
    if(colorWrong){
        memberColor = parseInt(colorWrong.replace("#", "0x"));
    }
    else{
        memberColor = 0xecd5a7;
    }
    if(member.IsPlate){
        description = "Plate";
        layerNumber = 23;
    }
    else{
        switch(member.BeamType){
            case "LVL":
                description = "LVL";
                layerNumber = 5;
                break;
            case "Rimboard":
                description = "Rimboard";
                layerNumber = 3;
                break;
            case "BeamByOthers":
                description = "BeamByOthers";
                layerNumber = 6;
                break;
            case "Lumber":
                description = "Lumber";
                layerNumber = 7;
                break;
            case "Steel":
                description = "Steel";
                layerNumber = 16;
                break;
            case "Glulam":
                description = "Glulam";
                layerNumber = 21;
                break;
            default:
                description = "Find The Beam Type";
                break;
        }
    }
    color = 0xecd5a7;
    if(member.BeamType === "IJoist"){
        generateIJoist(member, average, group, version, isMetric, hangerNames, objectOpacity);
        if(member.WebHoles.length > 0){
            for(let i = 0; i < member.WebHoles.length; i++){
                let {oneHole, line, line2} = generateHoles(member, degrees, lx1, y2, lz1, lx2, lz2, i, average, member.WebThickness, memberColor);
                oneHole.forRayTracing = true;
                line.layers.set(2);
                line2.layers.set(2);
                oneHole.layers.set(2);
                oneHole.userData = {
                    GUID: member.GUID,
                    idNumber: member.$id,
                    Description: "WebHole",
                    toScreen: description,
                    level: member.LevelName,
                    webLine1: line,
                    webLine2: line2,
                }
                let type = null;
                if(member.WebHoles[i].IsObround){
                    type = "Obround";
                }
                else if(member.WebHoles[i].IsRound){
                    type = "Round";
                }
                else {
                    type = "Rectangle";
                }
                oneHole.displayWindowInfo = {
                    Type: type,
                    Level: member.LevelName,
                };
                if(member.PlacementLabel){
                    oneHole.displayWindowInfo.Label = member.PlacementLabel;
                }
                if (member.WebHoles[i].Width !== 0.0) {
                    oneHole.displayWindowInfo.Width = isMetric ? (member.WebHoles[i].Width * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Width.toFixed(2).replace(/\.?0+$/, '') + " in";
                }

                if (member.WebHoles[i].Height !== 0.0) {
                    oneHole.displayWindowInfo.Height = isMetric ? (member.WebHoles[i].Height * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Height.toFixed(2).replace(/\.?0+$/, '') + " in";
                }

                if (member.WebHoles[i].Diameter !== 0.0) {
                    oneHole.displayWindowInfo.Diameter = isMetric ? (member.WebHoles[i].Diameter * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Diameter.toFixed(2).replace(/\.?0+$/, '') + " in";
                }
                oneHole.displayWindowInfo.Distance = isMetric ? (member.WebHoles[i].Location * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Location.toFixed(2).replace(/\.?0+$/, '') + " in";
                group.add(oneHole);
            }
        }
    }
    else if(member.BeamType === "OpenJoist" || member.BeamType === "GatorJoist" || member.BeamType === "OJ2000" || member.BeamType === "TrimJoist" || member.BeamType === "TrimJoistDFL"){
        generateGatorJoist(member, average, group, version, isMetric, hangerNames, objectOpacity);
    }
    else{
        for(let i = 0; i < member.AllMemberMeshPositions.length; i++){
            let points = [];
            for (let j = 0; j < member.AllMemberMeshPositions[i].length; j++) {
              let point = {};
              point = {
                x: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[0] * 20) - average.x,
                y: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[1] * 20),
                z: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[2] * 20) - average.z,
              }
              points.push(point);
            }
            let face;
            face = null;
            for(let j = 0; j < member.AllMemberMeshTriangleIndices[i].length; j+=3){
              let point1 = points[member.AllMemberMeshTriangleIndices[i][j]];
              let point2 = points[member.AllMemberMeshTriangleIndices[i][j+1]];
              let point3 = points[member.AllMemberMeshTriangleIndices[i][j+2]];
              let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
              let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
              let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
              const vertices = new Float32Array([
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z,
              ]);
              const geometry = new THREE.BufferGeometry();
              geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
              if(face == null){
                face = geometry;
              }
              else{
                if(geometry)
                    face = mergeBufferGeometries([face, geometry]);
              }
            }
            if(board == null){
              board = face;
            }
            else{
                if(face)
                    board = mergeBufferGeometries([board, face]);
            }
          }
        // board.computeVertexNormals();
        const vec = new THREE.Vector3(lx2 - lx1, 0, lz2 - lz1);
        vec.normalize();
        const perp = new THREE.Vector3(-vec.z, 0, vec.x);
        const slope = (lx2 - lx1) / (lz2 - lz1);
        var material;
        material = new THREE.MeshStandardMaterial({
            color: memberColor,
            flatShading: true,
            side: THREE.DoubleSide,
        });
        let mesh = new THREE.Mesh(board, material);
        const edges = new THREE.EdgesGeometry(board);
        mesh.updateMatrix();
        mesh.layers.set(layerNumber);
        mesh.allData = member;
        if(member.PlyCount > 1) {
            let line;
            let reps;
            let scalar = [];
            if(member.PlyCount == 2) {
                reps = 1;
                scalar = [1/2];
            }
            else if(member.PlyCount == 3) {
                reps = 2;
                scalar = [1/3, 2/3];
            }
            else if(member.PlyCount == 4) {
                reps = 3;
                scalar = [1/4, 2/4, 3/4];
            }
            for(let i = 0; i < reps; i++) {
                let points = [];
                //find the point a third of the way between rb1 and lb1 and use that as the center of the line
                let vector1 = new THREE.Vector3().subVectors(rb1, lb1);
                vector1.multiplyScalar(scalar[i]);
                vector1.add(lb1);
                vector1.x -= lineUp * vec.x;
                vector1.z -= lineUp * vec.z;
                vector1.y -= lineUp * 2;
                points.push(vector1);
                let vector2 = new THREE.Vector3().subVectors(rb2, lb2);
                vector2.multiplyScalar(scalar[i]);
                vector2.add(lb2);
                vector2.x += lineUp * vec.x;
                vector2.z += lineUp * vec.z;
                vector2.y -= lineUp * 2;
                points.push(vector2);
                let vector3 = new THREE.Vector3().subVectors(rt2, lt2);
                vector3.multiplyScalar(scalar[i]);
                vector3.add(lt2);
                vector3.x += lineUp * vec.x;
                vector3.z += lineUp * vec.z;
                vector3.y += lineUp * 2;
                points.push(vector3);
                let vector4 = new THREE.Vector3().subVectors(rt1, lt1);
                vector4.multiplyScalar(scalar[i]);
                vector4.add(lt1);
                vector4.x -= lineUp * vec.x;
                vector4.z -= lineUp * vec.z;
                vector4.y += lineUp * 2;
                points.push(vector4);
                points.push(vector1);
                const lineMaterial = new THREE.LineDashedMaterial({
                    color: 0x000000,
                    dashSize: 0.1,
                    gapSize: 0.1,
                    linewidth: 0.1,
                    transparent: true,
                    opacity: 0.8,
                });
                line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), lineMaterial);
                line.position.set(-average.x, 0, -average.z);
                line.layers.set(layerNumber);
                line.forRayTracing = false;
                line.userData = {
                    GUID: member.GUID,
                    idNumber: member.$id,
                    Description: description,
                    toScreen: description,
                    level: member.LevelName,
                    supplier: member.Supplier,
                    material: member.MaterialName,
                    width: member.Width,
                    depth: member.Depth,
                    plyLine: true,
                }
                group.add(line);
            }
        }
        if(member.WebHoles.length > 0){
            for(let i = 0; i < member.WebHoles.length; i++){
                let {oneHole, line, line2} = generateHoles(member, degrees, lx1, y2, lz1, lx2, lz2, i, average, member.Width, memberColor);
                oneHole.forRayTracing = true;
                oneHole.layers.set(layerNumber);
                line.layers.set(layerNumber);
                line2.layers.set(layerNumber);
                oneHole.userData = {
                    GUID: member.GUID,
                    idNumber: member.$id,
                    Description: "WebHole",
                    toScreen: description,
                    level: member.LevelName,
                    webLine1: line,
                    webLine2: line2,
                }
                let type = null;
                if(member.WebHoles[i].IsObround){
                    type = "Obround";
                }
                else if(member.WebHoles[i].IsRound){
                    type = "Round";
                }
                else {
                    type = "Rectangle";
                }
                oneHole.displayWindowInfo = {
                    Type: type,
                    Level: member.LevelName,
                };
                if(member.PlacementLabel){
                    oneHole.displayWindowInfo.Label = member.PlacementLabel;
                }
                if (member.WebHoles[i].Width !== 0.0) {
                    oneHole.displayWindowInfo.Width = isMetric ? (member.WebHoles[i].Width * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Width.toFixed(2).replace(/\.?0+$/, '') + " in";
                }

                if (member.WebHoles[i].Height !== 0.0) {
                    oneHole.displayWindowInfo.Height = isMetric ? (member.WebHoles[i].Height * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Height.toFixed(2).replace(/\.?0+$/, '') + " in";
                }

                if (member.WebHoles[i].Diameter !== 0.0) {
                    oneHole.displayWindowInfo.Diameter = isMetric ? (member.WebHoles[i].Diameter * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Diameter.toFixed(2).replace(/\.?0+$/, '') + " in";
                }
                oneHole.displayWindowInfo.Distance = isMetric ? (member.WebHoles[i].Location * 25.4).toFixed(2).replace(/\.?0+$/, '') + " mm" : member.WebHoles[i].Location.toFixed(2).replace(/\.?0+$/, '') + " in";
                group.add(oneHole);
            }
        }
        if(member.HangerModel1){
            let hangerType = member.HangerModel1.split(' ')[0];
            hangerType = hangerType.replace('(Min)', '');
            hangerType = hangerType.replace('/Min', '');
            hangerType = hangerType.replace('Min', '');
            hangerType = hangerType.replace('(Max)', '');
            hangerType = hangerType.replace('/Max', '');
            hangerType = hangerType.replace('Max', '');
            hangerType = hangerType.trim();
            hangerType = hangerType.replace('R/L', 'L');
            hangerType = hangerType.replace('LZ_RZ', 'LZ');
            hangerType = hangerType.replace('/', '_');
            hangerType += ".stl";
            hangerNames.add(hangerType);
        }
        if(member.HangerModel2 ){
            let hangerType = member.HangerModel2.split(' ')[0];
            hangerType = hangerType.replace('(Min)', '');
            hangerType = hangerType.replace('/Min', '');
            hangerType = hangerType.replace('Min', '');
            hangerType = hangerType.replace('(Max)', '');
            hangerType = hangerType.replace('/Max', '');
            hangerType = hangerType.replace('Max', '');
            hangerType = hangerType.trim();
            hangerType = hangerType.replace('R/L', 'R');
            hangerType = hangerType.replace('LZ_RZ', 'RZ');
            hangerType = hangerType.replace('/', '_');
            hangerType += ".stl";
            hangerNames.add(hangerType);
        }
        if(member.HangerLeftSide){
            mesh.hangerInfoLeft = {
                member: member,
                vec: vec,
                px1: lx1,
                py1: ly1,
                pz1: lz1,
                type: description,
                layerNumber: layerNumber,
                rb1: rb1,
                lb1: lb1,
                average: average,
                group: group,
                version: version,
            }
        }
        if(member.HangerRightSide){
            mesh.hangerInfoRight = {
                member: member,
                vec: vec,
                px2: lx2,
                py2: ly2,
                pz2: lz2,
                type: description,
                layerNumber: layerNumber,
                rb2: rb2,
                lb2: lb2,
                average: average,
                group: group,
                version: version,
            }
        }
        mesh.userData = {
            GUID: member.GUID,
            idNumber: member.$id,
            Description: description,
            toScreen: description,
            level: member.LevelName,
            color: memberColor,
            normalColor: color,
            canChangeColor: true,
            supplier: member.Supplier,
            material: member.MaterialName,
            width: member.Width,
            depth: member.Depth,
            plyCount: member.PlyCount,
            tapeMeasure: true,
            layer: layerNumber,
            edges: edges,
            ends: [ endPoints1, endPoints2 ],
            points: points,
            middle: middlePoints,
        }
        const width = isMetric? ((member.Width * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": member.Width.toFixed(2).replace(/\.?0*$/,'') + " in";

        const depth = isMetric? ((member.Depth * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": member.Depth.toFixed(2).replace(/\.?0*$/,'') + " in";

        const elevationInInches = ly1.toFixed(2).replace(/\.?0*$/,'') + " in";
        const elevationInMillimeters = ((ly1 * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm";
        const elevation = isMetric ? elevationInMillimeters : elevationInInches;
        const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
        const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
        let placementLabel = "n/a";
        let materialID = "n/a";
        let supplier = "n/a";
        if(member.Supplier){
            supplier = member.Supplier;
        }
        if(member.MaterialName){
            materialID = member.MaterialName;
        }
        if(member.PlacementLabel){
            placementLabel = member.PlacementLabel;
        }
        mesh.displayWindowInfo = {
            Type: description,
            Level: member.LevelName,
            Supplier: supplier,
            Material: materialID,
            placementLabel: placementLabel,
            PlyCount: member.PlyCount,
            Length: length,
            Width: width,
            Depth: depth,
            Elevation: elevation,
        };
        // if(texture){
        //     mesh.userData.texture = texture;
        // }
        mesh.forRayTracing = true;
        if(objectOpacity.transparent){
            mesh.material.transparent = true;
            mesh.material.opacity = objectOpacity.opacity;
        }
        group.add(mesh);
        if(member.ZClip1Positions.length){
            if(member.ZClip1Positions[0].length){
              let mesh1 = generateZClip(member.ZClip1Positions[0],member.ZClip1TriangleIndices[0],average)
              let mesh2 = generateZClip(member.ZClip2Positions[0],member.ZClip2TriangleIndices[0],average)
              mesh1.updateMatrix();
              mesh2.updateMatrix();

              var geo1 = mesh1.geometry.clone().applyMatrix4(mesh1.matrix);
              var geo2 = mesh2.geometry.clone().applyMatrix4(mesh2.matrix);
              var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
              const material = new THREE.MeshBasicMaterial({color: 0x808080});
              const mesh = new THREE.Mesh( mergedGeometry, material );
              mesh.forRayTracing = true;
              mesh.layers.set( layerNumber );
              mesh.userData = {
                type: description,
                Description: "zClip",
                level: member.LevelName,
                GUID: member.GUID,
                idNumber: member.$id,
              };
              mesh.displayWindowInfo = {
                Type: "zClip",
                Level: member.LevelName,
              }
              group.add( mesh );

            }
        }
        if(member.ReinforcementPositions.length){
            if(member.ReinforcementPositions[0].length){
                generateReinforcement(member.ReinforcementPositions,member.ReinforcementTriangleIndices,average, group, member, layerNumber)
            }
        }
        // return mesh;
    }
}
function getMinMaxPoints(rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2){
    let min = rb1;
    let max = rt1;
    const bottomPoints = [rb1, rb2, lb1, lb2];
    const topPoints = [rt1, rt2, lt1, lt2];
    for (let i = 0; i < bottomPoints.length; i++) {
        if (bottomPoints[i].y < min.y) {
            min = bottomPoints[i];
        }
    }
    for (let i = 0; i < topPoints.length; i++) {
        if (topPoints[i].y > max.y) {
            max = topPoints[i];
        }
    }
    return [min, max];
}
function updateCounts(xyz, counts){
    // modify Y so that it only has 2 decimal places
    xyz[0].y = parseFloat(xyz[0].y.toFixed(2));
    xyz[1].y = parseFloat(xyz[1].y.toFixed(2));

    // Update minYCounts
    let minCounts = counts.minCounts[xyz[0].y];
    if (minCounts) {
        minCounts.count++;
    } else {
        counts.minCounts[xyz[0].y] = {
            minX: xyz[0].x,
            maxX: xyz[0].x,
            minZ: xyz[0].z,
            maxZ: xyz[0].z,
            y: xyz[0].y,
            count: 1
        };
        minCounts = counts.minCounts[xyz[0].y];
    }
    // Update bounding box for minY
    if (xyz[0].x < minCounts.minX) minCounts.minX = xyz[0].x;
    if (xyz[0].x > minCounts.maxX) minCounts.maxX = xyz[0].x;
    if (xyz[0].z < minCounts.minZ) minCounts.minZ = xyz[0].z;
    if (xyz[0].z > minCounts.maxZ) minCounts.maxZ = xyz[0].z;

    // Update maxYCounts
    let maxCounts = counts.maxCounts[xyz[1].y];
    if (maxCounts) {
        maxCounts.count++;
    } else {
        counts.maxCounts[xyz[1].y] = {
            minX: xyz[1].x,
            maxX: xyz[1].x,
            minZ: xyz[1].z,
            maxZ: xyz[1].z,
            y: xyz[1].y,
            count: 1
        };
        maxCounts = counts.maxCounts[xyz[1].y];
    }
    // Update bounding box for maxY
    if (xyz[1].x < maxCounts.minX) maxCounts.minX = xyz[1].x;
    if (xyz[1].x > maxCounts.maxX) maxCounts.maxX = xyz[1].x;
    if (xyz[1].z < maxCounts.minZ) maxCounts.minZ = xyz[1].z;
    if (xyz[1].z > maxCounts.maxZ) maxCounts.maxZ = xyz[1].z;

    // Increment the memberCount
    counts.memberCount.count++;
}
export { generateMember };

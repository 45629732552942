function boundingBox(rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2, bbox){
    if(bbox.minX > Math.min(rb1.x, rb2.x, rt1.x, rt2.x, lb1.x, lb2.x, lt1.x, lt2.x)){
        bbox.minX = Math.min(rb1.x, rb2.x, rt1.x, rt2.x, lb1.x, lb2.x, lt1.x, lt2.x);
    }
    if(bbox.maxX < Math.max(rb1.x, rb2.x, rt1.x, rt2.x, lb1.x, lb2.x, lt1.x, lt2.x)){
        bbox.maxX = Math.max(rb1.x, rb2.x, rt1.x, rt2.x, lb1.x, lb2.x, lt1.x, lt2.x);
    }
    if(bbox.minY > Math.min(rb1.y, rb2.y, rt1.y, rt2.y, lb1.y, lb2.y, lt1.y, lt2.y)){
        bbox.minY = Math.min(rb1.y, rb2.y, rt1.y, rt2.y, lb1.y, lb2.y, lt1.y, lt2.y);
    }
    if(bbox.maxY < Math.max(rb1.y, rb2.y, rt1.y, rt2.y, lb1.y, lb2.y, lt1.y, lt2.y)){
        bbox.maxY = Math.max(rb1.y, rb2.y, rt1.y, rt2.y, lb1.y, lb2.y, lt1.y, lt2.y);
    }
    if(bbox.minZ > Math.min(rb1.z, rb2.z, rt1.z, rt2.z, lb1.z, lb2.z, lt1.z, lt2.z)){
        bbox.minZ = Math.min(rb1.z, rb2.z, rt1.z, rt2.z, lb1.z, lb2.z, lt1.z, lt2.z);
    }
    if(bbox.maxZ < Math.max(rb1.z, rb2.z, rt1.z, rt2.z, lb1.z, lb2.z, lt1.z, lt2.z)){
        bbox.maxZ = Math.max(rb1.z, rb2.z, rt1.z, rt2.z, lb1.z, lb2.z, lt1.z, lt2.z);
    }
}
export {boundingBox};
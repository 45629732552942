/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
import { generateZClip } from './zclip.js';
import { generateBackerBlock } from './backerBlock.js';
import { generateJoistClip } from './joistClip.js';
import { generateReinforcement } from './reinforcement.js';
import { generateEndDevices } from './endDevices.js';

function generateIJoist(member, average, group, version, isMetric, hangerNames, objectOpacity){
  if(member.IJoistMeshPositions){
    const lineUp = 0.05;
    const rb1 = new THREE.Vector3(parseFloat(member._rightBottomP1.split(",")[0]), parseFloat(member._rightBottomP1.split(",")[2]), parseFloat(member._rightBottomP1.split(",")[1]));
    const rb2 = new THREE.Vector3(parseFloat(member._rightBottomP2.split(",")[0]), parseFloat(member._rightBottomP2.split(",")[2]), parseFloat(member._rightBottomP2.split(",")[1]));
    const rt1 = new THREE.Vector3(parseFloat(member._rightTopP1.split(",")[0]), parseFloat(member._rightTopP1.split(",")[2]), parseFloat(member._rightTopP1.split(",")[1]));
    const rt2 = new THREE.Vector3(parseFloat(member._rightTopP2.split(",")[0]), parseFloat(member._rightTopP2.split(",")[2]), parseFloat(member._rightTopP2.split(",")[1]));
    const lb1 = new THREE.Vector3(parseFloat(member._leftBottomP1.split(",")[0]), parseFloat(member._leftBottomP1.split(",")[2]), parseFloat(member._leftBottomP1.split(",")[1]));
    const lb2 = new THREE.Vector3(parseFloat(member._leftBottomP2.split(",")[0]), parseFloat(member._leftBottomP2.split(",")[2]), parseFloat(member._leftBottomP2.split(",")[1]));
    const lt1 = new THREE.Vector3(parseFloat(member._leftTopP1.split(",")[0]), parseFloat(member._leftTopP1.split(",")[2]), parseFloat(member._leftTopP1.split(",")[1]));
    const lt2 = new THREE.Vector3(parseFloat(member._leftTopP2.split(",")[0]), parseFloat(member._leftTopP2.split(",")[2]), parseFloat(member._leftTopP2.split(",")[1]));
    let distance = [
      rb1.distanceTo(rb2),
      rt1.distanceTo(rt2),
      lb1.distanceTo(lb2),
      lt1.distanceTo(lt2),  
    ]
    const endPoints1 = [
      rb1,
      rt1,
      lt1,
      lb1,
    ]
    const endPoints2 = [
      rb2,
      rt2,
      lt2,
      lb2,
    ]
    const points = [rb1, rb2, rt1, rt2, lb1, lb2, lt1, lt2]
    const middlePoints = [
      new THREE.Vector3((rb1.x + lb1.x) / 2, (rb1.y + lb1.y) / 2, (rb1.z + lb1.z) / 2),
      new THREE.Vector3((rt1.x + lt1.x) / 2, (rt1.y + lt1.y) / 2, (rt1.z + lt1.z) / 2),
      new THREE.Vector3((rt2.x + lt2.x) / 2, (rt2.y + lt2.y) / 2, (rt2.z + lt2.z) / 2),
      new THREE.Vector3((rb2.x + lb2.x) / 2, (rb2.y + lb2.y) / 2, (rb2.z + lb2.z) / 2),

    ]
    let maxDistance = Math.max(...distance);
    const px1 = parseFloat(member.Line.P1.split(",")[0]);
    const pz1 = parseFloat(member.Line.P1.split(",")[1]);
    const px2 = parseFloat(member.Line.P2.split(",")[0]);
    const pz2 = parseFloat(member.Line.P2.split(",")[1]);
    const py1 = parseFloat(member.Line.P1.split(",")[2]);
    const py2 = parseFloat(member.Line.P2.split(",")[2]);
    const vec = new THREE.Vector3(px2 - px1, 0, pz2 - pz1);
    let iJoist = null;
    vec.normalize();
    const perp = new THREE.Vector3(-vec.z, 0, vec.x);
    if(Math.abs(perp.x) === 0){
      perp.x =0;
    }
    if(Math.abs(perp.z) === 0){
      perp.z =0;
    }
    let color = 0xecd5a7;
    let colorWrong;
    let iJoistColor;
    if(member.ChosenColor == null){
      colorWrong = member.BasicColor;
    }
    else if (member.ChosenColor){
      colorWrong = member.ChosenColor;
    }
    if(colorWrong){
      iJoistColor = parseInt(colorWrong.replace("#", "0x"));
    }
    else{
      iJoistColor = 0xecd5a7; 
    }
    const multiplier = member.PlumbDepth / member.Depth;
    for(let i = 0; i < member.IJoistMeshPositions.length; i++){
      let points = [];
      for (let j = 0; j < member.IJoistMeshPositions[i].length; j++) {
        let point = {};
        point = {
          x: parseFloat(member.IJoistMeshPositions[i][j].split(",")[0]) - average.x,
          y: parseFloat(member.IJoistMeshPositions[i][j].split(",")[1]),
          z: parseFloat(member.IJoistMeshPositions[i][j].split(",")[2]) - average.z,
        }
        points.push(point);
      }
      let face;
      face = null;
      for(let j = 0; j < member.IJoistMeshTriangleIndices[i].length; j+=3){
        let point1 = points[member.IJoistMeshTriangleIndices[i][j]];
        let point2 = points[member.IJoistMeshTriangleIndices[i][j+1]];
        let point3 = points[member.IJoistMeshTriangleIndices[i][j+2]];
        let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
        let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
        let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
        const vertices = new Float32Array([
          pp1.x, pp1.y, pp1.z,
          pp2.x, pp2.y, pp2.z,
          pp3.x, pp3.y, pp3.z,
        ]);
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
        if(face == null){
          if(geometry)
            face = geometry;
        }
        else{
          face = mergeBufferGeometries([face, geometry]);
        }
      }
      if(iJoist == null){
        iJoist = face;
      }
      else{
        if(face)
          iJoist = mergeBufferGeometries([iJoist, face]);
      }
    }
    const material = new THREE.MeshStandardMaterial({
      color: iJoistColor,
      flatShading: true,
      side: THREE.DoubleSide,
      // map: texture,
    });
    const vertices = new Float32Array([
      //right side of cube
      rb1.x, rb1.y, rb1.z,
      rb2.x, rb2.y, rb2.z,
      rt1.x, rt1.y, rt1.z,

      rb2.x, rb2.y, rb2.z,
      rt2.x, rt2.y, rt2.z,
      rt1.x, rt1.y, rt1.z,

      //left side of cube

      lt1.x, lt1.y, lt1.z,
      lb2.x, lb2.y, lb2.z,
      lb1.x, lb1.y, lb1.z,

      lt1.x, lt1.y, lt1.z,
      lt2.x, lt2.y, lt2.z,
      lb2.x, lb2.y, lb2.z,

      //bottom of cube
      lb1.x, lb1.y, lb1.z,
      rb2.x, rb2.y, rb2.z,
      rb1.x, rb1.y, rb1.z,

      lb1.x, lb1.y, lb1.z,
      lb2.x, lb2.y, lb2.z,
      rb2.x, rb2.y, rb2.z,

      //top of cube
      rt1.x, rt1.y, rt1.z,
      rt2.x, rt2.y, rt2.z,
      lt1.x, lt1.y, lt1.z,

      rt2.x, rt2.y, rt2.z,
      lt2.x, lt2.y, lt2.z,
      lt1.x, lt1.y, lt1.z,

      //front of cube
      rb1.x, rb1.y, rb1.z,
      rt1.x, rt1.y, rt1.z,
      lb1.x, lb1.y, lb1.z,

      rt1.x, rt1.y, rt1.z,
      lt1.x, lt1.y, lt1.z,
      lb1.x, lb1.y, lb1.z,

      //back of cube
      lb2.x, lb2.y, lb2.z,
      rt2.x, rt2.y, rt2.z,
      rb2.x, rb2.y, rb2.z,

      lb2.x, lb2.y, lb2.z,
      lt2.x, lt2.y, lt2.z,
      rt2.x, rt2.y, rt2.z,

    ]);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const edges = new THREE.EdgesGeometry(geometry);
    const mesh = new THREE.Mesh(iJoist, material)
    if(member.HangerModel1){
      let hangerType = member.HangerModel1.split(' ')[0];
      hangerType = hangerType.replace('(Min)', '');
      hangerType = hangerType.replace('/Min', '');
      hangerType = hangerType.replace('Min', '');
      hangerType = hangerType.replace('(Max)', '');
      hangerType = hangerType.replace('/Max', '');
      hangerType = hangerType.replace('Max', '');
      hangerType = hangerType.trim();
      hangerType = hangerType.replace('R/L', 'L');
      hangerType = hangerType.replace('LZ_RZ', 'LZ');
      hangerType = hangerType.replace('/', '_');
      hangerType += ".stl";
      hangerNames.add(hangerType);
    }
    if(member.HangerModel2){
        let hangerType = member.HangerModel2.split(' ')[0];
        hangerType = hangerType.replace('(Min)', '');
        hangerType = hangerType.replace('/Min', '');
        hangerType = hangerType.replace('Min', '');
        hangerType = hangerType.replace('(Max)', '');
        hangerType = hangerType.replace('/Max', '');
        hangerType = hangerType.replace('Max', '');
        hangerType = hangerType.trim();
        hangerType = hangerType.replace('R/L', 'R');
        hangerType = hangerType.replace('LZ_RZ', 'RZ');
        hangerType = hangerType.replace('/', '_');
        hangerType += ".stl";
        hangerNames.add(hangerType);
    }
    if(member.HangerLeftSide){
        mesh.hangerInfoLeft = {
          member: member,
          vec: vec,
          px1: px1,
          py1: py1,
          pz1: pz1,
          type: "iJoist",
          layerNumber: 2,
          rb1: rb1,
          lb1: lb1,
          average: average,
          group: group,
          version: version
        }
    }
    if(member.HangerRightSide){
        mesh.hangerInfoRight = {
          member: member,
          vec: vec,
          px2: px2,
          py2: py2,
          pz2: pz2,
          type: "iJoist",
          layerNumber: 2,
          rb2: rb2,
          lb2: lb2,
          average: average,
          group: group,
          version: version
        }
    }
    mesh.forRayTracing = true;
    if(member.IsNoggin){
      const width = isMetric ? ((member.Width * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm" : member.Width + " in";
      const depth = isMetric ? ((member.Depth * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm" : member.Depth + " in";
      const elevationInInches = py1.toFixed(2).replace(/\.?0*$/,'') + " in";
      const elevationInMillimeters = ((py1 * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm";
      const elevation = isMetric ? elevationInMillimeters : elevationInInches;
      const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
      const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
      mesh.layers.set(17);
      mesh.userData = {
        GUID: member.GUID,
        idNumber: member.$id,
        Description: "noggin",
        toScreen: "Noggin",
        level: member.LevelName,
        material: member.MaterialName,
        layer: 17,
        color: iJoistColor,
        normalColor: color,
      }
      mesh.displayWindowInfo = {
        Description: "Noggin",
        Level: member.LevelName,
        Width: width,
        Depth: depth,
        Elevation: elevation,
        Length: length,
      };
    }
    else{
      mesh.layers.set(2);
      mesh.userData = {
        GUID: member.GUID,
        idNumber: member.$id,
        Description: "iJoist",
        toScreen: "iJoist",
        level: member.LevelName,
        material: member.MaterialName,
        supplier: member.Supplier,
        width: member.Width,
        depth: member.Depth,
        color: iJoistColor,
        normalColor: color,
        canChangeColor: true,
        flangeThickness: member.FlangeThickness,
        webThickness: member.WebThickness,
        plyCount: member.PlyCount,
        tapeMeasure: true,
        layer: 2,
        edges: edges,
        ends: [ endPoints1, endPoints2 ],
        middle: middlePoints,
        points: points,
      }
      const width = isMetric ? ((member.Width * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm" : member.Width + " in";
      const depth = isMetric ? ((member.Depth * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm" : member.Depth + " in";
      const elevationInInches = py1.toFixed(2).replace(/\.?0*$/,'') + " in";
      const elevationInMillimeters = ((py1 * 25.4).toFixed(2)).replace(/\.?0*$/,'') + " mm";
      const elevation = isMetric ? elevationInMillimeters : elevationInInches;
      const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
      const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
      let placementLabel = 'n/a';
      let supplierID = 'n/a';
      let materialID = 'n/a';
      if(member.MaterialName){
        materialID = member.MaterialName;
      }
      if(member.Supplier){
        supplierID = member.Supplier;
      }
      if(member.PlacementLabel){
        placementLabel = member.PlacementLabel;
      }
      mesh.displayWindowInfo = {
        Type: "iJoist",
        // GUID: member.GUID,
        Level: member.LevelName,
        Supplier: member.Supplier,
        Material: member.MaterialName,
        Label: placementLabel,
        PlyCount: member.PlyCount,
        Width: width,
        Depth: depth,
        Length: length,
        Elevation: elevation,
      };
    }
    //group.add(middle);
    if(member.PlyCount > 1){
      let line;
      let scalar = [];
      let reps;
      if(member.PlyCount == 2) {
        scalar = [0.5];
        reps = 1;
      } 
      else if(member.PlyCount == 3) {
        scalar = [1/3, 2/3];
        reps = 2;
      }
      else if(member.PlyCount == 4) {
        scalar = [0.25, 0.5, 0.75];
        reps = 3;
      }
      for(let i = 0; i < reps; i++) {
        let points = [];
        let vector1 = new THREE.Vector3().subVectors(rb1, lb1);
        vector1.multiplyScalar(scalar[i]);
        vector1.add(lb1);
        vector1.x -= vec.x * lineUp;
        vector1.z -= vec.z * lineUp;
        vector1.y -= lineUp;
        points.push(vector1);
        let vector2 = new THREE.Vector3().subVectors(rb2, lb2);
        vector2.multiplyScalar(scalar[i]);
        vector2.add(lb2);
        vector2.x += vec.x * lineUp;
        vector2.z += vec.z * lineUp;
        vector2.y -= lineUp;
        points.push(vector2);
        let vector3 = new THREE.Vector3().subVectors(rb2, lb2);
        vector3.multiplyScalar(scalar[i]);
        vector3.add(lb2);
        vector3.x += vec.x * lineUp;
        vector3.z += vec.z * lineUp;
        vector3.y += (lineUp + member.FlangeThickness * multiplier);
        points.push(vector3);
        let vector4 = new THREE.Vector3().subVectors(rb1, lb1);
        vector4.multiplyScalar(scalar[i]);
        vector4.add(lb1);
        vector4.x -= vec.x * lineUp;
        vector4.z -= vec.z * lineUp;
        vector4.y += (lineUp + member.FlangeThickness * multiplier);
        points.push(vector4);
        points.push(vector1);
        let lineMaterial = new THREE.LineDashedMaterial({ 
          color: 0x000000,
          dashSize: 0.1,
          gapSize: 0.1,
          linewidth: 0.1,
          transparent: true,
          opacity: 0.8,
        });
        line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), lineMaterial);
        line.position.set(-average.x, 0, -average.z);
        line.layers.set(2);
        line.forRayTracing = false;
        line.userData = {
          GUID: member.GUID,
          idNumber: member.$id,
          Description: "iJoist",
          toScreen: "iJoist",
          level: member.LevelName,
          material: member.MaterialName,
          supplier: member.Supplier,
          width: member.Width,
          depth: member.Depth,
          flangeThickness: member.FlangeThickness,
          webThickness: member.WebThickness,
          plyLine: true,
        }
        group.add(line);
  
        points = [];
        vector1 = new THREE.Vector3().subVectors(rt1, lt1);
        vector1.multiplyScalar(scalar[i]);
        vector1.add(lt1);
        vector1.x -= vec.x * lineUp;
        vector1.z -= vec.z * lineUp;
        vector1.y += lineUp;
        points.push(vector1);
        vector2 = new THREE.Vector3().subVectors(rt2, lt2);
        vector2.multiplyScalar(scalar[i]);
        vector2.add(lt2);
        vector2.x += vec.x * lineUp;
        vector2.z += vec.z * lineUp;
        vector2.y += lineUp;
        points.push(vector2);
        vector3 = new THREE.Vector3().subVectors(rt2, lt2);
        vector3.multiplyScalar(scalar[i]);
        vector3.add(lt2);
        vector3.x += vec.x * lineUp;
        vector3.z += vec.z * lineUp;
        vector3.y -= (lineUp + member.FlangeThickness * multiplier);
        points.push(vector3);
        vector4 = new THREE.Vector3().subVectors(rt1, lt1);
        vector4.multiplyScalar(scalar[i]);
        vector4.add(lt1);
        vector4.x -= vec.x * lineUp;
        vector4.z -= vec.z * lineUp;
        vector4.y -= (lineUp + member.FlangeThickness * multiplier);
        points.push(vector4);
        points.push(vector1);
        lineMaterial = new THREE.LineDashedMaterial({ 
          color: 0x000000,
          dashSize: 0.1,
          gapSize: 0.1,
          linewidth: 0.1,
          transparent: true,
          opacity: 0.8,
        });
        line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(points), lineMaterial);
        line.position.set(-average.x, 0, -average.z);
        line.layers.set(2);
        line.forRayTracing = false;
        line.userData = {
          GUID: member.GUID,
          idNumber: member.$id,
          Description: "iJoist",
          toScreen: "iJoist",
          level: member.LevelName,
          material: member.MaterialName,
          supplier: member.Supplier,
          width: member.Width,
          depth: member.Depth,
          flangeThickness: member.FlangeThickness,
          webThickness: member.WebThickness,
          plyLine: true,
        }
        group.add(line);
      }
    }
    mesh.allData = member;
    if(objectOpacity.transparent){
      mesh.material.transparent = true;
      mesh.material.opacity = objectOpacity.opacity;
  }
    group.add( mesh );
    if(member.ZClip1Positions && member.ZClip1Positions.length){
      if(member.ZClip1Positions[0].length){
        let mesh1 = generateZClip(member.ZClip1Positions[0],member.ZClip1TriangleIndices[0],average)
        let mesh2 = generateZClip(member.ZClip2Positions[0],member.ZClip2TriangleIndices[0],average)
        mesh1.updateMatrix();
        mesh2.updateMatrix();

        var geo1 = mesh1.geometry.clone().applyMatrix4(mesh1.matrix);
        var geo2 = mesh2.geometry.clone().applyMatrix4(mesh2.matrix);
        var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
        var mater = new THREE.MeshBasicMaterial({color: 0x808080});
        const mesh = new THREE.Mesh( mergedGeometry, mater );
        mesh.forRayTracing = true;
        mesh.layers.set( 17 );
        mesh.userData = {
          type: "zClip",
          Description: "noggin",
          level: member.LevelName,
          GUID: member.GUID,
          idNumber: member.$id,
        };
        mesh.displayWindowInfo = {
          Type: "Z Clip",
          Level: member.LevelName,
        }
        group.add( mesh );
      }
    }
    if(member.BackerBlockLines && member.BackerBlockLines.length){
      if(member.BackerBlockLines[0].length){
        generateBackerBlock(member.BackerBlockLines,average, group,member.LevelName,member.GUID, member.$id, 2, "iJoist")
      }
    }
    if(member.JoistClipPositionsTop && member.JoistClipPositionsTop.length){
      if(member.JoistClipPositionsTop[0].length){
        generateJoistClip(member.JoistClipPositionsTop,member.JoistClipTriangleIndicesTop,average, group, member)
      }
    }
    if(member.JoistClipPositionsBottom && member.JoistClipPositionsBottom.length){
      if(member.JoistClipPositionsBottom[0].length){
        generateJoistClip(member.JoistClipPositionsBottom,member.JoistClipTriangleIndicesBottom,average, group, member)
      }
    }
    if(member.ReinforcementPositions && member.ReinforcementPositions.length){
      if(member.ReinforcementPositions[0].length){
        generateReinforcement(member.ReinforcementPositions, member.ReinforcementTriangleIndices,average, group, member, 2)
      }
    }
    if(member.EndDevice1MeshPositions && member.EndDevice1MeshPositions.length){
      if(member.EndDevice1MeshPositions[0].length){
        generateEndDevices(member.EndDevice1MeshPositions, member.EndDevice1TriangleIndices, average, group, member, 2, member.EndDevice1Name)
      }
    }
    if(member.EndDevice2MeshPositions && member.EndDevice2MeshPositions.length){
      if(member.EndDevice2MeshPositions[0].length){
        generateEndDevices(member.EndDevice2MeshPositions, member.EndDevice2TriangleIndices, average, group, member, 2, member.EndDevice2Name)
      }
    }
  }
}

export { generateIJoist };
/* eslint-disable */
import { generateMember } from "../ObjectCreateFolders/member.js";
import { generateWall } from "../ObjectCreateFolders/wall.js";
import { generateRoof } from "../ObjectCreateFolders/roof.js";
import { generateColumn } from "../ObjectCreateFolders/columns.js";
import { generateTruss } from "../ObjectCreateFolders/truss.js";
import { generateObstruction } from "../ObjectCreateFolders/obstruction.js";
import { generateStrongBack } from "../ObjectCreateFolders/strongback.js";
import { generateSquashBlock } from "../ObjectCreateFolders/squashBlock.js";
import { generatePonyWall } from "../ObjectCreateFolders/ponywall.js";
import { generateGroudFloor } from "../ObjectCreateFolders/helperFiles/helperFunctions.js";
import { generateConstructionLines } from "../ObjectCreateFolders/constructionLines.js";

function electronInialization(data, items, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, settings, camera, raycaster){
    const beamTypeMap = {
        'LVL': items.LVL,
        'Rimboard': items.Rimboard,
        'BeamByOthers': items.BeamByOthers,
        'Steel': items.Steel,
        'Lumber': items.Lumber,
        'Glulam': items.Glulam,
    };

    // Create a map for the special cases in BeamTypes
    const specialBeamTypes = new Set(["OpenJoist", "GatorJoist", "OJ2000", "TrimJoist", "TrimJoistDFL"]);
    let opacityObject = {
        transparent: false,
        opacity: currentTransparencyOutside / 10
    }
    data.Members.forEach(element => {
        try{
            if(specialBeamTypes.has(element.BeamType)) {
                items.OpenJoist.inModel = true;
                items.OpenJoist.count++;
                if(items.OpenJoist.opacity){
                    opacityObject.transparent = true;
                }
            } else if(element.BeamType === 'IJoist') {
                if(element.IsNoggin){
                    items.noggin.inModel = true;
                    items.noggin.count++;
                    if(items.noggin.opacity){
                        opacityObject.transparent = true;
                    }
                }
                else {
                    items.iJoist.inModel = true;
                    items.iJoist.count++;
                    if(items.iJoist.opacity){
                        opacityObject.transparent = true;
                    }
                }
            } else {
                if(element.IsPlate){
                    items.Plate.inModel = true;
                    items.Plate.count++;
                    if(items.Plate.opacity){
                        opacityObject.transparent = true;
                    }
                }
                else{
                    let item = beamTypeMap[element.BeamType];
                    if(item) {
                        item.inModel = true;
                        item.count++;
                        if(item.opacity){
                            opacityObject.transparent = true;
                        }
                    }
                }
            }
            generateMember(element, average, group, version, isMetric, hangerNames, counts, bbox, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Member with GUID: " + element.GUID);
        }
    });
    data.Walls.forEach(element => {
        try{
            items.Wall.inModel = true;
            items.Wall.count++;
            if(items.Wall.opacity){
                opacityObject.transparent = true;
            }
            generateWall(element, average, group, isMetric, bbox, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Wall with GUID: " + element.GUID);
        }
    });
    data.RoofPlane.forEach(element => {
        try{
            items.Roof.inModel = true;
            items.Roof.count++;
            if(items.Roof.opacity){
                opacityObject.transparent = true;
            }
            generateRoof(element, average, group,null, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Roof with GUID: " + element.GUID);
        }
    });
    data.Columns.forEach(element => {
        try{
            if(!element.IsStud){
                items.Column.inModel = true;
                items.Column.count++;
                if(items.Column.opacity){
                    opacityObject.transparent = true;
                }
            }
            else{
                items.Stud.inModel = true;
                items.Stud.count++;
                if(items.Stud.opacity){
                    opacityObject.transparent = true;
                }
            }
            generateColumn(element,average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Column with GUID: " + element.GUID);
        }
    });
    data.Trusses.forEach(element => {
        try{
            items.Truss.inModel = true;
            items.Truss.count++;
            if(items.Truss.opacity){
                opacityObject.transparent = true;
            }
            generateTruss(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Truss with GUID: " + element.UndoGuid);
        }
    });
    data.Obstructions.forEach(element => {
        try{
            items.Obstruction.inModel = true;
            items.Obstruction.count++;
            if(items.Obstruction.opacity){
                opacityObject.transparent = true;
            }
            generateObstruction(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Obstruction with GUID: " + element.UndoGUID);
        }
    });
    data.StrongBack.forEach(element => {
        try{
            items.Strongback.inModel = true;
            items.Strongback.count++;
            if(items.Strongback.opacity){
                opacityObject.transparent = true;
            }
            generateStrongBack(element, average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Strongback with GUID: " + element.UndoGUID);
        }
    });
    data.SquashBlockLine.forEach(element => {
        try{
            items.SquashBlockLine.inModel = true;
            items.SquashBlockLine.count++;
            if(items.SquashBlockLine.opacity){
                opacityObject.transparent = true;
            }
            generateSquashBlock(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating SquashBlock with GUID: " + element.UndoGUID);
        }
    });
    data.BlockingLines.forEach(element => {
        // if(element.NogginLines.length > 0){
        //     items.BlockingLine.inModel = true;
        //     items.BlockingLine.count++;
        //     generateBlockingLine(element, average, group,levelNames);
        // }
        try{
            if(element.ItemGuids.length > 0){
                items.Blocking.inModel = true;
                items.Blocking.count+= element.ItemGuids.length;
                const guidMap = new Map(element.ItemGuids.map(item => [item, true]));
                group.children.forEach(child => {
                    try{
                        if(guidMap.has(child.userData.GUID)) {
                            if(child.userData.Description === "iJoist"){
                                child.userData.Description = "Blocking";
                                child.userData.toScreen = "Blocking";
                                child.layers.set(22);
                                child.displayWindowInfo.Type = "Blocking";
                                if(items.Blocking.opacity){
                                    child.material.transparent = true;
                                    child.material.opacity = currentTransparencyOutside / 10;
                                }
                            }
                        }
                    }catch(err){
                        console.log(err);
                        console.log("Error generating Blocking with GUID: " + child.userData.GUID);
                    }
                });
            }
        }catch(err){
            console.log(err);
            console.log("Error generating Blocking with GUID: " + element);
        }
    });
    data.PonyWalls.forEach(element => {
        try{
            items.PonyWall.inModel = true;
            items.PonyWall.count++;
            if(items.PonyWall.opacity){
                opacityObject.transparent = true;
            }
            generatePonyWall(element, average, group, items, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating PonyWall with GUID: " + element.GUID);
        }
    });
    let firstTimeDeck = true;
    data.DeckingPolygon.forEach(element => {
        items.Decking.inModel = true;
        items.Decking.count++;
        generateGroudFloor(element, average, group);
        if(items.Decking.count > 0 && firstTimeDeck && settings.DeckingVisibility){
            items.GroundLayer.visibility = false;
            camera.layers.toggle(20);
            raycaster.layers.toggle(20);
            firstTimeDeck = false;
        }
    });
}
function portalInialization(data, items, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, camera, raycaster){
    const beamTypeMap = {
        'LVL': items.LVL,
        'Rimboard': items.Rimboard,
        'BeamByOthers': items.BeamByOthers,
        'Steel': items.Steel,
        'Lumber': items.Lumber
    };

    // Create a map for the special cases in BeamTypes
    const specialBeamTypes = new Set(["OpenJoist", "GatorJoist", "OJ2000", "TrimJoist", "TrimJoistDFL"]);
    let opacityObject = {
        transparent: false,
        opacity: currentTransparencyOutside / 10
    }
    data.Members.forEach(element => {
        try{
            if(specialBeamTypes.has(element.BeamType)) {
                items.OpenJoist.inModel = true;
                items.OpenJoist.count++;
                if(items.OpenJoist.opacity){
                    opacityObject.transparent = true;
                }
            } else if(element.BeamType === 'IJoist') {
                if(element.IsNoggin){
                    items.noggin.inModel = true;
                    items.noggin.count++;
                    if(items.noggin.opacity){
                        opacityObject.transparent = true;
                    }
                }
                else {
                    items.iJoist.inModel = true;
                    items.iJoist.count++;
                    if(items.iJoist.opacity){
                        opacityObject.transparent = true;
                    }
                }
            } else {
                if(element.IsPlate){
                    items.Plate.inModel = true;
                    items.Plate.count++;
                    if(items.Plate.opacity){
                        opacityObject.transparent = true;
                    }
                }
                else{
                    let item = beamTypeMap[element.BeamType];
                    if(item) {
                        item.inModel = true;
                        item.count++;
                        if(item.opacity){
                            opacityObject.transparent = true;
                        }
                    }
                }
            }
            generateMember(element, average, group, version, isMetric, hangerNames, counts, bbox, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Member with GUID: " + element.GUID);
        }
    });
    data.Walls.forEach(element => {
        try{
            items.Wall.inModel = true;
            items.Wall.count++;
            if(items.Wall.opacity){
                opacityObject.transparent = true;
            }
            generateWall(element, average, group, isMetric, bbox, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Wall with GUID: " + element.GUID);
        }
    });
    data.RoofPlane.forEach(element => {
        try{
            items.Roof.inModel = true;
            items.Roof.count++;
            if(items.Roof.opacity){
                opacityObject.transparent = true;
            }
            generateRoof(element, average, group,null, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Roof with GUID: " + element.GUID);
        }
    });
    data.Columns.forEach(element => {
        try{
            if(!element.IsStud){
                items.Column.inModel = true;
                items.Column.count++;
                if(items.Column.opacity){
                    opacityObject.transparent = true;
                }
            }
            else{
                items.Stud.inModel = true;
                items.Stud.count++;
                if(items.Stud.opacity){
                    opacityObject.transparent = true;
                }
            }
            generateColumn(element,average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        }
        catch(err){
            console.log(err);
            console.log("Error generating Column with GUID: " + element.GUID);
        }
    });
    data.Trusses.forEach(element => {
        try{
            items.Truss.inModel = true;
            items.Truss.count++;
            if(items.Truss.opacity){
                opacityObject.transparent = true;
            }
            generateTruss(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Truss with GUID: " + element.UndoGuid);
        }
    });
    data.Obstructions.forEach(element => {
        try{
            items.Obstruction.inModel = true;
            items.Obstruction.count++;
            if(items.Obstruction.opacity){
                opacityObject.transparent = true;
            }
            generateObstruction(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Obstruction with GUID: " + element.UndoGUID);
        }
    });
    data.StrongBack.forEach(element => {
        try{
            items.Strongback.inModel = true;
            items.Strongback.count++;
            if(items.Strongback.opacity){
                opacityObject.transparent = true;
            }
            generateStrongBack(element, average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating Strongback with GUID: " + element.UndoGUID);
        }
    });
    data.SquashBlockLine.forEach(element => {
        try{
            items.SquashBlockLine.inModel = true;
            items.SquashBlockLine.count++;
            if(items.SquashBlockLine.opacity){
                opacityObject.transparent = true;
            }
            generateSquashBlock(element, average, group, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating SquashBlock with GUID: " + element.UndoGUID);
        }
    });
    data.BlockingLines.forEach(element => {
        // if(element.NogginLines.length > 0){
        //     items.BlockingLine.inModel = true;
        //     items.BlockingLine.count++;
        //     generateBlockingLine(element, average, group,levelNames);
        // }
        try{
            if(element.ItemGuids.length > 0){
                items.Blocking.inModel = true;
                items.Blocking.count+= element.ItemGuids.length;
                const guidMap = new Map(element.ItemGuids.map(item => [item, true]));
                group.children.forEach(child => {
                    try{
                        if(guidMap.has(child.userData.GUID)) {
                            if(child.userData.Description === "iJoist"){
                                child.userData.Description = "Blocking";
                                child.userData.toScreen = "Blocking";
                                child.layers.set(22);
                                child.displayWindowInfo.Type = "Blocking";
                                if(items.Blocking.opacity){
                                    child.material.transparent = true;
                                    child.material.opacity = currentTransparencyOutside / 10;
                                }
                            }
                        }
                    }catch(err){
                        console.log(err);
                        console.log("Error generating Blocking with GUID: " + child.userData.GUID);
                    }
                });
            }
        }catch(err){
            console.log(err);
            console.log("Error generating Blocking with GUID: " + element);
        }
    });
    data.PonyWalls.forEach(element => {
        try{
            items.PonyWall.inModel = true;
            items.PonyWall.count++;
            if(items.PonyWall.opacity){
                opacityObject.transparent = true;
            }
            generatePonyWall(element, average, group, items, opacityObject);
            opacityObject.transparent = false;
        }catch(err){
            console.log(err);
            console.log("Error generating PonyWall with GUID: " + element.GUID);
        }
    });
    let firstTimeDeck = true;
    data.DeckingPolygon.forEach(element => {
        items.Decking.inModel = true;
        items.Decking.count++;
        generateGroudFloor(element, average, group);
        if(items.Decking.count > 0 && firstTimeDeck){
            items.GroundLayer.visibility = false;
            camera.layers.toggle(20);
            raycaster.layers.toggle(20);
            firstTimeDeck = false;
        }
    });
}
function addMembersMethod(data, items, store, opacityObject, currentTransparencyOutside, average, group, version, isMetric, hangerNames, counts, bbox, camera, raycaster, planes, constructionLineGUID, levelN, scroll){
    if(data.Members.length > 0){
        data.Members.forEach((element) => {
            if(element.IsPlate){
                items.Plate.count++;
                store.commit('setModel', 'Plate');
                items.Plate.inModel = true;
                if(items.Plate.opacity){
                    opacityObject.transparent = true;
                }
            }
            if(element.BeamType === 'LVL'){
                items.LVL.count++;
                store.commit('setModel', 'LVL');
                items.LVL.inModel = true;
                if(items.LVL.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === 'Rimboard'){
                items.Rimboard.count++;
                store.commit('setModel', 'Rimboard');
                items.Rimboard.inModel = true;
                if(items.Rimboard.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === 'BeamByOthers'){
                items.BeamByOthers.count++;
                store.commit('setModel', 'BeamByOthers');
                items.BeamByOthers.inModel = true;
                if(items.BeamByOthers.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === 'Steel'){
                items.Steel.count++;
                store.commit('setModel', 'Steel');
                items.Steel.inModel = true;
                if(items.Steel.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === 'Lumber'){
                items.Lumber.count++;
                store.commit('setModel', 'Lumber');
                items.Lumber.inModel = true;
                if(items.Lumber.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === "OpenJoist" || element.BeamType === "GatorJoist" || element.BeamType === "OJ2000" || element.BeamType === "TrimJoist"){
                items.OpenJoist.count++;
                store.commit('setModel', 'OpenJoist');
                items.OpenJoist.inModel = true;
                if(items.OpenJoist.opacity){
                    opacityObject.transparent = true;
                }
            }
            else if(element.BeamType === 'IJoist'){
                // if(element.BlockingLineGuid != "00000000-0000-0000-0000-000000000000"){
                //     items.BlockingLine.count++;
                //     store.commit('setModel', 'BlockingLine');
                //     items.BlockingLine.inModel = true;
                // }
                if(element.IsNoggin){
                    items.noggin.count++;
                    store.commit('setModel', 'noggin');
                    items.noggin.inModel = true;
                    if(items.noggin.opacity){
                        opacityObject.transparent = true;
                    }
                }
                else{
                    items.iJoist.count++;
                    store.commit('setModel', 'iJoist');
                    items.iJoist.inModel = true;
                    if(items.iJoist.opacity){
                        opacityObject.transparent = true;
                    }
                }
            }
            generateMember(element, average, group, version, isMetric, hangerNames,counts,bbox, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.Walls.length > 0){
        data.Walls.forEach(element => {
            items.Wall.count++;
            store.commit('setModel', 'Wall');
            items.Wall.inModel = true;
            if(items.Wall.opacity){
                opacityObject.transparent = true;
            }
            generateWall(element, average, group, isMetric, bbox, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.RoofPlane.length > 0){
        data.RoofPlane.forEach(element => {
            items.Roof.count++;
            store.commit('setModel', 'Roof');
            items.Roof.inModel = true;
            if(items.Roof.opacity){
                opacityObject.transparent = true;
            }
            generateRoof(element, average, group, bbox, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.Columns.length > 0){
        data.Columns.forEach(element => {
            if(!element.IsStud){
                items.Column.count++;
                store.commit('setModel', 'Column');
                items.Column.inModel = true;
                if(items.Column.opacity){
                    opacityObject.transparent = true;
                }
            }
            else{
                items.Stud.count++;
                store.commit('setModel', 'Stud');
                items.Stud.inModel = true;
                if(items.Stud.opacity){
                    opacityObject.transparent = true;
                }
            }
            generateColumn(element, average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.Trusses.length > 0){
        data.Trusses.forEach(element => {
            items.Truss.count++;
            store.commit('setModel', 'Truss');
            items.Truss.inModel = true;
            if(items.Truss.opacity){
                opacityObject.transparent = true;
            }
            generateTruss(element, average, group, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.Obstructions.length > 0){
        data.Obstructions.forEach(element => {
            items.Obstruction.count++;
            store.commit('setModel', 'Obstruction');
            items.Obstruction.inModel = true;
            if(items.Obstruction.opacity){
                opacityObject.transparent = true;
            }
            generateObstruction(element, average, group, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.StrongBack.length > 0){
        data.StrongBack.forEach(element => {
            items.Strongback.count++;
            store.commit('setModel', 'Strongback');
            items.Strongback.inModel = true;
            if(items.Strongback.opacity){
                opacityObject.transparent = true;
            }
            generateStrongBack(element, average, group, isMetric, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.SquashBlockLine.length > 0){
        data.SquashBlockLine.forEach(element => {
            items.SquashBlockLine.count++;
            store.commit('setModel', 'SquashBlockLine');
            items.SquashBlockLine.inModel = true;
            if(items.SquashBlockLine.opacity){
                opacityObject.transparent = true;
            }
            generateSquashBlock(element, average, group, opacityObject);
            opacityObject.transparent = false;
        });
    }
    if(data.BlockingLines.length > 0){
        data.BlockingLines.forEach(element => {
            // items.BlockingLine.count++;
            // store.commit('setModel', 'BlockingLine');
            // items.BlockingLine.inModel = true;
            // generateBlockingLine(element, average, group,levelNames);
            if(element.ItemGuids.length > 0){
                items.Blocking.inModel = true;
                items.Blocking.count+= element.ItemGuids.length;
                const guidMap = new Map(element.ItemGuids.map(item => [item, true]));
                group.children.forEach(child => {
                    if(guidMap.has(child.userData.GUID)) {
                        if(child.userData.Description === "iJoist"){
                            child.userData.Description = "Blocking";
                            child.userData.toScreen = "Blocking";
                            child.layers.set(22);
                            child.displayWindowInfo.Type = "Blocking";
                            if(items.Blocking.opacity){
                                child.material.transparent = true;
                                child.material.opacity = currentTransparencyOutside / 10;
                            }
                        }
                    }
                });
            }
        });
    }
    if(data.PonyWalls.length > 0){
        data.PonyWalls.forEach(element => {
            items.PonyWall.count++;
            store.commit('setModel', 'PonyWall');
            items.PonyWall.inModel = true;
            if(items.PonyWall.opacity){
                opacityObject.transparent = true;
            }
            generatePonyWall(element, average, group, opacityObject);
            opacityObject.transparent = false;
        });
    }
    data.DeckingPolygon.forEach(element => {
        items.Decking.inModel = true;
        items.Decking.count++;
        generateGroudFloor(element, average, group);
        if(items.Decking.count > 0){
            items.GroundLayer.visibility = false;
            camera.layers.toggle(20);
            raycaster.layers.toggle(20);
        }
    });
    if(data.ConstructionLines.length > 0){
        if(planes.length > 0){
            for(let i = 0; i < planes.length;){
                planes.pop();
            }
            group.traverse( function ( object ) {
                if(object.userData.Description){
                    object.material.clippingPlanes = null;
                }
            });
        }
        scroll.enableClipping = false;
        scroll.enableScrollClipping = false;
        generateConstructionLines(data.ConstructionLines[0], average, group,planes,constructionLineGUID,levelN);
    }
}
export { electronInialization, portalInialization, addMembersMethod };

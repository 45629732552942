/* eslint-disable */ 
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
async function generateHangerLeft(member, vec, lx1, ly1, lz1, description, layerNumber, rb1, lb1, average, group, version, geometryCache, didntWork, lowPerformance){
    if(member.HangerModel1 == null){
        finishHangerLeft(member, lb1, rb1, average, group, vec, layerNumber, description, true, null, lowPerformance)
        return;
    }
    let hangerType = member.HangerModel1.split(' ')[0];
    hangerType = hangerType.replace('(Min)', '');
    hangerType = hangerType.replace('/Min', '');
    hangerType = hangerType.replace('Min', '');
    hangerType = hangerType.replace('(Max)', '');
    hangerType = hangerType.replace('/Max', '');
    hangerType = hangerType.replace('Max', '');
    hangerType = hangerType.trim();
    const endVec = new THREE.Vector3(rb1.x - lb1.x, 0, rb1.z - lb1.z);
    endVec.normalize();
    hangerType = hangerType.replace('R/L', 'L');
    hangerType = hangerType.replace('LZ_RZ', 'LZ');
    hangerType = hangerType.replace('/', '_');
    let hangDesc = hangerType;
    hangerType += ".stl";
    try{
        let geometry;
        // if(version == 1){
        //     hangerType = "C:/Users/vande/source/repos/iStruct3Dv2/public/HangerFiles/" + hangerType;
        //     geometry = await loadGeometry(hangerType);
        // }
        if (version === 2) {
            if (geometryCache[hangerType]) {
                geometry = geometryCache[hangerType];
            }
            else if(didntWork[hangerType]){
                finishHangerLeft(member, lb1, rb1, average, group, vec, layerNumber, description, false, hangDesc, lowPerformance)
                return;
            }
        }
        else if (version === 0) {
            if (geometryCache[hangerType]) {
                geometry = geometryCache[hangerType];
            }
            else if(didntWork[hangerType]){
                finishHangerLeft(member, lb1, rb1, average, group, vec, layerNumber, description, false, hangDesc, lowPerformance)
                return;
            }
        }
        const material = new THREE.MeshPhongMaterial( { color: 0xC0C0C0, specular: 0x111111, shininess: 200 } );
        const meshH = new THREE.Mesh( geometry, material );
        meshH.geometry.computeBoundingSphere();
        function setRotation(meshH, endVec) {
            // Rotate hanger to be vertical (standing on the Y-axis)
            meshH.rotateY(Math.PI / 2);
            meshH.rotateX(-Math.PI / 2);
        
            // Align hanger with the desired endVec direction
            const up = new THREE.Vector3(0, 0, 1);
            const angle = up.angleTo(endVec);
            const rotationAxis = new THREE.Vector3();
            rotationAxis.crossVectors(up, endVec).normalize();
            meshH.rotateOnWorldAxis(rotationAxis, angle);
        }
        function setPosition(meshH, lx1, ly1, lz1, average, member) {
            if (meshH.geometry.boundingSphere.center.z < 0) {
                meshH.position.set(lx1 - average.x, ly1 + member.PlumbDepth, lz1 - average.z);
            } else {
                meshH.position.set(lx1 - average.x, ly1, lz1 - average.z);
            }
        }
        setPosition(meshH, lx1, ly1, lz1, average, member);
        setRotation(meshH, endVec);
        // setRotation(meshH, vec, endVec);
        meshH.scale.set(1, 1, 1.01);
        meshH.forRayTracing = true;
        meshH.userData = {
            Description: description,
            model: hangDesc,
            GUID: member.GUID,
            idNumber: member.$id,
            supplier: member.HangerSupplier1,
            level: member.LevelName,
            isHanger: true,
            color: 0xC0C0C0,
            normalColor: 0xC0C0C0,
        }
        meshH.displayWindowInfo = {
            Description: "Hanger",
            Model: hangDesc,
            Supplier: member.HangerSupplier1,
            Level: member.LevelName,
            TopMount: member.TopFastener1,
            FaceMount: member.FaceFastener1,
            JoistFastener: member.JoistFastener1,
        }
        meshH.layers.set(layerNumber);
        if(lowPerformance){
            meshH.material.visible = false;
        }
        else{
            meshH.material.visible = true;
        }
        group.add(meshH);
        return true;
    }
    catch(err){
        console.log(err);
        return false;
    }
}
async function generateHangerRight(member, vec, lx2, ly2, lz2, description, layerNumber, rb2, lb2, average, group, version, geometryCache, didntWork, lowPerformance){

    if(member.HangerModel2 == null){
        finishHangerRight(member, lb2, rb2, average, group, vec, layerNumber, description, true, null, lowPerformance)
        return;
    }
    let hangerType = member.HangerModel2.split(' ')[0];
    hangerType = hangerType.replace('(Min)', '');
    hangerType = hangerType.replace('/Min', '');
    hangerType = hangerType.replace('Min', '');
    hangerType = hangerType.replace('(Max)', '');
    hangerType = hangerType.replace('/Max', '');
    hangerType = hangerType.replace('Max', '');
    hangerType = hangerType.trim();
    const endVec = new THREE.Vector3(rb2.x - lb2.x, 0, rb2.z - lb2.z);
    endVec.normalize();
    hangerType = hangerType.replace('R/L', 'R');
    hangerType = hangerType.replace('LZ_RZ', 'RZ');
    hangerType = hangerType.replace('/', '_');
    let hangDesc = hangerType;
    hangerType += ".stl";
    try{
        let geometry;
        // if(version == 1){
        //     hangerType = "C:/Users/vande/source/repos/iStruct3Dv2/public/HangerFiles/" + hangerType;
        //     geometry = await loadGeometry(hangerType);

        // }
        if (version === 2) {
            if (geometryCache[hangerType]) {
                geometry = geometryCache[hangerType];
            }
            else if(didntWork[hangerType]){
                finishHangerRight(member, lb2, rb2, average, group, vec, layerNumber, description, true, null, lowPerformance)
                return;
            }
        }
        else if (version === 0){
            if (geometryCache[hangerType]) {
                geometry = geometryCache[hangerType];
            }
            else if(didntWork[hangerType]){
                finishHangerRight(member, lb2, rb2, average, group, vec, layerNumber, description, false, hangDesc, lowPerformance)
                return;
            }
        }
        const material = new THREE.MeshPhongMaterial( { color: 0xC0C0C0, specular: 0x111111, shininess: 200 } );
        const meshH = new THREE.Mesh( geometry, material );
        meshH.geometry.computeBoundingSphere();
        function setPosition(meshH, lx2, ly2, lz2, average, member) {
            if (meshH.geometry.boundingSphere.center.z < 0) {
                meshH.position.set(lx2 - average.x, ly2 + member.PlumbDepth, lz2 - average.z);
            } else {
                meshH.position.set(lx2 - average.x, ly2, lz2 - average.z);
            }
        }
        function setRotation(meshH, endVec) {
            // Rotate hanger to be vertical (standing on the Y-axis)
            meshH.rotateY(-Math.PI / 2);
            meshH.rotateX(-Math.PI / 2);
        
            // Align hanger with the desired endVec direction
            const up = new THREE.Vector3(0, 0, 1);
            const angle = up.angleTo(endVec);
            const rotationAxis = new THREE.Vector3();
            rotationAxis.crossVectors(up, endVec).normalize();
            meshH.rotateOnWorldAxis(rotationAxis, angle);
        }
        setRotation(meshH, endVec);
        setPosition(meshH, lx2, ly2, lz2, average, member);
        meshH.scale.set( 1, 1, 1.01 );

        meshH.forRayTracing = true;
        meshH.userData = {
            Description: description,
            GUID: member.GUID,
            idNumber: member.$id,
            level: member.LevelName,
            model: hangDesc,
            supplier: member.HangerSupplier2,
            isHanger: true,
            color: 0xC0C0C0,
            normalColor: 0xC0C0C0,
        }
        meshH.displayWindowInfo = {
            Description: "Hanger",
            Model: hangDesc,
            Supplier: member.HangerSupplier2,
            Level: member.LevelName,
            TopMount: member.TopFastener2,
            FaceMount: member.FaceFastener2,
            JoistFastener: member.JoistFastener2,
        }
        meshH.layers.set(layerNumber);
        if(lowPerformance){
            meshH.material.visible = false;
        }
        else{
            meshH.material.visible = true;
        }
        group.add(meshH);
        return true;
    }
    catch(err){
        console.log(err);
        return false;
    }
}
function generateHanger(height){
    const triangle = new THREE.Shape();
    triangle.moveTo(0, 0);
    triangle.lineTo(0, height);
    triangle.lineTo(.3, height);
    triangle.lineTo(5, 0);
    triangle.lineTo(0, 0);
    let extrudeSettings = {
        steps: 1,
        depth: .3,
        bevelEnabled: false,
    }
    const hangerGeo= new THREE.ExtrudeGeometry(triangle, extrudeSettings);
    const hangerMaterial = new THREE.MeshStandardMaterial({color: 0xC0C0C0, side: THREE.DoubleSide});
    const hanger = new THREE.Mesh( hangerGeo, hangerMaterial );
    return hanger; 
}
function finishHangerLeft(member, lb1, rb1, average, group, vec, layerNumber, description, info, hangDesc, lowPerformance){

    const hanger1 = generateHanger(member.PlumbDepth);
    const hanger2 = generateHanger(member.PlumbDepth);
    const yaw = Math.atan2(vec.z , vec.x);
    let color = 0xC0C0C0;
    if(vec.x === 0){
        hanger1.position.set(rb1.x - average.x , rb1.y, rb1.z - average.z);
        hanger2.position.set(lb1.x + 0.3 * vec.z - average.x, lb1.y, lb1.z - 0.3 * vec.x - average.z);
        hanger1.rotation.set(0,yaw + Math.PI,0);
        hanger2.rotation.set(0,yaw + Math.PI,0);
    }
    else if(vec.z === 0){
        hanger1.position.set(rb1.x - average.x , rb1.y, rb1.z - average.z);
        hanger2.position.set(lb1.x - 0.3 * vec.z - average.x, lb1.y, lb1.z - 0.3 * vec.x - average.z);
        hanger1.rotation.set(0, yaw, 0);
        hanger2.rotation.set(0, yaw, 0);
    }
    else{
        // console.log("ere")
        hanger1.position.set(rb1.x - average.x , rb1.y, rb1.z - average.z);
        hanger2.position.set(lb1.x - 0.3 * vec.z - average.x, lb1.y, lb1.z - 0.3 * vec.x - average.z);

        hanger1.rotation.set(0, yaw + Math.PI, 0);
        hanger2.rotation.set(0, yaw + Math.PI, 0);
        // color = 0x00ff00;
    }
    hanger1.updateMatrix();
    hanger2.updateMatrix();
    var geo1 = hanger1.geometry.clone().applyMatrix4(hanger1.matrix);
    var geo2 = hanger2.geometry.clone().applyMatrix4(hanger2.matrix);
    var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
    var material = new THREE.MeshStandardMaterial({color: color, side: THREE.DoubleSide});
    var meshH = new THREE.Mesh( mergedGeometry, material );
    meshH.forRayTracing = true;
    meshH.userData = {
        Description: description,
        toScreen: description,
        GUID: member.GUID,
        idNumber: member.$id,
        level: member.LevelName,
        isHanger: true,
    }
    let Supplier;
    if(info){
        hangDesc = "n/a";
        Supplier = "n/a";
    }
    else{
        Supplier = member.HangerSupplier1;
    }
    meshH.displayWindowInfo = {
        Description: "Hanger",
        Model: hangDesc,
        Supplier: Supplier,
        Level: member.LevelName,
        TopMount: member.TopFastener1,
        FaceMount: member.FaceFastener1,
        JoistFastener: member.JoistFastener1,
        // GUID: member.GUID,
        // vecx: vec.x,
        // vecy: vec.y,
        // vecz: vec.z,
        // yaw: yaw,
    }
    meshH.layers.set(layerNumber);
    if(lowPerformance){
        meshH.material.visible = false;
    }
    else{
        meshH.material.visible = true;
    }
    group.add(meshH);
}
function finishHangerRight(member, lb2, rb2, average, group, vec, layerNumber, description, info, hangDesc, lowPerformance){
    const hanger1 = generateHanger(member.PlumbDepth);
    const hanger2 = generateHanger(member.PlumbDepth);
    hanger1.layers.set(2);
    hanger2.layers.set(2);
    const yaw = Math.atan2(vec.z , vec.x);
    let color = 0xC0C0C0;
    if(vec.x === 0){
        hanger1.position.set(lb2.x - average.x, lb2.y, lb2.z - average.z);
        hanger2.position.set(rb2.x - 0.3 * vec.z - average.x, rb2.y, rb2.z - 0.3 * vec.x - average.z);
        hanger1.rotation.set(0,yaw,0);
        hanger2.rotation.set(0,yaw,0);
    }
    else if(vec.z === 0){
        hanger1.position.set(lb2.x - average.x, lb2.y, lb2.z - average.z);
        hanger2.position.set(rb2.x - 0.3 * vec.z - average.x, rb2.y, rb2.z + 0.3 * vec.x - average.z);
        hanger1.rotation.set(0,yaw + Math.PI,0);
        hanger2.rotation.set(0,yaw + Math.PI,0);
    }
    else{
        // console.log("here")
        hanger1.position.set(lb2.x - average.x, lb2.y, lb2.z - average.z);
        hanger2.position.set(rb2.x - 0.3 * vec.z - average.x, rb2.y, rb2.z + 0.3 * vec.x - average.z);

        hanger1.rotation.set(0, -yaw + Math.PI, 0);
        hanger2.rotation.set(0, -yaw + Math.PI, 0);
        // color = 0x00ff00;
    }
    hanger1.updateMatrix();
    hanger2.updateMatrix();

    var geo1 = hanger1.geometry.clone().applyMatrix4(hanger1.matrix);
    var geo2 = hanger2.geometry.clone().applyMatrix4(hanger2.matrix);
    var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
    var material = new THREE.MeshStandardMaterial({color: color, side: THREE.DoubleSide});
    var meshH = new THREE.Mesh( mergedGeometry, material );
    meshH.forRayTracing = true;
    meshH.userData = {
        Description: description,
        toScreen: description,
        GUID: member.GUID,
        idNumber: member.$id,
        level: member.LevelName,
        isHanger: true,
    }
    let Supplier;
    if(info){
        hangDesc = "n/a";
        Supplier = "n/a";
    }
    else{
        Supplier = member.HangerSupplier2;
    }
    meshH.displayWindowInfo = {
        Description: "Hanger",
        Model: hangDesc,
        Supplier: Supplier,
        Level: member.LevelName,
        TopMount: member.TopFastener2,
        FaceMount: member.FaceFastener2,
        JoistFastener: member.JoistFastener2,
        // GUID: member.GUID,
        // vecx: vec.x,
        // vecy: vec.y,
        // vecz: vec.z,
        // yaw: yaw,
    }
    meshH.layers.set(layerNumber);
    if(lowPerformance){
        meshH.material.visible = false;
    }
    else{
        meshH.material.visible = true;
    }
    group.add(meshH);
}
async function getHanger(hangerType) {
    try {
        const hangerData = await window.api.invoke('getHanger', hangerType);
        return hangerData;
    } catch (err) {
        console.error('Error in getHanger:', err);
        throw err;
    }
}
async function loadGeometry(hangerType) {
    return new Promise((resolve) => {
        const loader = new STLLoader();
        loader.load(hangerType, (geometry) => {
            resolve(geometry);
        });
    });
}
export {generateHangerLeft, generateHangerRight, generateHanger}
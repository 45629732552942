/* eslint-disable */ 
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';

function generateTruss(truss, average, group, objectOpacity){
    if(truss.Points3D.length > 0){
      let points = [];
      let extractedPoints = [];
      for (let i = 0; i < truss.Points3D[0].length; i++) {
        let point = {
          x: parseFloat(truss.Points3D[0][i].split(",")[0]) - average.x,
          y: parseFloat(truss.Points3D[0][i].split(",")[1]),
          z: parseFloat(truss.Points3D[0][i].split(",")[2]) - average.z,
        }
        points.push(point);
        extractedPoints.push(new THREE.Vector3(point.x, point.y, point.z));
      }
      let wholeTruss = null;
      let face;
      for(let i = 0; i < truss.Faces[0].length; i++){
        face = null;
        for(let j = 0; j < truss.Faces[0][i].Indices.length; j+=3){
          let point1 = points[truss.Faces[0][i].Indices[j]];
          let point2 = points[truss.Faces[0][i].Indices[j+1]];
          let point3 = points[truss.Faces[0][i].Indices[j+2]];
          let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
          let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
          let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
          const vertices = new Float32Array([
            pp1.x, pp1.y, pp1.z,
            pp2.x, pp2.y, pp2.z,
            pp3.x, pp3.y, pp3.z,
          ]);
          const geometry = new THREE.BufferGeometry();
          geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
          if(face == null){
            face = geometry;
          }
          else{
            face = mergeBufferGeometries([face, geometry]);
          }
        }
        if(wholeTruss == null){
          wholeTruss = face;
        }
        else{
          wholeTruss = mergeBufferGeometries([wholeTruss, face]);
        }
      }
      const edges = new THREE.EdgesGeometry(wholeTruss);
      const material = new THREE.MeshStandardMaterial({
        color: 0xD14C10,
        flatShading: true,
        side: THREE.DoubleSide,
      });
      const mesh = new THREE.Mesh(wholeTruss, material);
      mesh.allData = truss;
      mesh.layers.set(14);
      mesh.forRayTracing = true;
      mesh.userData = { 
        Description: "Truss",
        toScreen: "Truss",
        GUID: truss.UndoGuid,
        idNumber: truss.$id,
        level: truss.Level,
        layer: 14,
        color: 0xD14C10,
        canChangeColor: false,
        normalColor: 0xD14C10,
        edges: edges,
        tapeMeasure: true,
        points: extractedPoints,
      }
      mesh.displayWindowInfo = {
        Type: "Truss",
        // ID: truss.$id,
        Level: truss.Level,
      }
      if(objectOpacity.transparent){
        mesh.material.transparent = true;
        mesh.material.opacity = objectOpacity.opacity;
      }
      group.add(mesh);
    }
    else{
      let wholeTruss = null;
      let extractedPoints = [];
      for(let i = 0; i < truss.Points3DForMembers.length; i++){
        let points = [];
        for (let j = 0; j < truss.Points3DForMembers[i].length; j++) {
          let point = {
            x: parseFloat(truss.Points3DForMembers[i][j].split(",")[0]) - average.x, 
            y: parseFloat(truss.Points3DForMembers[i][j].split(",")[1]),
            z: parseFloat(truss.Points3DForMembers[i][j].split(",")[2]) - average.z,
          }
          points.push(point);
          extractedPoints.push(new THREE.Vector3(point.x, point.y, point.z));
        }
        let beam = null;
        let face;
        for(let j = 0; j < truss.FacesForMembers[i].length; j++){
          face = null;
          for(let k = 0; k < truss.FacesForMembers[i][j].Indices.length; k+=3){
            let point1 = points[truss.FacesForMembers[i][j].Indices[k]];
            let point2 = points[truss.FacesForMembers[i][j].Indices[k+1]];
            let point3 = points[truss.FacesForMembers[i][j].Indices[k+2]];
            let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
            let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
            let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
            const vertices = new Float32Array([
              pp1.x, pp1.y, pp1.z,
              pp2.x, pp2.y, pp2.z,
              pp3.x, pp3.y, pp3.z,
            ]);
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            if(face == null){
              face = geometry;
            }
            else{
              face = mergeBufferGeometries([face, geometry]);
            }
          }
          if(beam == null){
            beam = face;
          }
          else{
            beam = mergeBufferGeometries([beam, face]);
          }
        }
        if(wholeTruss == null){
          wholeTruss = beam;
        }
        else{
          wholeTruss = mergeBufferGeometries([wholeTruss, beam]);
        }
      }
      if(truss.Points3DForPlates.length){
        for(let i = 0; i < truss.Points3DForPlates.length; i++){
          let points = [];
          for (let j = 0; j < truss.Points3DForPlates[i].length; j++) {
            let point = {
              x: parseFloat(truss.Points3DForPlates[i][j].split(",")[0]) - average.x,
              y: parseFloat(truss.Points3DForPlates[i][j].split(",")[1]),
              z: parseFloat(truss.Points3DForPlates[i][j].split(",")[2]) - average.z,
            }
            points.push(point);
            extractedPoints.push(new THREE.Vector3(point.x, point.y, point.z));
          }
          let plate = null;
          let face;
          for(let j = 0; j < truss.FacesForPlates[i].length; j++){
            face = null;
            for(let k = 0; k < truss.FacesForPlates[i][j].Indices.length; k+=3){
              let point1 = points[truss.FacesForPlates[i][j].Indices[k]];
              let point2 = points[truss.FacesForPlates[i][j].Indices[k+1]];
              let point3 = points[truss.FacesForPlates[i][j].Indices[k+2]];
              let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
              let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
              let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
              const vertices = new Float32Array([
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z,
              ]);
              const geometry = new THREE.BufferGeometry();
              geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
              if(face == null){
                face = geometry;
              }
              else{
                face = mergeBufferGeometries([face, geometry]);
              }
            }
            if(plate == null){
              plate = face;
            }
            else{
              plate = mergeBufferGeometries([plate, face]);
            }
          }
          if(wholeTruss == null){
            wholeTruss = plate;
          }
          else{
            wholeTruss = mergeBufferGeometries([wholeTruss, plate]);
          }
        }
      }
      const edges = new THREE.EdgesGeometry(wholeTruss);
      const material = new THREE.MeshStandardMaterial({
        color: 0xD14C10,
        flatShading: true,
        side: THREE.DoubleSide,
      });
      const mesh = new THREE.Mesh(wholeTruss, material);
      mesh.allData = truss;
      mesh.layers.set(14);
      mesh.forRayTracing = true;
      mesh.userData = { 
        Description: "Truss",
        toScreen: "Truss",
        GUID: truss.UndoGuid,
        idNumber: truss.$id,
        level: truss.Level,
        layer: 14,
        color: 0xD14C10,
        canChangeColor: false,
        normalColor: 0xD14C10,
        edges: edges,
        points: extractedPoints,
        tapeMeasure: true,
      }
      mesh.displayWindowInfo = {
        Type: "Truss",
        // ID: truss.$id,
        Level: truss.Level,
      }
      if(objectOpacity.transparent){
        mesh.material.transparent = true;
        mesh.material.opacity = objectOpacity.opacity;
      }
      group.add(mesh);
  
        
    }
}
export {generateTruss}
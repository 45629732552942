/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
function generateConstructionLines(constructionLine, average, group, planes, constructionLineGUID, levelN){
    if(constructionLine && constructionLine.WhatIsThis === "FullSliceConstructionLine"){
        const x1 = parseFloat(constructionLine.Line.P1.split(",")[0])- average.x;
        const z1 = parseFloat(constructionLine.Line.P1.split(",")[1])- average.z;
        const x2 = parseFloat(constructionLine.Line.P2.split(",")[0])- average.x;
        const z2 = parseFloat(constructionLine.Line.P2.split(",")[1])- average.z; 
        // const points = [new THREE.Vector3(x1, 0, z1), new THREE.Vector3(x2, 0, z2)];
        // const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
        // const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        // const line = new THREE.Line(lineGeometry, lineMaterial);
        // line.thisIsConstructionLine = true;
        // group.add(line);
        let normal = new THREE.Vector3().subVectors(
            new THREE.Vector3(x2, 0, z2),
            new THREE.Vector3(x1, 0, z1)
        ).normalize();
        normal.cross(new THREE.Vector3(0, 1, 0)).normalize();

          
        let point = new THREE.Vector3(x1, 0, z1);
          
        planes[0] = new THREE.Plane(normal, -point.dot(normal));
        constructionLineGUID[0] = constructionLine.Guid;

        group.traverse( function ( object ) {
            if(object.userData.Description){
                object.material.clippingPlanes = planes;
            }
        });
    }
    else if(constructionLine && constructionLine.WhatIsThis === "LevelSliceConstructionLine"){
        levelN = constructionLine.LevelName;
        const x1 = parseFloat(constructionLine.Line.P1.split(",")[0])- average.x;
        const z1 = parseFloat(constructionLine.Line.P1.split(",")[1])- average.z;
        const x2 = parseFloat(constructionLine.Line.P2.split(",")[0])- average.x;
        const z2 = parseFloat(constructionLine.Line.P2.split(",")[1])- average.z; 
        // const points = [new THREE.Vector3(x1, 0, z1), new THREE.Vector3(x2, 0, z2)];
        // const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
        // const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        // const line = new THREE.Line(lineGeometry, lineMaterial);
        // line.thisIsConstructionLine = true;
        // group.add(line);
        let normal = new THREE.Vector3().subVectors(
            new THREE.Vector3(x2, 0, z2),
            new THREE.Vector3(x1, 0, z1)
        ).normalize();
        normal.cross(new THREE.Vector3(0, 1, 0)).normalize();
        let point = new THREE.Vector3(x1, 0, z1);
        planes[0] = new THREE.Plane(normal, -point.dot(normal));
        constructionLineGUID[0] = constructionLine.Guid;
        group.traverse( function ( object ) {
            if(object.userData.Description){
                if(object.userData.level === levelN){
                    object.material.clippingPlanes = planes;
                }
            }
        });
    }
}
export { generateConstructionLines };
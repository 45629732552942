import * as THREE from 'three';
function generateHelperPlanes(counts, helperPlanes) {
    const threshold = 0.01 * counts.memberCount.count;
    function getCountBasedColor(count, maxCount) {
        const ratio = count / maxCount;
        const hex = (ratio * 0xff) << 8; // shift towards green channel
        return hex;
    }

    for (let y in counts.minCounts) {
        if (counts.minCounts[y].count > threshold) {
            const color = getCountBasedColor(counts.minCounts[y].count, counts.memberCount.count);
            const planeMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide }); 
            const planeGeometry = new THREE.PlaneGeometry(
                counts.minCounts[y].maxX - counts.minCounts[y].minX, 
                counts.minCounts[y].maxZ - counts.minCounts[y].minZ
            );
            const plane = new THREE.Mesh(planeGeometry, planeMaterial);

            // set properties from your provided code
            plane.layers.enableAll();
            plane.rotation.x = - Math.PI / 2;
            plane.position.set(0, parseFloat(y), 0);
            plane.Description = "Ignore";
            plane.userData.helperPlane = true;
            plane.userData.tapeMeasureLine = true;
            plane.userData.name = "Level Bottom";
            plane.visible = false;

            helperPlanes.add(plane);
        }
    }

    for (let y in counts.maxCounts) {
        if (counts.maxCounts[y].count > threshold) {
            const color = getCountBasedColor(counts.maxCounts[y].count, counts.memberCount.count);
            const planeMaterial = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide });
            const planeGeometry = new THREE.PlaneGeometry(
                counts.maxCounts[y].maxX - counts.maxCounts[y].minX, 
                counts.maxCounts[y].maxZ - counts.maxCounts[y].minZ
            );
            const plane = new THREE.Mesh(planeGeometry, planeMaterial);

            // set properties from your provided code
            plane.layers.enableAll();
            plane.rotation.x = - Math.PI / 2;
            plane.position.set(0, parseFloat(y), 0);
            plane.Description = "Ignore";
            plane.userData.helperPlane = true;
            plane.userData.tapeMeasureLine = true;
            plane.userData.name = "Level Top";
            plane.visible = false;

            helperPlanes.add(plane);
        }
    }
}
export{generateHelperPlanes};
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateReinforcement(positions,  indices, average , group, member, type){
    console.log(positions.length, indices.length)
    for(let i = 0; i < positions.length; i++){
        let points = [];
        for(let j = 0; j < positions[i].length; j++){
            let point = {}
            point = {
                x: parseFloat(positions[i][j].split(",")[0]) * 20 - average.x,
                y: parseFloat(positions[i][j].split(",")[1]) * 20,
                z: parseFloat(positions[i][j].split(",")[2]) * 20 - average.z
            }
            points.push(point);
        }
        console.log(points);
        let face = null;
        for(let j = 0; j < indices[i].length; j+=3){
            let point1 = points[indices[i][j]];
            let point2 = points[indices[i][j+1]];
            let point3 = points[indices[i][j+2]];
            let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
            let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
            let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
            const vertices = new Float32Array( [
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z
            ] );
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            if(face == null){
                face = geometry;
            }else{
                face = mergeBufferGeometries([face, geometry]);
            }
        }
        const material = new THREE.MeshStandardMaterial( { color: 0xb7b7b7, flatShading: true, side: THREE.DoubleSide  } );
        const mesh = new THREE.Mesh( face, material );
        mesh.layers.set( type );
        mesh.forRayTracing = true;
        mesh.userData = {Description: "iJoist",level: member.LevelName, idNumber: member.$id, GUID: member.GUID};
        let info = member.HoleReinforcementInfo[i];
        mesh.displayWindowInfo = {
            Type: "Web Hole Reinforcement",
            Manufacturer: info.Manufacturer,
            Connector_Model: info.Connector_Model,
            Item_Reference: info.Item_Reference,
            Description: info.Description,
            JoistSeries: info.JoistSeries,
            JoistDepth_mm: info.JoistDepth_mm,
            MaxHoleDepth_mm: info.MaxHoleDepth_mm,
            MaxHoleWidth_mm: info.MaxHoleWidth_mm,
            Vk_hole_1Ply_kN: info.Vk_hole_1Ply_kN,
            Vk_hole_2Ply_kN: info.Vk_hole_2Ply_kN,
            Level: member.LevelName
        };
        group.add( mesh );
    } 
}
export { generateReinforcement };
/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateGussets(gssetInfo, gussetPositions, gussetTriangleIndices, average){
    let wholeGusset = null;
    for(let i = 0; i < gussetPositions.length; i++){
        let points = [];    
        for(let j = 0; j < gussetPositions[i].length; j++){
            let point = {
                x: parseFloat(gussetPositions[i][j].split(",")[0]) * 20 - average.x,
                y: parseFloat(gussetPositions[i][j].split(",")[1]) * 20,
                z: parseFloat(gussetPositions[i][j].split(",")[2]) * 20 - average.z,
            }
            points.push(point);
        }
        let face = null;
        for(let j = 0; j < gussetTriangleIndices[i].length; j+=3){
            let point1 = points[gussetTriangleIndices[i][j]];
            let point2 = points[gussetTriangleIndices[i][j + 1]];
            let point3 = points[gussetTriangleIndices[i][j + 2]];
            let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
            let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
            let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
            const vertices = new Float32Array([
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z,
            ]);
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            if(face == null){
                face = geometry;
            }
            else{
                face = mergeBufferGeometries([face, geometry]);
            }
        }
        if(wholeGusset == null){
            wholeGusset = face;
        }
        else{
            wholeGusset = mergeBufferGeometries([wholeGusset, face]);
        }
    }
    const material = new THREE.MeshStandardMaterial({
        color: 0x5d96a7,
        flatShading: true,
        side: THREE.DoubleSide,
    });
    const mesh = new THREE.Mesh(wholeGusset, material);
    return mesh;

}
export {generateGussets};
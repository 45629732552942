import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateJoistClip(positions,  indices, average, group, member){
    for(let i = 0; i < positions.length; i++){
        let points = [];
        for(let j = 0; j < positions[i].length; j++){
            let point = {}
            point = {
                x: parseFloat(positions[i][j].split(",")[0]) * 20 - average.x,
                y: parseFloat(positions[i][j].split(",")[1]) * 20,
                z: parseFloat(positions[i][j].split(",")[2]) * 20 - average.z
            }
            points.push(point);
            // Add the point to the scene 
            // const geometry = new THREE.SphereGeometry( 0.1, 32, 32 );
            // const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
            // const sphere = new THREE.Mesh( geometry, material );
            // sphere.position.set(point.x, point.y, point.z);
            // group.add( sphere );
        }
        let face = null;
        for(let j = 0; j < indices[i].length; j+=3){
            let point1 = points[indices[i][j]];
            let point2 = points[indices[i][j+1]];
            let point3 = points[indices[i][j+2]];
            let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
            let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
            let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
            const vertices = new Float32Array( [
                pp1.x, pp1.y, pp1.z,
                pp2.x, pp2.y, pp2.z,
                pp3.x, pp3.y, pp3.z
            ] );
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
            if(face == null){
                face = geometry;
            }else{
                face = mergeBufferGeometries([face, geometry]);
            }
        }
        const material = new THREE.MeshStandardMaterial( { color: 0x000000, flatShading: true, side: THREE.DoubleSide  } );
        const mesh = new THREE.Mesh( face, material );
        mesh.layers.set( 2 );
        mesh.forRayTracing = true;
        mesh.userData = {Description: "iJoist",level: member.LevelName, idNumber: member.$id, GUID: member.GUID};
        mesh.displayWindowInfo = {
            Type: "Joist Clip",
            Level: member.LevelName,
        }
        group.add( mesh );
    }   
}
export { generateJoistClip };
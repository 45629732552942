import * as THREE from 'three';
import { CSG } from 'three-csg-ts';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';

function generateColumn(column, average, group, isMetric, objectOpacity){
    // Concrete: #A9A9A9
    // Steel: #B0B0B0
    // Lumber: #964B00
    let color = 0xecd5a7; //default color
    if(column.ColumnMaterial === 1){
      color = 0xecd5a7; //lumber
    }
    else if(column.ColumnMaterial === 0){
      color = 0xB0B0B0; //steel
    }
    else if(column.ColumnMaterial === 2){
      color = 0xA9A9A9; //concrete
    } 
    let mesh;
    let edges;
    let line; 
    let lb1 = new THREE.Vector3(parseFloat(column.BottomPoint1.split(",")[0]),parseFloat(column.BottomPoint1.split(",")[2]), parseFloat(column.BottomPoint1.split(",")[1]));
    let rb1 = new THREE.Vector3(parseFloat(column.BottomPoint2.split(",")[0]),parseFloat(column.BottomPoint2.split(",")[2]), parseFloat(column.BottomPoint2.split(",")[1]));
    let rb2 = new THREE.Vector3(parseFloat(column.BottomPoint3.split(",")[0]),parseFloat(column.BottomPoint3.split(",")[2]), parseFloat(column.BottomPoint3.split(",")[1]));
    let lb2 = new THREE.Vector3(parseFloat(column.BottomPoint4.split(",")[0]),parseFloat(column.BottomPoint4.split(",")[2]), parseFloat(column.BottomPoint4.split(",")[1]));
    let lt1 = new THREE.Vector3(parseFloat(column.TopPoint1.split(",")[0]),parseFloat(column.TopPoint1.split(",")[2]), parseFloat(column.TopPoint1.split(",")[1]));
    let rt1 = new THREE.Vector3(parseFloat(column.TopPoint2.split(",")[0]),parseFloat(column.TopPoint2.split(",")[2]), parseFloat(column.TopPoint2.split(",")[1]));
    let rt2 = new THREE.Vector3(parseFloat(column.TopPoint3.split(",")[0]),parseFloat(column.TopPoint3.split(",")[2]), parseFloat(column.TopPoint3.split(",")[1]));
    let lt2 = new THREE.Vector3(parseFloat(column.TopPoint4.split(",")[0]),parseFloat(column.TopPoint4.split(",")[2]), parseFloat(column.TopPoint4.split(",")[1]));
    const points = [lb1, rb1, rb2, lb2, lt1, rt1, rt2, lt2];
    let distance = [
      rb1.distanceTo(rt1),
      rb2.distanceTo(rt2),
      lb1.distanceTo(lt1),
      lb2.distanceTo(lt2),  
    ];
    let maxDistance = Math.max(...distance);
    // if(checkBounds){
    //   (function(){
    //     if(lb1.x < bbminX || lb2.x < bbminX || rb1.x < bbminX || rb2.x < bbminX || lt1.x < bbminX || lt2.x < bbminX || rt1.x < bbminX || rt2.x < bbminX){
    //       bbminX = Math.min(lb1.x, lb2.x, rb1.x, rb2.x, lt1.x, lt2.x, rt1.x, rt2.x);
    //     }
    //     if(lb1.x > bbmaxX || lb2.x > bbmaxX || rb1.x > bbmaxX || rb2.x > bbmaxX || lt1.x > bbmaxX || lt2.x > bbmaxX || rt1.x > bbmaxX || rt2.x > bbmaxX){
    //       bbmaxX = Math.max(lb1.x, lb2.x, rb1.x, rb2.x, lt1.x, lt2.x, rt1.x, rt2.x);
    //     }
    //     if(lb1.z < bbminZ || lb2.z < bbminZ || rb1.z < bbminZ || rb2.z < bbminZ || lt1.z < bbminZ || lt2.z < bbminZ || rt1.z < bbminZ || rt2.z < bbminZ){
    //       bbminZ = Math.min(lb1.z, lb2.z, rb1.z, rb2.z, lt1.z, lt2.z, rt1.z, rt2.z);
    //     }
    //     if(lb1.z > bbmaxZ || lb2.z > bbmaxZ || rb1.z > bbmaxZ || rb2.z > bbmaxZ || lt1.z > bbmaxZ || lt2.z > bbmaxZ || rt1.z > bbmaxZ || rt2.z > bbmaxZ){
    //       bbmaxZ = Math.max(lb1.z, lb2.z, rb1.z, rb2.z, lt1.z, lt2.z, rt1.z, rt2.z);
    //     }
    //     if(lb1.y < bbminY || lb2.y < bbminY || rb1.y < bbminY || rb2.y < bbminY || lt1.y < bbminY || lt2.y < bbminY || rt1.y < bbminY || rt2.y < bbminY){
    //       bbminY = Math.min(lb1.y, lb2.y, rb1.y, rb2.y, lt1.y, lt2.y, rt1.y, rt2.y);
    //     }
    //     if(lb1.y > bbmaxY || lb2.y > bbmaxY || rb1.y > bbmaxY || rb2.y > bbmaxY || lt1.y > bbmaxY || lt2.y > bbmaxY || rt1.y > bbmaxY || rt2.y > bbmaxY){
    //       bbmaxY = Math.max(lb1.y, lb2.y, rb1.y, rb2.y, lt1.y, lt2.y, rt1.y, rt2.y);
    //     }
    //   })();
    // }
    if(column.ColumnShape === 0 && !column.IsStud){
      const vertices = new Float32Array([
        //right side of cube
        rt1.x, rt1.y, rt1.z,
        rb2.x, rb2.y, rb2.z,
        rb1.x, rb1.y, rb1.z,
  
        rt1.x, rt1.y, rt1.z,
        rt2.x, rt2.y, rt2.z,
        rb2.x, rb2.y, rb2.z,
  
        // //left side of cube
  
        lb1.x, lb1.y, lb1.z,
        lb2.x, lb2.y, lb2.z,
        lt1.x, lt1.y, lt1.z,
  
        lb2.x, lb2.y, lb2.z,
        lt2.x, lt2.y, lt2.z,
        lt1.x, lt1.y, lt1.z,
  
        // //bottom of cube
        rb1.x, rb1.y, rb1.z,
        rb2.x, rb2.y, rb2.z,
        lb1.x, lb1.y, lb1.z,
  
        rb2.x, rb2.y, rb2.z,
        lb2.x, lb2.y, lb2.z,
        lb1.x, lb1.y, lb1.z,
  
        // //top of cube
        lt1.x, lt1.y, lt1.z,
        rt2.x, rt2.y, rt2.z,
        rt1.x, rt1.y, rt1.z,
  
        lt1.x, lt1.y, lt1.z,
        lt2.x, lt2.y, lt2.z,
        rt2.x, rt2.y, rt2.z,
  
        // //front of cube
        lb1.x, lb1.y, lb1.z,
        rt1.x, rt1.y, rt1.z,
        rb1.x, rb1.y, rb1.z,
  
        lb1.x, lb1.y, lb1.z,
        lt1.x, lt1.y, lt1.z,
        rt1.x, rt1.y, rt1.z,
  
        // //back of cube
        rb2.x, rb2.y, rb2.z,
        rt2.x, rt2.y, rt2.z,
        lb2.x, lb2.y, lb2.z,
  
        rt2.x, rt2.y, rt2.z,
        lt2.x, lt2.y, lt2.z,
        lb2.x, lb2.y, lb2.z,
  
      ]);
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
      const material = new THREE.MeshPhongMaterial({color: color, flatShading: true,side: THREE.DoubleSide});
      mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(-average.x,0,-average.z);
      edges = new THREE.EdgesGeometry( geometry );
    }
    else if(column.ColumnShape === 0 && column.IsStud){
      let columnMesh = null;
      let extractedPoints = [];
      for(let i = 0; i < column.ColumnMeshPositions.length; i++){
          let points = [];
          for (let j = 0; j < column.ColumnMeshPositions[i].length; j++) {
            let point = {};
            point = {
              x: parseFloat(column.ColumnMeshPositions[i][j].split(",")[0]) * 20 - average.x,
              y: parseFloat(column.ColumnMeshPositions[i][j].split(",")[1]) * 20,
              z: parseFloat(column.ColumnMeshPositions[i][j].split(",")[2]) * 20 - average.z,
            }
            points.push(point);
            extractedPoints.push(new THREE.Vector3(point.x, point.y, point.z));
          }
          let face;
          face = null;
          for(let j = 0; j < column.ColumnTriangleIndices[i].length; j+=3){
            let point1 = points[column.ColumnTriangleIndices[i][j]];
            let point2 = points[column.ColumnTriangleIndices[i][j+1]];
            let point3 = points[column.ColumnTriangleIndices[i][j+2]];
            let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
            let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
            let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
            const vertices = new Float32Array([
              pp1.x, pp1.y, pp1.z,
              pp2.x, pp2.y, pp2.z,
              pp3.x, pp3.y, pp3.z,
            ]);
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            if(face == null){
              face = geometry;
            }
            else{
              face = mergeBufferGeometries([face, geometry]);
            }
          }
          if(columnMesh == null){
            columnMesh = face;
          }
          else{
            columnMesh = mergeBufferGeometries([columnMesh, face]);
          }
      }
      const edges = new THREE.EdgesGeometry(columnMesh);
      const material = new THREE.MeshStandardMaterial({ color: 0xd8eced, flatShading: true, side: THREE.DoubleSide });
      const mesh = new THREE.Mesh(columnMesh, material);
      mesh.layers.set(9);
      mesh.forRayTracing = true;
      mesh.userData = {
        GUID: column.GUID,
        idNumber: column.$id,
        Description: "Stud",
        toScreen: "Stud",
        level: column.LevelName,
        color: color,
        normalColor: color,
        canChangeColor: false,
        material: column.ColumnMaterial,
        tapeMeasure: true,
        edges: edges,
        points: extractedPoints,
        layer: 9,
      }
      const elevationInInches = lb1.y.toFixed(2).replace(/\.?0*$/,'') + " in";
      const elevationInMillimeters = ((lb1.y * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm";
      const elevation = isMetric ? elevationInMillimeters : elevationInInches;
      const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
      const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
      mesh.displayWindowInfo = {
        Type: "Stud",
        Level: column.LevelName,
        Height: length,
        Elevation: elevation,
      }
      if(objectOpacity.transparent){
        mesh.material.transparent = true;
        mesh.material.opacity = objectOpacity.value;
      }
      group.add(mesh);
    }
    else {
      let Diameter;
      if(lb1.x !== rb1.x){
        Diameter = Math.abs(lb1.x - rb1.x);
      }
      else{
        Diameter = Math.abs(lb1.z - rb1.z);
      }
      let height = column.Length;
      const topPoint3D = new THREE.Vector3(parseFloat(column.TopPoint3D.split(",")[0]),parseFloat(column.TopPoint3D.split(",")[2]), parseFloat(column.TopPoint3D.split(",")[1]));
      const bottomPoint3D = new THREE.Vector3(parseFloat(column.bottomPoint3D.split(",")[0]),parseFloat(column.bottomPoint3D.split(",")[2]), parseFloat(column.bottomPoint3D.split(",")[1]));
      if(topPoint3D.x === bottomPoint3D.x && topPoint3D.z === bottomPoint3D.z){
        let geometry = new THREE.CylinderGeometry(Diameter/2, Diameter/2, height, 64);
        let material = new THREE.MeshPhongMaterial({color: color, flatShading: true, side: THREE.DoubleSide});
        mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(bottomPoint3D.x - average.x, height / 2 + bottomPoint3D.y,bottomPoint3D.z - average.z);
        edges = new THREE.EdgesGeometry( geometry );
        line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 0x000000 } ) );
        line.position.set(bottomPoint3D.x - average.x, height / 2 + bottomPoint3D.y,bottomPoint3D.z - average.z);
      }
      else{
        let vector = new THREE.Vector3(topPoint3D.x - bottomPoint3D.x, topPoint3D.y - bottomPoint3D.y, topPoint3D.z - bottomPoint3D.z);
        const lengthHor = Math.sqrt(Math.pow(vector.x,2) + Math.pow(vector.z,2));
        const hypo = Math.sqrt(Math.pow(lengthHor,2) + Math.pow(vector.y,2));
        const angle = Math.asin(vector.y / hypo);
        const diameter = Diameter / Math.sin(angle);
        let geometry = new THREE.CylinderGeometry(Diameter/2, Diameter/2, height * 1.4, 64);
        let material = new THREE.MeshPhongMaterial({color: color, flatShading: true, side: THREE.DoubleSide});
        mesh = new THREE.Mesh(geometry, material);
        var axis = new THREE.Vector3(0, 1, 0);
        mesh.quaternion.setFromUnitVectors(axis, vector.clone().normalize());
        //find the plumn diameter after the rotation
        let vector1 = new THREE.Vector3(topPoint3D.x - bottomPoint3D.x, 0, topPoint3D.z - bottomPoint3D.z);
        vector1.normalize();
        const addX = diameter * vector1.x; 
        const addZ = diameter * vector1.z;
        console.log(diameter, addX, addZ, Diameter);
        mesh.position.set(bottomPoint3D.x + addX - average.x, height / 2 + bottomPoint3D.y, bottomPoint3D.z + addZ - average.z);
        const boxCutTop = new THREE.BoxGeometry(diameter* 4, height * 2, diameter * 4);
        const boxCutBottom = new THREE.BoxGeometry(diameter* 4, height * 2, diameter * 4);
        const materialTop = new THREE.MeshPhongMaterial({color: 0x000000, flatShading: true});
        const meshTop = new THREE.Mesh(boxCutTop, materialTop);
        meshTop.position.set(topPoint3D.x - average.x, topPoint3D.y + height, topPoint3D.z - average.z);
        const materialBottom = new THREE.MeshPhongMaterial({color: 0x000000, flatShading: true});
        var meshBottom = new THREE.Mesh(boxCutBottom, materialBottom);
        meshBottom.position.set(bottomPoint3D.x - average.x, bottomPoint3D.y - height, bottomPoint3D.z - average.z);
        meshBottom.updateMatrix();
        meshTop.updateMatrix();
        meshBottom = CSG.union (meshTop, meshBottom);
        meshBottom.updateMatrix();
        mesh.updateMatrix();
        mesh = CSG.subtract(mesh, meshBottom);
  
      }
    }
    if(!column.IsStud){
      mesh.layers.set(4);
      mesh.forRayTracing = true;
      mesh.userData = {
        GUID: column.GUID,
        idNumber: column.$id,
        Description: "Column",
        toScreen: "Column",
        level: column.LevelName,
        color: color,
        normalColor: color,
        canChangeColor: false,
        material: column.ColumnMaterial,
        tapeMeasure: true,
        edges: edges,
        points: points,
        layer: 4,
      }
      const elevationInInches = lb1.y.toFixed(2).replace(/\.?0*$/,'') + " in";
      const elevationInMillimeters = ((lb1.y * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm";
      const elevation = isMetric ? elevationInMillimeters : elevationInInches;
      const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
      const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
      mesh.displayWindowInfo = {
        Type: "Column",
        // ID: column.$id,
        Level: column.LevelName,
        Height: length,
        Elevation: elevation,
      }
      if(objectOpacity.transparent){
        mesh.material.transparent = true;
        mesh.material.opacity = objectOpacity.value;
      }
      group.add(mesh);
    }
}
  export {generateColumn}
/* eslint-disable  no-unused-vars */
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
import { generateZClip } from './zclip.js';
import { generateGussets } from './gussets.js';
import { generateScabs } from './scabs.js';

function generateGatorJoist(member, average, group, version, isMetric, hangerNames, objectOpacity){
    var openWeb;
    const rb1 = new THREE.Vector3(parseFloat(member._rightBottomP1.split(",")[0]), parseFloat(member._rightBottomP1.split(",")[2]), parseFloat(member._rightBottomP1.split(",")[1]));
    const rb2 = new THREE.Vector3(parseFloat(member._rightBottomP2.split(",")[0]), parseFloat(member._rightBottomP2.split(",")[2]), parseFloat(member._rightBottomP2.split(",")[1]));
    const rt1 = new THREE.Vector3(parseFloat(member._rightTopP1.split(",")[0]), parseFloat(member._rightTopP1.split(",")[2]), parseFloat(member._rightTopP1.split(",")[1]));
    const rt2 = new THREE.Vector3(parseFloat(member._rightTopP2.split(",")[0]), parseFloat(member._rightTopP2.split(",")[2]), parseFloat(member._rightTopP2.split(",")[1]));
    const lb1 = new THREE.Vector3(parseFloat(member._leftBottomP1.split(",")[0]), parseFloat(member._leftBottomP1.split(",")[2]), parseFloat(member._leftBottomP1.split(",")[1]));
    const lb2 = new THREE.Vector3(parseFloat(member._leftBottomP2.split(",")[0]), parseFloat(member._leftBottomP2.split(",")[2]), parseFloat(member._leftBottomP2.split(",")[1]));
    const lt1 = new THREE.Vector3(parseFloat(member._leftTopP1.split(",")[0]), parseFloat(member._leftTopP1.split(",")[2]), parseFloat(member._leftTopP1.split(",")[1]));
    const lt2 = new THREE.Vector3(parseFloat(member._leftTopP2.split(",")[0]), parseFloat(member._leftTopP2.split(",")[2]), parseFloat(member._leftTopP2.split(",")[1]));
    const endPoints1 = [
        rb1,
        rt1,
        lt1,
        lb1,
    ]
    const endPoints2 = [
        rb2,
        rt2,
        lt2,
        lb2,
    ]
    const points = [rb1, rt1, lt1, lb1, rb2, rt2, lt2, lb2];
    const middlePoints = [
      new THREE.Vector3((rb1.x + lb1.x) / 2, (rb1.y + lb1.y) / 2, (rb1.z + lb1.z) / 2),
      new THREE.Vector3((rt1.x + lt1.x) / 2, (rt1.y + lt1.y) / 2, (rt1.z + lt1.z) / 2),
      new THREE.Vector3((rt2.x + lt2.x) / 2, (rt2.y + lt2.y) / 2, (rt2.z + lt2.z) / 2),
      new THREE.Vector3((rb2.x + lb2.x) / 2, (rb2.y + lb2.y) / 2, (rb2.z + lb2.z) / 2),

    ]
    const px1 = parseFloat(member.Line.P1.split(",")[0]);
    const pz1 = parseFloat(member.Line.P1.split(",")[1]);
    const px2 = parseFloat(member.Line.P2.split(",")[0]);
    const pz2 = parseFloat(member.Line.P2.split(",")[1]);
    const py1 = parseFloat(member.Line.P1.split(",")[2]);
    const py2 = parseFloat(member.Line.P2.split(",")[2]);
    const y1 = parseFloat(member.Line.P1.split(",")[2]) + member.Depth/2;
    const y2 = y1 - member.PlumbDepth;
    const y3 = parseFloat(member.Line.P2.split(",")[2]) + member.Depth/2;
    const y4 = y3 - member.PlumbDepth;
    const slope = (pz2 - pz1) / (px2 - px1);
    const vec = new THREE.Vector3(px2 - px1, 0, pz2 - pz1);
    vec.normalize();
    let distance = [
        rb1.distanceTo(rb2),
        rt1.distanceTo(rt2),
        lb1.distanceTo(lb2),
        lt1.distanceTo(lt2),  
    ];
    let maxDistance = Math.max(...distance);
    const perp = new THREE.Vector3(-vec.z, 0, vec.x);
    if(Math.abs(perp.x) === 0){
        perp.x =0;
    }
    if(Math.abs(perp.z) === 0){
        perp.z =0;
    }
    let color = 0xecd5a7;
    let colorWrong;
    let openWebColor;
    if(member.ChosenColor == null){
        colorWrong = member.BasicColor;
    }
    else if (member.ChosenColor){
        colorWrong = member.ChosenColor;
    }
    if(colorWrong){
        openWebColor = parseInt(colorWrong.replace("#", "0x"));
    }
    else{
        openWebColor = 0xecd5a7; 
    }
    for(let i = 0; i < member.AllMemberMeshPositions.length; i++){
        let points = [];
        for (let j = 0; j < member.AllMemberMeshPositions[i].length; j++) {
          let point = {};
          point = {
            x: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[0] * 20) - average.x,
            y: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[1] * 20),
            z: parseFloat(member.AllMemberMeshPositions[i][j].split(",")[2] * 20) - average.z,
          }
          points.push(point);
        }
        let face;
        face = null;
        for(let j = 0; j < member.AllMemberMeshTriangleIndices[i].length; j+=3){
          let point1 = points[member.AllMemberMeshTriangleIndices[i][j]];
          let point2 = points[member.AllMemberMeshTriangleIndices[i][j+1]];
          let point3 = points[member.AllMemberMeshTriangleIndices[i][j+2]];
          let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
          let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
          let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
          const vertices = new Float32Array([
            pp1.x, pp1.y, pp1.z,
            pp2.x, pp2.y, pp2.z,
            pp3.x, pp3.y, pp3.z,
          ]);
          const geometry = new THREE.BufferGeometry();
          geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
          if(face == null){
            face = geometry;
          }
          else{
            if(geometry)
              face = mergeBufferGeometries([face, geometry]);
          }
        }
        if(openWeb == null){
          openWeb = face;
        }
        else{
          if(face)
            openWeb = mergeBufferGeometries([openWeb, face]);
        }
    }
    const material = new THREE.MeshPhongMaterial({
        color: openWebColor,
        side: THREE.DoubleSide,
        flatShading: true,
    });
    const vertices = new Float32Array([
      //right side of cube
      rb1.x, rb1.y, rb1.z,
      rb2.x, rb2.y, rb2.z,
      rt1.x, rt1.y, rt1.z,

      rb2.x, rb2.y, rb2.z,
      rt2.x, rt2.y, rt2.z,
      rt1.x, rt1.y, rt1.z,

      //left side of cube

      lt1.x, lt1.y, lt1.z,
      lb2.x, lb2.y, lb2.z,
      lb1.x, lb1.y, lb1.z,

      lt1.x, lt1.y, lt1.z,
      lt2.x, lt2.y, lt2.z,
      lb2.x, lb2.y, lb2.z,

      //bottom of cube
      lb1.x, lb1.y, lb1.z,
      rb2.x, rb2.y, rb2.z,
      rb1.x, rb1.y, rb1.z,

      lb1.x, lb1.y, lb1.z,
      lb2.x, lb2.y, lb2.z,
      rb2.x, rb2.y, rb2.z,

      //top of cube
      rt1.x, rt1.y, rt1.z,
      rt2.x, rt2.y, rt2.z,
      lt1.x, lt1.y, lt1.z,

      rt2.x, rt2.y, rt2.z,
      lt2.x, lt2.y, lt2.z,
      lt1.x, lt1.y, lt1.z,

      //front of cube
      rb1.x, rb1.y, rb1.z,
      rt1.x, rt1.y, rt1.z,
      lb1.x, lb1.y, lb1.z,

      rt1.x, rt1.y, rt1.z,
      lt1.x, lt1.y, lt1.z,
      lb1.x, lb1.y, lb1.z,

      //back of cube
      lb2.x, lb2.y, lb2.z,
      rt2.x, rt2.y, rt2.z,
      rb2.x, rb2.y, rb2.z,

      lb2.x, lb2.y, lb2.z,
      lt2.x, lt2.y, lt2.z,
      rt2.x, rt2.y, rt2.z,

    ]);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const edges = new THREE.EdgesGeometry(geometry);
    const openWebMesh = new THREE.Mesh(openWeb, material);
    openWebMesh.layers.set(8);
    openWebMesh.forRayTracing = true;
    openWebMesh.allData = member;
    openWebMesh.userData = {
        GUID: member.GUID,
        idNumber: member.$id,
        Description: "OpenJoist",
        toScreen: member.BeamType,
        level: member.LevelName,
        material: member.MaterialName,
        supplier: member.Supplier,
        width: member.Width,
        depth: member.Depth,
        color: openWebColor,
        normalColor: color,
        canChangeColor: true,
        plyCount: member.PlyCount,
        tapeMeasure: true,
        edges: edges,
        ends: [ endPoints1, endPoints2 ],
        points: points,
        middle: middlePoints,
        layer: 8,
    }
    const width = isMetric ? ((member.Width * 25.4).toFixed(2)).replace(/.00$/, '') + " mm" : member.Width + " in";

    const depth = isMetric ? ((member.Depth * 25.4).toFixed(2)).replace(/.00$/, '') + " mm" : member.Depth + " in";

    const elevationInInches = py1.toFixed(2) + " in";
    const elevationInMillimeters = ((py1 * 25.4).toFixed(2)).replace(/.00$/, '') + " mm";
    const elevation = isMetric ? elevationInMillimeters : elevationInInches;
    const lengthInInches = maxDistance.toFixed(2).replace(/\.?0+$/, '');
    const length = isMetric ? ((maxDistance * 25.4).toFixed(2)).replace(/\.?0+$/, '') + " mm" : lengthInInches + " in";
    openWebMesh.displayWindowInfo = {
        Type: member.BeamType,
        Level: member.LevelName,
        Material: member.MaterialName,
        Supplier: member.Supplier,
        // Guid: member.GUID,
        Length: length,
        Width: width,
        Depth: depth,
        Elevation: elevation,
    };
    if(member.PlacementLabel){
        openWebMesh.displayWindowInfo.Label = member.PlacementLabel;
    }
    if(member.HangerModel1){
        let hangerType = member.HangerModel1.split(' ')[0];
        hangerType = hangerType.replace('(Min)', '');
        hangerType = hangerType.replace('/Min', '');
        hangerType = hangerType.replace('Min', '');
        hangerType = hangerType.replace('(Max)', '');
        hangerType = hangerType.replace('/Max', '');
        hangerType = hangerType.replace('Max', '');
        hangerType = hangerType.trim();
        hangerType = hangerType.replace('R/L', 'L');
        hangerType = hangerType.replace('LZ_RZ', 'LZ');
        hangerType = hangerType.replace('/', '_');
        hangerType += ".stl";
        hangerNames.add(hangerType);
    }
    if(member.HangerModel2){
        let hangerType = member.HangerModel2.split(' ')[0];
        hangerType = hangerType.replace('(Min)', '');
        hangerType = hangerType.replace('/Min', '');
        hangerType = hangerType.replace('Min', '');
        hangerType = hangerType.replace('(Max)', '');
        hangerType = hangerType.replace('/Max', '');
        hangerType = hangerType.replace('Max', '');
        hangerType = hangerType.trim();
        hangerType = hangerType.replace('R/L', 'R');
        hangerType = hangerType.replace('LZ_RZ', 'RZ');
        hangerType = hangerType.replace('/', '_');
        hangerType += ".stl";
        hangerNames.add(hangerType);
    }

    if(member.HangerLeftSide){
        openWebMesh.hangerInfoLeft = {
            member: member,
            vec : vec,
            px1 : px1,
            py1 : py1,
            pz1 : pz1,
            type: "OpenJoist",
            layerNumber: 8,
            rb1 : rb1,
            lb1 : lb1,
            average: average,
            group : group,
            version : version,
        }
    }
    if(member.HangerRightSide){
        openWebMesh.hangerInfoRight = {
            member: member,
            vec : vec,
            px2 : px2,
            py2 : py2,
            pz2 : pz2,
            type: "OpenJoist",
            layerNumber: 8,
            rb2 : rb2,
            lb2 : lb2,
            average: average,
            group : group,
            version : version,
        }
    }
    if(objectOpacity.transparent){
      openWebMesh.material.transparent = true;
      openWebMesh.material.opacity = objectOpacity.opacity;
  }
    group.add(openWebMesh);
    if(member.ZClip1Positions.length){
        if(member.ZClip1Positions[0].length){
          let mesh1 = generateZClip(member.ZClip1Positions[0],member.ZClip1TriangleIndices[0],average)
          let mesh2 = generateZClip(member.ZClip2Positions[0],member.ZClip2TriangleIndices[0],average)
          mesh1.updateMatrix();
          mesh2.updateMatrix();
  
          var geo1 = mesh1.geometry.clone().applyMatrix4(mesh1.matrix);
          var geo2 = mesh2.geometry.clone().applyMatrix4(mesh2.matrix);
          var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
          var mater = new THREE.MeshBasicMaterial({color: 0x808080});
          const mesh = new THREE.Mesh( mergedGeometry, mater );
          mesh.forRayTracing = true;
          mesh.layers.set( 8 );
          mesh.userData = {
            type: "zClip",
            Description: "OpenJoist",
            level: member.LevelName,
            GUID: member.GUID,
            idNumber: member.$id,
          };
          mesh.displayWindowInfo= {
            Type: "Z Clip",
            Level: member.LevelName,
          }
        group.add( mesh );
        }
    }
    if(member.GussetInfo){
        let meshG = generateGussets(member.GussetInfo, member.GussetPositions, member.GussetTriangleIndices, average)
        meshG.forRayTracing = false;
        meshG.layers.set( 8 );
        meshG.userData = {
          type: "gusset",
          Description: "OpenJoist",
          level: member.LevelName,
          GUID: member.GUID,
          idNumber: member.$id,
        };
        group.add( meshG );
    }
    if(member.ScabInfo){
        let meshS = generateScabs(member.ScabInfo, member.ScabPositions, member.ScabTriangleIndices, average)
        meshS.forRayTracing = false;
        meshS.layers.set( 8 );
        meshS.userData = {
          type: "scab",
          Description: "OpenJoist",
          level: member.LevelName,
          GUID: member.GUID,
          idNumber: member.$id,
        };
        group.add( meshS );
    }
}
export {generateGatorJoist}
import { STLLoader } from '../imports/STLLoader.js';
import { generateHangerLeft, generateHangerRight } from '@/ObjectCreateFolders/helperFiles/hangers.js';
async function loadHangers(geometryCache, didntWork, hangerNames, version, lowPerformance, group){
    const loader = new STLLoader();
    let promises = [];
    async function loadStlFile(hangerType, loader, version) {
        // If version 2
        if (version === 2) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    let address = window.location.hostname;
                    let port = process.env.NODE_ENV === 'production' ? '' : ':4000';
                    address =window.location.protocol + '//' + address + port + '/stl/';
                    const response = await fetch(address + hangerType);
                    if (response.ok) {
                        // Read the binary data from the response
                        const stlData = await response.arrayBuffer();
    
                        // Create a File from the binary data
                        const stlFile = new File([stlData], `${hangerType}`, { type: 'application/octet-stream' });
    
                        // Create a URL for the File
                        const stlUrl = URL.createObjectURL(stlFile);
    
                        // Load the .stl file using the loader
                        loader.load(stlUrl, function (geometry1) {
                            geometryCache[hangerType] = geometry1;
                            resolve();  // Resolve the promise once the geometry is loaded
                        });
                    } else {
                        didntWork[hangerType] = true;
                        resolve();  // Resolve the promise even if the fetch was unsuccessful
                    }
                } catch (error) {
                    console.error(`Error fetching .stl file: ${error}`);
                    reject(error);
                }
            });
        } 
        // If version 0
        else if (version === 0) {
            const hangerData = await getHanger(hangerType);
            if (hangerData.buffer) {
                const arrayBuffer = hangerData.buffer.slice(hangerData.byteOffset, hangerData.byteOffset + hangerData.byteLength);
                let geometry = loader.parse(arrayBuffer);
                // add to geometry cache 
                geometryCache[hangerType] = geometry;
            }
            else {
                // add to didn't work cache
                didntWork[hangerType] = true;
            }
        }
    }

    // For each element in the set
    for (let item of hangerNames) {
        promises.push(loadStlFile(item, loader, version));
    }

    // Wait for all promises to resolve before moving on
    await Promise.all(promises);
    // go through each member in the groupo 
    // if it has a hanger info left or right
    // generate the hanger
    // if it has a hanger info left and right
    group.traverse((element) => {
        if(element.hangerInfoLeft){
            generateHangerLeft(
                element.hangerInfoLeft.member,
                element.hangerInfoLeft.vec,
                element.hangerInfoLeft.px1,
                element.hangerInfoLeft.py1,
                element.hangerInfoLeft.pz1,
                element.hangerInfoLeft.type,
                element.hangerInfoLeft.layerNumber,
                element.hangerInfoLeft.rb1,
                element.hangerInfoLeft.lb1,
                element.hangerInfoLeft.average,
                element.hangerInfoLeft.group,
                element.hangerInfoLeft.version,
                geometryCache,
                didntWork,
                lowPerformance
            );
        }
        if(element.hangerInfoRight){
            generateHangerRight(
                element.hangerInfoRight.member,
                element.hangerInfoRight.vec,
                element.hangerInfoRight.px2,
                element.hangerInfoRight.py2,
                element.hangerInfoRight.pz2,
                element.hangerInfoRight.type,
                element.hangerInfoRight.layerNumber,
                element.hangerInfoRight.rb2,
                element.hangerInfoRight.lb2,
                element.hangerInfoRight.average,
                element.hangerInfoRight.group,
                element.hangerInfoRight.version,
                geometryCache,
                didntWork,
                lowPerformance
            );
        }
    });
}
async function getHanger(hangerType) {
    try {
        const hangerData = await window.api.invoke('getHanger', hangerType);
        return hangerData;
    } catch (err) {
        console.error('Error in getHanger:', err);
        throw err;
    }
}
export { loadHangers } ;
/* eslint-disable */ 
import * as THREE from 'three';
import * as earcut from 'earcut';
function generateRoof(roofData, average, group, bbox, objectOpacity) {
    if (roofData.Bounds3D.length > 0) {
        const verticesTop = [];
        const verticesBottom = [];
        const vertices2D = [];
        let changed = false;
        // Add vertices from Bounds3D points
        for (let j = 0; j < roofData.Bounds3D.length; j++) {
            const x = parseFloat(roofData.Bounds3D[j].split(",")[0]) - average.x;
            const yTop = parseFloat(roofData.Bounds3D[j].split(",")[2]) + 2.3;
            const yBottom = parseFloat(roofData.Bounds3D[j].split(",")[2]);
            const z = parseFloat(roofData.Bounds3D[j].split(",")[1]) - average.z;
            
            // update the minX, minY, minZ, maxX, maxY, maxZ
            if(bbox){
                bbox.minX = Math.min(bbox.minX, x);
                bbox.maxX = Math.max(bbox.maxX, x);
                
                bbox.minY = Math.min(bbox.minY, yBottom, yTop);
                bbox.maxY = Math.max(bbox.maxY, yBottom, yTop);
                
                bbox.minZ = Math.min(bbox.minZ, z);
                bbox.maxZ = Math.max(bbox.maxZ, z);
            }
            verticesTop.push(x, yTop, z);
            verticesBottom.push(x, yBottom, z);
            vertices2D.push(x, z);
        }
        const pointsArray = [];

        // Iterate through verticesTop
        for (let i = 0; i < verticesTop.length; i += 3) {
            const pointTop = new THREE.Vector3(verticesTop[i], verticesTop[i + 1], verticesTop[i + 2]);
            pointsArray.push(pointTop);
        }

        // Iterate through verticesBottom
        for (let i = 0; i < verticesBottom.length; i += 3) {
            const pointBottom = new THREE.Vector3(verticesBottom[i], verticesBottom[i + 1], verticesBottom[i + 2]);
            pointsArray.push(pointBottom);
        }

        // Add bottom vertices to top vertices array
        const vertices = verticesTop.concat(verticesBottom);
        
        // Triangulate the geometry for the roof top and bottom
        const trianglesTop = earcut(vertices2D);
        const trianglesBottom = earcut(vertices2D).map(v => v + verticesTop.length / 3); 

        // Triangulate the geometry for the sides
        const trianglesSides = [];
        const vertexCount = roofData.Bounds3D.length;
        for (let i = 0; i < vertexCount - 1; i++) {
            trianglesSides.push(i, i + 1, i + vertexCount);
            trianglesSides.push(i + vertexCount, i + 1, i + 1 + vertexCount);
        }
        // Close the loop
        trianglesSides.push(vertexCount - 1, 0, vertexCount - 1 + vertexCount);
        trianglesSides.push(vertexCount - 1 + vertexCount, 0, 0 + vertexCount);

        // Combine all triangles
        const triangles = trianglesTop.concat(trianglesBottom, trianglesSides);
        
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        geometry.setIndex(triangles);
        
        // Calculate face normals
        geometry.computeVertexNormals();
        const edges = new THREE.EdgesGeometry(geometry);

        const material = new THREE.MeshStandardMaterial({ color: 0x60BBAF, side: THREE.DoubleSide });
        const roof = new THREE.Mesh(geometry, material);
        roof.layers.set(1);
        roof.forRayTracing = true;
        const roofSlope = roofData.FractionSlope ? roofData.FractionSlope : roofData.Slope.toFixed(2);
        roof.userData = {
            GUID: roofData.GUID,
            idNumber: roofData.$id,
            Description: "Roof",
            toScreen: "Roof",
            level: roofData.LevelName,
            slope: roofSlope,
            color: 0x60BBAF,
            normalColor: 0x60BBAF,
            canChangeColor: false,
            edges: edges,
            points: pointsArray,
            joistsUnder: roofData.JoistsUnderPlanePlumbDepth,
            tapeMeasure: true,
            layer: 1,
        };
        roof.displayWindowInfo = {
            Type: "Roof",
            // ID: roofData.GUID,
            Level: roofData.LevelName,
            Slope: roofSlope,
            // Can you format roofData.JoinsUnderPlanePlumbDepth to 2 decimal places?
            JoistsUnder: roofData.JoistsUnderPlanePlumbDepth.toFixed(2),
        };
        if(objectOpacity.transparent){
            roof.material.transparent = true;
            roof.material.opacity = objectOpacity.value;
        }
        group.add(roof);
    }
}
export { generateRoof };


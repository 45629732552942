import * as THREE from 'three';
import { mergeBufferGeometries } from '../../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateZClip(positions,  indices, average){
    let points = [];
    for(let i = 0; i < positions.length; i++){
        let point = {}
        point = {
            x: parseFloat(positions[i].split(",")[0]) * 20 - average.x,
            y: parseFloat(positions[i].split(",")[1]) * 20,
            z: parseFloat(positions[i].split(",")[2]) * 20 - average.z,
        }
        points.push(point);
    }
    let face;
    face = null;
    for(let i = 0; i < indices.length; i+=3){
        let point1 = points[indices[i]];
        let point2 = points[indices[i+1]];
        let point3 = points[indices[i+2]];
        let pp1 = new THREE.Vector3(point1.x, point1.y, point1.z);
        let pp2 = new THREE.Vector3(point2.x, point2.y, point2.z);
        let pp3 = new THREE.Vector3(point3.x, point3.y, point3.z);
        const vertices = new Float32Array( [
            pp1.x, pp1.y, pp1.z,
            pp2.x, pp2.y, pp2.z,
            pp3.x, pp3.y, pp3.z
        ] );
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute( 'position', new THREE.BufferAttribute( vertices, 3 ) );
        if(face == null){
            face = geometry;
        }else{
            face = mergeBufferGeometries([face, geometry]);
        }
    }
    const material = new THREE.MeshBasicMaterial( { color: 0x808080 } );
    const mesh = new THREE.Mesh( face, material );
    return mesh;
}
export { generateZClip };
import * as THREE from 'three';
function finishInit(bbox, average, controls, camera, raycaster, items){
    const newbbminx = bbox.minX - average.x;
    const newbbminz = bbox.minZ - average.z;
    const newbbmaxx = bbox.maxX - average.x;
    const newbbmaxz = bbox.maxZ - average.z;
    const boundingbox = new THREE.BoxGeometry(newbbmaxx - newbbminx, bbox.maxY - bbox.minY, newbbmaxz - newbbminz);
    const boundingboxmesh = new THREE.Mesh(boundingbox, new THREE.MeshBasicMaterial({color: 0x000000, wireframe: true}));
    boundingboxmesh.position.set(0, (bbox.minY + bbox.maxY) / 2, 0);
    boundingboxmesh.layers.set(1);
    boundingboxmesh.Description = "Bounding Box";
    let bb = new THREE.Box3().setFromObject(boundingboxmesh);
    if(newbbmaxx > newbbmaxz){
        const value = newbbmaxx / newbbmaxz;
        camera.position.set(newbbmaxx / value, bbox.maxY * 2, newbbmaxz * value);}
    else{
        const value = newbbmaxz / newbbmaxx;
        camera.position.set(newbbmaxx * value, bbox.maxY * 2, newbbmaxz / value);}
    bb.getCenter(controls.target);
    for (const [, value] of Object.entries(items)) {
        if (!value.visibility) {
            raycaster.layers.disable(value.layer);
            camera.layers.disable(value.layer);
        }
    }
}
export { finishInit };
/* eslint-disable */ 
import * as THREE from 'three';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';
function generateSquashBlock(block, average, group, objectOpacity){
    let rb1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][0].split(",")[0]), parseFloat(block.ConstructorPoints[0][0].split(",")[2]), parseFloat(block.ConstructorPoints[0][0].split(",")[1]));
    let rt1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][1].split(",")[0]), parseFloat(block.ConstructorPoints[0][1].split(",")[2]), parseFloat(block.ConstructorPoints[0][1].split(",")[1]));
    let lb1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][2].split(",")[0]), parseFloat(block.ConstructorPoints[0][2].split(",")[2]), parseFloat(block.ConstructorPoints[0][2].split(",")[1]));
    let lt1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][3].split(",")[0]), parseFloat(block.ConstructorPoints[0][3].split(",")[2]), parseFloat(block.ConstructorPoints[0][3].split(",")[1]));
    let lb2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][4].split(",")[0]), parseFloat(block.ConstructorPoints[0][4].split(",")[2]), parseFloat(block.ConstructorPoints[0][4].split(",")[1]));
    let lt2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][5].split(",")[0]), parseFloat(block.ConstructorPoints[0][5].split(",")[2]), parseFloat(block.ConstructorPoints[0][5].split(",")[1]));
    let rb2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][6].split(",")[0]), parseFloat(block.ConstructorPoints[0][6].split(",")[2]), parseFloat(block.ConstructorPoints[0][6].split(",")[1]));
    let rt2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[0][7].split(",")[0]), parseFloat(block.ConstructorPoints[0][7].split(",")[2]), parseFloat(block.ConstructorPoints[0][7].split(",")[1]));
    let vertices = new Float32Array([
      //right side of cube
      rt1.x, rt1.y, rt1.z,
      rb2.x, rb2.y, rb2.z,
      rb1.x, rb1.y, rb1.z,
  
      rt1.x, rt1.y, rt1.z,
      rt2.x, rt2.y, rt2.z,
      rb2.x, rb2.y, rb2.z,
  
      // //left side of cube
  
      lb1.x, lb1.y, lb1.z,
      lb2.x, lb2.y, lb2.z,
      lt1.x, lt1.y, lt1.z,
  
      lb2.x, lb2.y, lb2.z,
      lt2.x, lt2.y, lt2.z,
      lt1.x, lt1.y, lt1.z,
  
      // //bottom of cube
      rb1.x, rb1.y, rb1.z,
      rb2.x, rb2.y, rb2.z,
      lb1.x, lb1.y, lb1.z,
  
      rb2.x, rb2.y, rb2.z,
      lb2.x, lb2.y, lb2.z,
      lb1.x, lb1.y, lb1.z,
  
      // //top of cube
      lt1.x, lt1.y, lt1.z,
      rt2.x, rt2.y, rt2.z,
      rt1.x, rt1.y, rt1.z,
  
      lt1.x, lt1.y, lt1.z,
      lt2.x, lt2.y, lt2.z,
      rt2.x, rt2.y, rt2.z,
  
      // //front of cube
      lb1.x, lb1.y, lb1.z,
      rt1.x, rt1.y, rt1.z,
      rb1.x, rb1.y, rb1.z,
  
      lb1.x, lb1.y, lb1.z,
      lt1.x, lt1.y, lt1.z,
      rt1.x, rt1.y, rt1.z,
  
      // //back of cube
      rb2.x, rb2.y, rb2.z,
      rt2.x, rt2.y, rt2.z,
      lb2.x, lb2.y, lb2.z,
  
      rt2.x, rt2.y, rt2.z,
      lt2.x, lt2.y, lt2.z,
      lb2.x, lb2.y, lb2.z,
  
    ]);
    const color = 0xe2d9c7
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const smaterial = new THREE.MeshStandardMaterial({color: color, side: THREE.DoubleSide, flatShading: true});
    const squashMesh = new THREE.Mesh(geometry, smaterial);
    squashMesh.position.set(-average.x, 0, -average.z);
    rb1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][0].split(",")[0]), parseFloat(block.ConstructorPoints[1][0].split(",")[2]), parseFloat(block.ConstructorPoints[1][0].split(",")[1]));
    rt1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][1].split(",")[0]), parseFloat(block.ConstructorPoints[1][1].split(",")[2]), parseFloat(block.ConstructorPoints[1][1].split(",")[1]));
    lb1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][2].split(",")[0]), parseFloat(block.ConstructorPoints[1][2].split(",")[2]), parseFloat(block.ConstructorPoints[1][2].split(",")[1]));
    lt1 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][3].split(",")[0]), parseFloat(block.ConstructorPoints[1][3].split(",")[2]), parseFloat(block.ConstructorPoints[1][3].split(",")[1]));
    lb2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][4].split(",")[0]), parseFloat(block.ConstructorPoints[1][4].split(",")[2]), parseFloat(block.ConstructorPoints[1][4].split(",")[1]));
    lt2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][5].split(",")[0]), parseFloat(block.ConstructorPoints[1][5].split(",")[2]), parseFloat(block.ConstructorPoints[1][5].split(",")[1]));
    rb2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][6].split(",")[0]), parseFloat(block.ConstructorPoints[1][6].split(",")[2]), parseFloat(block.ConstructorPoints[1][6].split(",")[1]));
    rt2 = new THREE.Vector3(parseFloat(block.ConstructorPoints[1][7].split(",")[0]), parseFloat(block.ConstructorPoints[1][7].split(",")[2]), parseFloat(block.ConstructorPoints[1][7].split(",")[1]));
    vertices = new Float32Array([
      //right side of cube
      rt1.x, rt1.y, rt1.z,
      rb2.x, rb2.y, rb2.z,
      rb1.x, rb1.y, rb1.z,
  
      rt1.x, rt1.y, rt1.z,
      rt2.x, rt2.y, rt2.z,
      rb2.x, rb2.y, rb2.z,
  
      // //left side of cube
  
      lb1.x, lb1.y, lb1.z,
      lb2.x, lb2.y, lb2.z,
      lt1.x, lt1.y, lt1.z,
  
      lb2.x, lb2.y, lb2.z,
      lt2.x, lt2.y, lt2.z,
      lt1.x, lt1.y, lt1.z,
  
      // //bottom of cube
      rb1.x, rb1.y, rb1.z,
      rb2.x, rb2.y, rb2.z,
      lb1.x, lb1.y, lb1.z,
  
      rb2.x, rb2.y, rb2.z,
      lb2.x, lb2.y, lb2.z,
      lb1.x, lb1.y, lb1.z,
  
      // //top of cube
      lt1.x, lt1.y, lt1.z,
      rt2.x, rt2.y, rt2.z,
      rt1.x, rt1.y, rt1.z,
  
      lt1.x, lt1.y, lt1.z,
      lt2.x, lt2.y, lt2.z,
      rt2.x, rt2.y, rt2.z,
  
      // //front of cube
      lb1.x, lb1.y, lb1.z,
      rt1.x, rt1.y, rt1.z,
      rb1.x, rb1.y, rb1.z,
  
      lb1.x, lb1.y, lb1.z,
      lt1.x, lt1.y, lt1.z,
      rt1.x, rt1.y, rt1.z,
  
      // //back of cube
      rb2.x, rb2.y, rb2.z,
      rt2.x, rt2.y, rt2.z,
      lb2.x, lb2.y, lb2.z,
  
      rt2.x, rt2.y, rt2.z,
      lt2.x, lt2.y, lt2.z,
      lb2.x, lb2.y, lb2.z,
  
    ]);
    const geometry2 = new THREE.BufferGeometry();
    geometry2.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const smaterial2 = new THREE.MeshStandardMaterial({color: color, side: THREE.DoubleSide, flatShading: true});
    const squashMesh2 = new THREE.Mesh(geometry2, smaterial2);
    squashMesh2.position.set(-average.x, 0, -average.z);


    squashMesh2.updateMatrix();
    squashMesh.updateMatrix();

    var geo1 = squashMesh2.geometry.clone().applyMatrix4(squashMesh2.matrix);
    var geo2 = squashMesh.geometry.clone().applyMatrix4(squashMesh.matrix);
    var mergedGeometry = mergeBufferGeometries([geo1, geo2]);
    var material = new THREE.MeshStandardMaterial({color: color, side: THREE.DoubleSide, flatShading: true});
    var meshH = new THREE.Mesh( mergedGeometry, material );
    meshH.forRayTracing = true;
    meshH.layers.set(12);
    meshH.userData = {
      idNumber: block.$id,
      GUID: block.UndoGuid,
      Height: block.Height,
      Description: "SquashBlockLine",
      color: 0xE2D9C7,
      normalColor: 0xE2D9C7,
      level: block.Level,
    }
    meshH.displayWindowInfo = {
      Description: "SquashBlock",
      level: block.Level,
    }
    if(objectOpacity.transparent){
      meshH.material.transparent = true;
      meshH.material.opacity = objectOpacity.opacity;
    }
    group.add(meshH);
  
}
export { generateSquashBlock };
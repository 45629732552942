import * as THREE from 'three';
import { mergeBufferGeometries } from '../../node_modules/three/examples/jsm/utils/BufferGeometryUtils.js';

function generateStrongBack(strongBack, average, group, isMetric, objectOpacity){ 
    const extractedPoints = [];
    let lb1 = new THREE.Vector3(parseFloat(strongBack.LeftBottomLine.P1.split(",")[0]), parseFloat(strongBack.LeftBottomLine.P1.split(",")[2]), parseFloat(strongBack.LeftBottomLine.P1.split(",")[1]));
    let lb2 = new THREE.Vector3(parseFloat(strongBack.LeftBottomLine.P2.split(",")[0]), parseFloat(strongBack.LeftBottomLine.P2.split(",")[2]), parseFloat(strongBack.LeftBottomLine.P2.split(",")[1]));
    let rb1 = new THREE.Vector3(parseFloat(strongBack.RightBottomLine.P1.split(",")[0]), parseFloat(strongBack.RightBottomLine.P1.split(",")[2]), parseFloat(strongBack.RightBottomLine.P1.split(",")[1]));
    let rb2 = new THREE.Vector3(parseFloat(strongBack.RightBottomLine.P2.split(",")[0]), parseFloat(strongBack.RightBottomLine.P2.split(",")[2]), parseFloat(strongBack.RightBottomLine.P2.split(",")[1]));
    let lt1 = new THREE.Vector3(parseFloat(strongBack.LeftTopLine.P1.split(",")[0]), parseFloat(strongBack.LeftTopLine.P1.split(",")[2]), parseFloat(strongBack.LeftTopLine.P1.split(",")[1]));
    let lt2 = new THREE.Vector3(parseFloat(strongBack.LeftTopLine.P2.split(",")[0]), parseFloat(strongBack.LeftTopLine.P2.split(",")[2]), parseFloat(strongBack.LeftTopLine.P2.split(",")[1]));
    let rt1 = new THREE.Vector3(parseFloat(strongBack.RightTopLine.P1.split(",")[0]), parseFloat(strongBack.RightTopLine.P1.split(",")[2]), parseFloat(strongBack.RightTopLine.P1.split(",")[1]));
    let rt2 = new THREE.Vector3(parseFloat(strongBack.RightTopLine.P2.split(",")[0]), parseFloat(strongBack.RightTopLine.P2.split(",")[2]), parseFloat(strongBack.RightTopLine.P2.split(",")[1]));
    const vertices = new Float32Array([
      //right side of cube
      rb1.x, rb1.y, rb1.z,
      rb2.x, rb2.y, rb2.z,
      rt1.x, rt1.y, rt1.z,
  
      rb2.x, rb2.y, rb2.z,
      rt2.x, rt2.y, rt2.z,
      rt1.x, rt1.y, rt1.z,
  
      //left side of cube
  
      lt1.x, lt1.y, lt1.z,
      lb2.x, lb2.y, lb2.z,
      lb1.x, lb1.y, lb1.z,
  
      lt1.x, lt1.y, lt1.z,
      lt2.x, lt2.y, lt2.z,
      lb2.x, lb2.y, lb2.z,
  
      //bottom of cube
      lb1.x, lb1.y, lb1.z,
      rb2.x, rb2.y, rb2.z,
      rb1.x, rb1.y, rb1.z,
  
      lb1.x, lb1.y, lb1.z,
      lb2.x, lb2.y, lb2.z,
      rb2.x, rb2.y, rb2.z,
  
      //top of cube
      rt1.x, rt1.y, rt1.z,
      rt2.x, rt2.y, rt2.z,
      lt1.x, lt1.y, lt1.z,
  
      rt2.x, rt2.y, rt2.z,
      lt2.x, lt2.y, lt2.z,
      lt1.x, lt1.y, lt1.z,
  
      //front of cube
      rb1.x, rb1.y, rb1.z,
      rt1.x, rt1.y, rt1.z,
      lb1.x, lb1.y, lb1.z,
  
      rt1.x, rt1.y, rt1.z,
      lt1.x, lt1.y, lt1.z,
      lb1.x, lb1.y, lb1.z,
  
      //back of cube
      lb2.x, lb2.y, lb2.z,
      rt2.x, rt2.y, rt2.z,
      rb2.x, rb2.y, rb2.z,
  
      lb2.x, lb2.y, lb2.z,
      lt2.x, lt2.y, lt2.z,
      rt2.x, rt2.y, rt2.z,
  
    ]);
    extractedPoints.push(lb1);
    extractedPoints.push(lb2);
    extractedPoints.push(rb1);
    extractedPoints.push(rb2);
    extractedPoints.push(lt1);
    extractedPoints.push(lt2);
    extractedPoints.push(rt1);
    extractedPoints.push(rt2);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    let geo = geometry;
    for(let i = 0; i < strongBack.BracingStuds.length; i++){
      const bracingStud = strongBack.BracingStuds[i];
      let rt2 = new THREE.Vector3(parseFloat(bracingStud[0].split(",")[0]), parseFloat(bracingStud[0].split(",")[2]), parseFloat(bracingStud[0].split(",")[1]));
      let rt1 = new THREE.Vector3(parseFloat(bracingStud[1].split(",")[0]), parseFloat(bracingStud[1].split(",")[2]), parseFloat(bracingStud[1].split(",")[1]));
      let lt1 = new THREE.Vector3(parseFloat(bracingStud[2].split(",")[0]), parseFloat(bracingStud[2].split(",")[2]), parseFloat(bracingStud[2].split(",")[1]));
      let lt2 = new THREE.Vector3(parseFloat(bracingStud[3].split(",")[0]), parseFloat(bracingStud[3].split(",")[2]), parseFloat(bracingStud[3].split(",")[1]));
      let rb2 = new THREE.Vector3(parseFloat(bracingStud[4].split(",")[0]), parseFloat(bracingStud[4].split(",")[2]), parseFloat(bracingStud[4].split(",")[1]));
      let rb1 = new THREE.Vector3(parseFloat(bracingStud[5].split(",")[0]), parseFloat(bracingStud[5].split(",")[2]), parseFloat(bracingStud[5].split(",")[1]));
      let lb1 = new THREE.Vector3(parseFloat(bracingStud[6].split(",")[0]), parseFloat(bracingStud[6].split(",")[2]), parseFloat(bracingStud[6].split(",")[1]));
      let lb2 = new THREE.Vector3(parseFloat(bracingStud[7].split(",")[0]), parseFloat(bracingStud[7].split(",")[2]), parseFloat(bracingStud[7].split(",")[1]));
      const vertices = new Float32Array([
        //right side of cube
        rb1.x, rb1.y, rb1.z,
        rb2.x, rb2.y, rb2.z,
        rt1.x, rt1.y, rt1.z,
    
        rb2.x, rb2.y, rb2.z,
        rt2.x, rt2.y, rt2.z,
        rt1.x, rt1.y, rt1.z,
    
        //left side of cube
    
        lt1.x, lt1.y, lt1.z,
        lb2.x, lb2.y, lb2.z,
        lb1.x, lb1.y, lb1.z,
    
        lt1.x, lt1.y, lt1.z,
        lt2.x, lt2.y, lt2.z,
        lb2.x, lb2.y, lb2.z,
    
        //bottom of cube
        lb1.x, lb1.y, lb1.z,
        rb2.x, rb2.y, rb2.z,
        rb1.x, rb1.y, rb1.z,
    
        lb1.x, lb1.y, lb1.z,
        lb2.x, lb2.y, lb2.z,
        rb2.x, rb2.y, rb2.z,
    
        //top of cube
        rt1.x, rt1.y, rt1.z,
        rt2.x, rt2.y, rt2.z,
        lt1.x, lt1.y, lt1.z,
    
        rt2.x, rt2.y, rt2.z,
        lt2.x, lt2.y, lt2.z,
        lt1.x, lt1.y, lt1.z,
    
        //front of cube
        rb1.x, rb1.y, rb1.z,
        rt1.x, rt1.y, rt1.z,
        lb1.x, lb1.y, lb1.z,
    
        rt1.x, rt1.y, rt1.z,
        lt1.x, lt1.y, lt1.z,
        lb1.x, lb1.y, lb1.z,
    
        //back of cube
        lb2.x, lb2.y, lb2.z,
        rt2.x, rt2.y, rt2.z,
        rb2.x, rb2.y, rb2.z,
    
        lb2.x, lb2.y, lb2.z,
        lt2.x, lt2.y, lt2.z,
        rt2.x, rt2.y, rt2.z,
    
      ]);
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
      geo = mergeBufferGeometries([geo, geometry]);
      extractedPoints.push(lb1);
      extractedPoints.push(lb2);
      extractedPoints.push(rb1);
      extractedPoints.push(rb2);
      extractedPoints.push(lt1);
      extractedPoints.push(lt2);
      extractedPoints.push(rt1);
      extractedPoints.push(rt2);

    }
    const edges = new THREE.EdgesGeometry(geo);
    const material = new THREE.MeshStandardMaterial({ color: 0x000000, side: THREE.DoubleSide, flatShading: true });
    const cube = new THREE.Mesh(geo, material);
    cube.position.set(-average.x, 0, -average.z);
    cube.allData = strongBack;
    cube.layers.set(15);
    cube.forRayTracing = true;
    cube.userData= {
        Description: "Strongback",
        toScreen: "Strongback",
        GUID: strongBack.UndoGuid,
        idNUmber: strongBack.$id,
        level: strongBack.Level,
        layer: 15,
        color: 0x000000,
        canChangeColor: false,
        normalColor: 0x000000,
        edges: edges,
        tapeMeasure: true,
        points: extractedPoints,
    }
    cube.displayWindowInfo = {
      Type: "Strongback",
      Level: strongBack.Level,
      Elevation: isMetric? ((lb1.y * 25.4).toFixed(2).replace(/\.?0*$/,'')) + " mm": (lb1.y.toFixed(2).replace(/\.?0*$/,'')) + " in",

    }
    if(objectOpacity.transparent){
      cube.material.transparent = true;
      cube.material.opacity = objectOpacity.opacity;
    }
    group.add(cube);
}
export {generateStrongBack}
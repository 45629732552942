import * as THREE from 'three';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { CSS2DRenderer,CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

export default class dimensionTool {
    constructor(renderer, camera, scene, group, mouse, raycaster, selection, onPointerMove, isMetric, controls, outlinePass, average, dimensionLinesKind, buttonsOn, store, tapeMeasureOn) {
        this.renderer = renderer;
        this.camera = camera;
        this.scene = scene;
        this.group = group;
        this.mouse = mouse;
        this.raycaster = raycaster;
        this.selection = selection;
        this.onPointerMove = onPointerMove;
        this.isMetric = isMetric;
        this.controls = controls;
        this.outlinePass = outlinePass;
        this.average = average;
        this.dimensionLinesKind = dimensionLinesKind;
        this.buttonsOn = buttonsOn;
        this.store = store;
        this.clickDownPoint = new THREE.Vector2();
        this.distanceBetween = 0;

        this.tapeMeasureOn = tapeMeasureOn;

        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize( window.innerWidth, window.innerHeight );
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        this.labelRenderer.domElement.style.pointerEvents = 'none';
        document.body.appendChild( this.labelRenderer.domElement );
        this.labelRenderer.domElement.style.color = 'white';
        this.geometry = new THREE.SphereGeometry( 1 );
        this.material = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
        this.dimensionToolObjects = new THREE.Group();
        const line = this.generateDimensionToolObjectSquare();
        this.dimensionToolObjects.add(line);
        this.stationaryToolObjects = new THREE.Group();
        this.scene.add(this.dimensionToolObjects);
        this.scene.add(this.stationaryToolObjects);
        this.intersectsTape = [];
        this.firstPointClicked = false;
        this.points = [];
        this.planeInvisible;
        this.measurementLabel;
        this.lines = new THREE.Group();
        this.labels = new THREE.Group();
        this.scene.add(this.lines);
        this.scene.add(this.labels);
        this.freeViewTape = false;
        this.firstSeen;
        this.memberBehind;
        this.currentMemberSelected;
        this.firstPointTool = null;
        this.firstLineSelected = null;
        this.secondLineSelected = null;

        this.bindEventListeners();
    }

    bindEventListeners() {
        document.addEventListener('keydown', this.onKeyDown.bind(this));
        this.onPointerMoveTape = this.onPointerMoveTape.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClickDown = this.onClickDown.bind(this);
    }

    onKeyDown(event) {
        if(event.key === 'w') {
            this.handleWKeyPress();
            this.buttonsOn['w'] = !this.buttonsOn['w'];
            this.store.commit('SET_DIMENSION_TOOL_ON', this.buttonsOn['w']);
        }
        else if(event.key === 'e'){
            this.handleEKeyPress();
        }
        else if(event.key === 'Escape' && this.buttonsOn['w']){
            this.handleEscapeKeyPress();
        }

    }
    getLabelRenderer(){
        return this.labelRenderer;
    }
    getLabels(){
        return this.labels;
    }
    getStationaryToolObjects(){
        return this.stationaryToolObjects;
    }
    handleRKeyPress(){
        if(this.tapeMeasureOn.value && !this.freeViewTape){
            // if(this.firstPointClicked){
            this.controls.enabled = true;
            this.renderer.domElement.removeEventListener( 'pointerdown', this.onClick, false);
            this.freeViewTape = true;
            this.dimensionToolObjects.children[0].material.color.setHex(0x000000);
        }
        else if(this.tapeMeasureOn.value && this.freeViewTape){
            this.controls.enabled = false;
            this.renderer.domElement.addEventListener( 'pointerdown', this.onClick, false);
            this.freeViewTape = false;
            this.dimensionToolObjects.children[0].material.color.setHex(0xff0000);
        }
    }
    handleEKeyPress(){
        if(!this.firstPointClicked){
            this.scene.remove(this.lines);
            this.lines = new THREE.Group();
            this.scene.add(this.lines);
            this.scene.remove(this.labels);
            for (let i = 0; i < this.labels.children.length; i++) {
                this.labels.children[i].element.remove();
            }
            this.labels = new THREE.Group();
            this.scene.add(this.labels);
            while(this.stationaryToolObjects.children.length > 0) {
                this.stationaryToolObjects.remove(this.stationaryToolObjects.children[0]);
            }
        }
    }
    handleEscapeKeyPress(){
        if(this.tapeMeasureOn.value){
            for (let i = this.lines.children.length - 1; i >= 0; i--) {
                const child = this.lines.children[i];
                if (child.isTemp) {
                    this.lines.remove(child); // Remove the child from the group.
                }
            }
            for(let i = this.labels.children.length - 1; i >= 0; i--){
                const child = this.labels.children[i];
                if(child.isTemp){
                    this.labels.remove(child);
                }
            }
            if(this.firstPointClicked){
                this.firstPointClicked = false;
                this.stationaryToolObjects.children.pop();
            }
            this.firstPointClicked = false;
        }
    }
    handleWKeyPress() {
        if(!this.tapeMeasureOn.value){
            this.tapeMeasureOn.value = true;
            this.renderer.domElement.style.cursor = 'crosshair';
            this.renderer.domElement.removeEventListener('pointermove', this.onPointerMove);
            this.renderer.domElement.addEventListener('pointerdown', this.onClickDown, false);
            this.renderer.domElement.addEventListener('mousemove', this.onPointerMoveTape, false);
            this.outlinePass.selectedObjects = [];
            this.raycaster.setFromCamera( this.mouse, this.camera );
            this.selection.on = false;
            this.dimensionToolObjects.children[0].material.color.setHex(0xff0000);
            this.intersectsTape = this.raycaster.intersectObjects( this.group.children, false );
            if(this.intersectsTape.length > 0){
              if(this.intersectsTape[0].object.userData.tapeMeasure && this.intersectsTape[0].object.material.visible){
                if(this.dimensionToolObjects.children.length > 0) {
                    let child = this.dimensionToolObjects.children[0];
                    child.position.copy(this.intersectsTape[0].point);
                    child.visible = true;
                }
              }
            }
        }
        else{
            for (let i = this.lines.children.length - 1; i >= 0; i--) {
                const child = this.lines.children[i];
                if (child.isTemp) {
                  this.lines.remove(child); // Remove the child from the group.
                }
            }
            for(let i = this.labels.children.length - 1; i >= 0; i--){
                const child = this.labels.children[i];
                if(child.isTemp){
                    this.labels.remove(child);
                }
            }
            this.tapeMeasureOn.value = false;
            if(this.firstPointClicked){
                this.firstPointClicked = false;
                this.stationaryToolObjects.children.pop();
            }
            this.firstPointClicked = false;
            this.controls.enabled = true;
            this.selection.on = true;
            this.freeViewTape = false;
            this.renderer.domElement.style.cursor = 'default';
            this.renderer.domElement.addEventListener('pointermove', this.onPointerMove);
            this.renderer.domElement.removeEventListener('pointerdown', this.onClickDown, false);
            this.renderer.domElement.removeEventListener('mousemove', this.onPointerMoveTape, false);

            this.group.remove(this.planeInvisible);
            this.points = [];
            this.dimensionToolObjects.children[0].visible = false;    
        }
    }
    onClickDown(event) {
        if(this.tapeMeasureOn.value && event.button === 0){
            event.preventDefault();
            this.clickDownPoint.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            this.clickDownPoint.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
            this.renderer.domElement.addEventListener('pointerup', this.onClick, false);
        }
    }
            
    onClick(event) {
        let clickedUpPoint = new THREE.Vector2();
        clickedUpPoint.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        clickedUpPoint.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        this.distanceBetween = this.clickDownPoint.distanceTo(clickedUpPoint);
        if(this.tapeMeasureOn.value && event.button === 0 && this.distanceBetween < 0.01){
            event.preventDefault();
            this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
            this.raycaster.setFromCamera( this.mouse, this.camera );
            this.memberBehind = null;
            if(this.currentMemberSelected){
              if(!this.firstPointClicked){
                this.memberBehind = this.currentMemberSelected.allData;
                this.firstPointClicked = true;
                this.firstPointObject = this.currentMemberSelected;
                let objectToClone = this.dimensionToolObjects.children[0];
                let clonedObject = objectToClone.clone(true); 
                clonedObject.geometry = objectToClone.geometry.clone();
                clonedObject.material = objectToClone.material.clone();
                this.stationaryToolObjects.add(clonedObject);
                this.firstPointTool = clonedObject;
                this.points.push(this.firstPointTool.position);
  
              }
              else{
                    let objectToClone = this.dimensionToolObjects.children[0];
                    let clonedObject = objectToClone.clone(true); 
                    clonedObject.geometry = objectToClone.geometry.clone();
                    clonedObject.material = objectToClone.material.clone();
                    this.stationaryToolObjects.add(clonedObject);
                    for (let i = this.lines.children.length - 1; i >= 0; i--) {
                        const child = this.lines.children[i];
                        // Check if the child is a line and has isTemp set to true.
                        if (child.isTemp) {
                            child.isTemp = false;
                        }
                    }
                    for(let i = this.labels.children.length - 1; i >= 0; i--){
                        const child = this.labels.children[i];
                        if(child.isTemp){
                            child.isTemp = false;
                            child.element.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                            child.element.style.color = 'white';
                        }
                    }
                    this.memberBehind = null;
                    this.firstPointObject = null;
                    this.firstPointClicked = false;
                    this.firstPointTool = null;
                    this.firstLineSelected = null;
                    this.secondLineSelected = null;
                    this.firstPointSelected = null;
                    this.secondPointSelected = null;
                }
            }
        }
        this.renderer.domElement.removeEventListener('pointerup', this.onClick, false);
    }
    onPointerMoveTape(event) {
        event.preventDefault();
        this.isParallel = false;
        this.memberBehindParallel = false;
        let edgesLinesGroup = new THREE.Group();
        let edgesPointsGroup = new THREE.Group();
        this.currentMemberSelected = null;
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        this.raycaster.setFromCamera( this.mouse, this.camera );
        let generateLinesFor = null
        const intersectsTapeTemp = this.raycaster.intersectObjects( this.group.children, false );
        const pointOnMember = new THREE.Vector3();
        for(let i = 0; i < intersectsTapeTemp.length; i++){
            if(intersectsTapeTemp[i].object.userData.tapeMeasure && intersectsTapeTemp[i].object.material.visible){
                generateLinesFor = intersectsTapeTemp[i].object;
                this.currentMemberSelected = intersectsTapeTemp[i].object;
                pointOnMember.copy(intersectsTapeTemp[i].point);
                break;
            }
        }
        if(generateLinesFor){
            if(this.dimensionLinesKind['allEdges']){
                // console.log(generateLinesFor.userData.edges)
                for(let i = 0; i < generateLinesFor.userData.edges.attributes.position.array.length; i+=6){
                    const lineStart = new THREE.Vector3(generateLinesFor.userData.edges.attributes.position.array[i] - this.average.x, generateLinesFor.userData.edges.attributes.position.array[i+1], generateLinesFor.userData.edges.attributes.position.array[i+2] - this.average.z);
                    const lineEnd = new THREE.Vector3(generateLinesFor.userData.edges.attributes.position.array[i+3] - this.average.x, generateLinesFor.userData.edges.attributes.position.array[i+4], generateLinesFor.userData.edges.attributes.position.array[i+5] - this.average.z);
                    const lineBefore = new THREE.Line3(lineStart, lineEnd);
                    const line = new THREE.LineSegments( new THREE.BufferGeometry().setFromPoints( [ lineBefore.start, lineBefore.end ] ), new THREE.LineBasicMaterial( { color: 0x000000 } ) );
                    line.layers.enableAll();
                    line.forRayTracing = false;
                    line.userData = {
                        Description: generateLinesFor.userData.Description,
                        // toScreen: generateLinesFor.userData.toScreen,
                        level: generateLinesFor.userData.level,
                        tapeMeasureLine: true,
                        // memberID: generateLinesFor.userData.memberID,
                        memberID: generateLinesFor.userData.GUID,
                        allData: generateLinesFor.allData,
                        edges: generateLinesFor.userData.edges,
                    }
                    edgesLinesGroup.add(line);
                }
                if(generateLinesFor.userData.middle){
                    for (let j = 0; j < generateLinesFor.userData.middle.length; j++) {
                        // Getting start and end vector for each line
                        const originalLineStart = generateLinesFor.userData.middle[j];
                        const originalLineEnd = generateLinesFor.userData.middle[(j + 1) % generateLinesFor.userData.middle.length]; // Use modulo to connect last vector to the first
                        
                        // Create a copy of the original lineStart and lineEnd
                        const lineStart = originalLineStart.clone();
                        const lineEnd = originalLineEnd.clone();
                        
                        lineStart.x -= this.average.x;
                        lineStart.z -= this.average.z;
                        lineEnd.x -= this.average.x;
                        lineEnd.z -= this.average.z;
                        
                        // Creating the line
                        const line = new THREE.LineSegments(new THREE.BufferGeometry().setFromPoints([lineStart, lineEnd]), new THREE.LineBasicMaterial({ color: 0x000000 }));
                        line.layers.enableAll();
                        line.forRayTracing = false;
                        line.visible = false;
                        line.userData = {
                            Description: generateLinesFor.userData.Description,
                            level: generateLinesFor.userData.level,
                            tapeMeasureLine: true,
                            // memberID: generateLinesFor.userData.memberID,
                            memberID: generateLinesFor.userData.GUID, // Same key is mentioned twice in your example. Correct as needed.
                            allData: generateLinesFor.allData,
                            edges: generateLinesFor.userData.ends,
                        };
                        edgesLinesGroup.add(line);
                    }
                }
            }
            else if(this.dimensionLinesKind['endEdges']){
                for (let i = 0; i < generateLinesFor.userData.ends.length; i++) {
                    const vectorsSet = generateLinesFor.userData.ends[i];
                    
                    // Iterate over the vectors in the set and create lines
                    for (let j = 0; j < vectorsSet.length; j++) {
                        // Getting start and end vector for each line
                        const originalLineStart = vectorsSet[j];
                        const originalLineEnd = vectorsSet[(j + 1) % vectorsSet.length]; // Use modulo to connect last vector to the first
                        
                        // Create a copy of the original lineStart and lineEnd
                        const lineStart = originalLineStart.clone();
                        const lineEnd = originalLineEnd.clone();
                        
                        lineStart.x -= this.average.x;
                        lineStart.z -= this.average.z;
                        lineEnd.x -= this.average.x;
                        lineEnd.z -= this.average.z;
                        
                        // Creating the line
                        const line = new THREE.LineSegments(new THREE.BufferGeometry().setFromPoints([lineStart, lineEnd]), new THREE.LineBasicMaterial({ color: 0x000000 }));
                        line.layers.enableAll();
                        line.forRayTracing = false;
                        line.visible = false;
                        line.userData = {
                            Description: generateLinesFor.userData.Description,
                            level: generateLinesFor.userData.level,
                            tapeMeasureLine: true,
                            // memberID: generateLinesFor.userData.memberID,
                            memberID: generateLinesFor.userData.GUID, // Same key is mentioned twice in your example. Correct as needed.
                            allData: generateLinesFor.allData,
                            edges: generateLinesFor.userData.ends,
                        };
                        edgesLinesGroup.add(line);
                    }
                }
            }
            else if(this.dimensionLinesKind['cornerPoints']){
                for (let i = 0; i < generateLinesFor.userData.points.length; i++) {
                    const originalPoint1 = generateLinesFor.userData.points[i];
                    const point1 = originalPoint1.clone();
                    point1.x -= this.average.x;
                    point1.z -= this.average.z;
                    let object = new THREE.Object3D();
                    object.position.copy(point1);
                    object.layers.enableAll();
                    object.visible = false;
                    edgesPointsGroup.add(object);
                }
                if(generateLinesFor.userData.middle){
                    for(let i = 0; i < generateLinesFor.userData.middle.length; i++){
                        const point = generateLinesFor.userData.middle[i];
                        const point1 = point.clone();
                        point1.x -= this.average.x;
                        point1.z -= this.average.z;
                        let object = new THREE.Object3D();
                        object.position.copy(point1);
                        object.layers.enableAll();
                        object.visible = false;
                        edgesPointsGroup.add(object);
                    }
                }
            }
            this.scene.add(edgesLinesGroup);
            this.scene.add(edgesPointsGroup);
            let closestLine = null;
            let closestDistance = Infinity;
            let closestPointOnLine = new THREE.Vector3();
            let closestPointOnLineFinal = new THREE.Vector3();
            let closestPointFinal = new THREE.Vector3();
            if(edgesPointsGroup.children.length){
                edgesPointsGroup.traverse((child) => {
                    if (child.isObject3D) {
                        const distance = pointOnMember.distanceTo(child.position);
                        if (distance < closestDistance) {
                            closestDistance = distance;
                            closestPointFinal = child.position;
                        }
                    }
                });
            }
            else if(edgesLinesGroup.children.length){
                edgesLinesGroup.traverse((child) => {
                    if (child.isLine) {
                        const start = new THREE.Vector3().fromBufferAttribute(child.geometry.attributes.position, 0);
                        const end = new THREE.Vector3().fromBufferAttribute(child.geometry.attributes.position, 1);
                        // Function to get the closest distance from a point to a line segment.
                        const closestPointOnSegment = (point, lineStart, lineEnd) => {
                            const lineDir = new THREE.Vector3().subVectors(lineEnd, lineStart).normalize();
                            const segmentLength = lineStart.distanceTo(lineEnd);
                            const projectLength = new THREE.Vector3().subVectors(point, lineStart).dot(lineDir);
                            const t = THREE.MathUtils.clamp(projectLength, 0, segmentLength);
                            return new THREE.Vector3().addVectors(lineStart, lineDir.multiplyScalar(t));
                        };
                        closestPointOnLine = closestPointOnSegment(pointOnMember, start, end);
                        const distance = pointOnMember.distanceTo(closestPointOnLine);
                        if (distance < closestDistance) {
                            closestDistance = distance;
                            closestLine = child;
                            closestPointOnLineFinal = closestPointOnLine;
                        }
                    }
                });
            }
            if(closestPointFinal){
                this.firstSeen = closestPointFinal;
                if(!this.firstPointClicked){
                    this.firstPointSelected = closestPointFinal;
                }
                else{
                    this.secondPointSelected = closestPointFinal;
                }
                if(this.dimensionToolObjects.children.length > 0) {
                    let child = this.dimensionToolObjects.children[0];
                    child.position.copy(closestPointFinal);
                    child.visible = true;
                    child.lookAt(this.camera.position);
                }
            }
            if(closestLine) {
                this.firstSeen = closestLine;
            
                if(!this.firstPointClicked) {
                    this.firstLineSelected = closestLine;
                }
                else {
                    this.secondLineSelected = closestLine;
                }
                if(this.dimensionToolObjects.children.length > 0) {
                    let child = this.dimensionToolObjects.children[0];
                    child.position.copy(closestPointOnLineFinal);
                    child.visible = true;
                    child.lookAt(this.camera.position);
                }
            }
            let points = [];
            if(this.firstPointClicked && this.firstPointTool){
                if(edgesLinesGroup.children.length){
                    points.push(closestPointOnLineFinal);
                }
                else if(edgesPointsGroup.children.length){
                    points.push(closestPointFinal);
                }
                points.push(this.firstPointTool.position);
                for (let i = this.lines.children.length - 1; i >= 0; i--) {
                    const child = this.lines.children[i];
                    if (child.isTemp) {
                      this.lines.remove(child);
                    }
                }
                for(let i = this.labels.children.length - 1; i >= 0; i--){
                    const child = this.labels.children[i];
                    if(child.isTemp){
                        this.labels.remove(child);
                    }
                }
            }
            if(points.length > 1){
                const positionsTotalDistance = [points[0].x, points[0].y, points[0].z, points[1].x, points[1].y, points[1].z];
                const positionsHypotenuseFlat = [points[0].x, points[0].y, points[0].z, points[1].x, points[0].y, points[1].z];
                const positionsHorizontalZ = [points[1].x, points[0].y, points[0].z, points[1].x, points[0].y, points[1].z];
                const positionsHorizontalX = [points[0].x, points[0].y, points[0].z, points[1].x, points[0].y, points[0].z];
                const positionsVertical = [points[1].x, points[0].y, points[1].z, points[1].x, points[1].y, points[1].z];
                const distanceTotalDistance = points[0].distanceTo(points[1]);
                const distanceHypotenuseFlat = points[0].distanceTo(new THREE.Vector3(points[1].x, points[0].y, points[1].z));
                const distanceHorizontalZ = new THREE.Vector3(points[1].x, points[0].y, points[0].z).distanceTo(new THREE.Vector3(points[1].x, points[0].y, points[1].z));
                const distanceHorizontalX = points[0].distanceTo(new THREE.Vector3(points[1].x, points[0].y, points[0].z));
                const distanceVertical = new THREE.Vector3(points[1].x, points[0].y, points[1].z).distanceTo(points[1]);
                if(distanceTotalDistance > 0.05){
                    const distanceStringTotalDistance = this.convertToString(distanceTotalDistance);
                    this.createLine(positionsTotalDistance, true, 0x6A5ACD, distanceStringTotalDistance);
                }
                if(distanceHypotenuseFlat > 0.05){
                    const distanceStringHypotenuseFlat = this.convertToString(distanceHypotenuseFlat);
                    this.createLine(positionsHypotenuseFlat, true, 0x556B2F, distanceStringHypotenuseFlat);
                }
                if(distanceHorizontalZ > 0.05){
                    const distanceStringHorizontalZ = this.convertToString(distanceHorizontalZ);
                    this.createLine(positionsHorizontalZ, true, 0x8B4513, distanceStringHorizontalZ);
                }
                if(distanceHorizontalX > 0.05){
                    const distanceStringHorizontalX = this.convertToString(distanceHorizontalX);
                    this.createLine(positionsHorizontalX, true, 0x5F9EA0, distanceStringHorizontalX);
                }
                if(distanceVertical > 0.05){
                    const distanceStringVertical = this.convertToString(distanceVertical);
                    this.createLine(positionsVertical, true, 0xB8860B, distanceStringVertical);
                }

            }

        }
        this.scene.remove(edgesLinesGroup);
        this.scene.remove(edgesPointsGroup);
        this.intersectsTape = [];
    }
    convertToString(distance){
        let distanceString;
        if (!this.isMetric) {
            // Convert the distance to feet, inches, and fractional inches
            const feet = Math.floor(distance / 12);
            let inches = Math.floor(distance % 12);
            let fraction = Math.round((distance % 1) * 16);
        
            // If fraction is 16/16, increase inches and reset fraction
            if (fraction === 16) {
                inches++;
                fraction = 0;
            }
        
            // Initialize distanceString
            distanceString = "";
        
            // Handle feet (only add if greater than zero)
            if (feet > 0) {
                distanceString += feet + "'";
            }
        
            // Handle inches
            // If there's a previous value (like feet), or the inches are non-zero, or there's a fraction to be added.
            if (distanceString.length > 0 || inches > 0 || fraction > 0) {
                if (distanceString.length > 0) { // If there's a previous value (like feet), add a space.
                    distanceString += " ";
                }
                distanceString += inches + '"';
            }
        
            // Handle fractions
            if (fraction > 0) {
                distanceString += " " + fraction + '/16"';
            }
        
            // Edge case: if everything is zero (like a distance of 0)
            if (distanceString.length === 0) {
                distanceString = '0"';
            }
        }
        else{
            distance *= 25.4;
            distanceString = distance.toFixed(2) + " mm";
        }
        return distanceString;
    }
    createLine(positions, isTemp, color, distanceString) {
        if (positions.length % 3 !== 0 || positions.length < 6) {
            console.error('Invalid positions array', positions);
            return;
        }
        const geometry = new LineGeometry();
        geometry.setPositions(positions);
        
        const material = new LineMaterial({
            color: color,
            linewidth: 3,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
            depthTest: false, 
        });
        let textColor = 'white';
        // if(color === 6266528){
        //     textColor = '#5F4E00';
        // } else if(color === 12092939){
        //     textColor = '#B8B83B';
        // } else if(color === 6970061){
        //     textColor = '#6A686D';
        // } else if(color === 5597999){
        //     textColor = '#556177';
        // } else if(color === 9127187){
        //     textColor = '#8B9393';
        // }

        const line = new Line2(geometry, material);
        line.computeLineDistances();
        line.isTemp = isTemp;
        line.layers.enableAll();
        this.lines.add(line);
        const measurementDiv = document.createElement('div');
        measurementDiv.className = 'measurementLabel';
        measurementDiv.style.color = textColor; // Set the color to white or any color of your choice
        measurementDiv.style.fontSize = '16px'; // Set font size to 16px or any size of your choice
        measurementDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'; // Example: semi-transparent black background
        measurementDiv.style.padding = '5px'; // Add some padding
        measurementDiv.style.borderRadius = '5px'; // Optional: for rounded corners
        measurementDiv.style.border = '1px solid #333'; // Optional: for a border
        this.measurementLabel = new CSS2DObject(measurementDiv);
        this.measurementLabel.layers.enableAll();
        this.measurementLabel.isTemp = isTemp;
        this.labels.add(this.measurementLabel);
        this.measurementLabel.element.innerHTML = distanceString;
        const startPoint = new THREE.Vector3(positions[0], positions[1], positions[2]);
        const endPoint = new THREE.Vector3(positions[3], positions[4], positions[5]);
        
        // Lerp between the start and end points
        this.measurementLabel.position.lerpVectors(startPoint, endPoint, 0.5);
        const screenPointLabel = this.toScreenPosition(this.measurementLabel, this.camera, this.renderer);
        const screenPointObject = this.toScreenPosition(this.dimensionToolObjects.children[0], this.camera, this.renderer);
        if(this.isClose(screenPointLabel, screenPointObject)) {
            measurementDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
            measurementDiv.style.color = 'rgba(255, 255, 255, 0.15)';
        }

    }
    generateDimensionToolObjectSquare() {
        const material = new LineMaterial({
            color: 0xff0000,
            linewidth: 3,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
        });
        const geometry = new LineGeometry();
        
        geometry.setPositions([
            -1, -1, 0,
            1, -1, 0,
            1, 1, 0,
            -1, 1, 0,
            -1, -1, 0 
        ]);
        const line = new Line2(geometry, material);
        line.visible = false;
        line.layers.enableAll();
        line.thing = true;
        
        return line;
    }
    generateDimensionToolObjectTriangle() {
        const material = new LineMaterial({
            color: 0xff0000, // Different color to distinguish
            linewidth: 3,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
        });
    
        const geometry = new LineGeometry();
    
        const halfHeight = Math.sqrt(3);
        
        geometry.setPositions([
            -1, -halfHeight/2, 0,
            1, -halfHeight/2, 0,
            0, halfHeight/2, 0,
            -1, -halfHeight/2, 0
        ]);
    
        const line = new Line2(geometry, material);
        line.visible = false;
        line.layers.enableAll();
        line.thing = true;
    
        return line;
    }
    generateDimensionToolObjectCircle() {
        const material = new LineMaterial({
            color: 0xff0000,
            linewidth: 3,
            resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
        });
    
        const geometry = new LineGeometry();
    
        const radius = 1;
        const segments = 64;
        const positions = [];
        
        for (let i = 0; i <= segments; i++) {
            const theta = (i / segments) * Math.PI * 2;
            const x = radius * Math.cos(theta);
            const y = radius * Math.sin(theta);
            positions.push(x, y, 0);
        }
    
        geometry.setPositions(positions);
    
        const line = new Line2(geometry, material);
        line.visible = false;
        line.layers.enableAll();
        line.thing = true;
    
        return line;
    }
    isOverlap(rectA, rectB){
        return (rectA.left <= rectB.right &&
            rectB.left <= rectA.right &&
            rectA.top <= rectB.bottom &&
            rectB.top <= rectA.bottom);
    }
    isClose(pointA, pointB) {
        const dx = pointA.x - pointB.x;
        const dy = pointA.y - pointB.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance <= 35;
    }
    toScreenPosition(obj, camera, renderer) {
        const vector = new THREE.Vector3();

        const widthHalf = 0.5 * renderer.domElement.width;
        const heightHalf = 0.5 * renderer.domElement.height;

        obj.updateMatrixWorld();
        vector.setFromMatrixPosition(obj.matrixWorld);
        vector.project(camera);

        vector.x = (vector.x * widthHalf) + widthHalf;
        vector.y = -(vector.y * heightHalf) + heightHalf;

        return {
            x: vector.x,
            y: vector.y
        };
    }

}
<template>
  <LoadingScreen :msg="loadingMsg" :totalTime="totalTime" :currentTime="currentTime" />
  <GUI v-if="storeReady" msg ="" />
</template>

<script>
  import LoadingScreen from './components/LoadingScreen.vue'
  import GUI from './components/GUI.vue'
  export default {
    name: 'App',
    components: {
      LoadingScreen,
      GUI
    },
    data(){
      return {
        storeReady: false,
        loadingMsg: 'Loading...',
        totalTime: 10000, // Example total time in milliseconds
        currentTime: 5000 // Example current time in milliseconds
      };
    },
    mounted() {
      window.addEventListener('storeReady', () => {
        this.storeReady = true;
      });
    },
  };
</script>

<style>
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  width: 100%;  
  height: 100%; 
  overflow: hidden;
}
</style>

function calling(response, group, highlightedMembers, firstTime, isColor, items, store, camera, raycaster, levelNames, currentTransparencyOutside){
    const rep = response;
    let objects = [];
    let objectClasses = new Set();
    highlightedMembers = [];
    let floor = null;
    for(let i = 0; i < rep.length; i++){
        for(let j = 0; j < group.children.length; j++){
            if(group.children[j].userData.GUID === rep[i] && group.children[j].forRayTracing && group.children[j].userData.Description !== "Hanger" && group.children[j].userData.Description !== "WebHole"){
                objects.push(group.children[j]);
                highlightedMembers.push(group.children[j].userData.GUID);
                objectClasses.add(group.children[j].userData.Description);
                floor = group.children[j].userData.level;
                // break;
            }
        }
    }
    let result;
    if(objects.length === 0){
        objects.push("");
        result = highlightMember(objects, objectClasses, floor, group, firstTime, isColor, items, store, camera, raycaster, levelNames, currentTransparencyOutside);
    }
    else{
        result = highlightMember(objects, objectClasses, floor, group, firstTime, isColor, items, store, camera, raycaster, levelNames, currentTransparencyOutside);
    }
    return result;
}
function highlightMember(objects, objectClasses, floor, group, firstTime, isColor, items, store, camera, raycaster, levelNames, currentTransparencyOutside){
    if(objects[0] === ""){
        if(!firstTime){
            for(let i = 0; i < group.children.length; i++){
                if(group.children[i].material.color.getHex() === 0xe834eb){
                  if(isColor){
                    group.children[i].material.color.set(group.children[i].userData.color);
                  }
                  else{
                    group.children[i].material.color.set(group.children[i].userData.normalColor);
                  }
                }
                for(let key in items){  
                    if(group.children[i].userData.Description.includes(items[key].id)){
                        if(items[key].opacity){
                            group.children[i].material.opacity = 1;
                            group.children[i].material.transparent = false;
                            group.children[i].material.needsUpdate = true;
                        }
                    }
                }
              }
              firstTime = true;
        }
        for(let key in items){
            items[key].opacity = false;
            store.commit('SET_ITEM', { key, value: false, property: 'opacity' });
        }
    } 
    else{
        if(!firstTime){
            for(let i = 0; i < group.children.length; i++){
                if(group.children[i].material.color.getHex() === 0xe834eb){
                    if(isColor){
                        group.children[i].material.color.set(group.children[i].userData.color);
                    }
                    else{
                        group.children[i].material.color.set(group.children[i].userData.normalColor);
                    }
                }
            }
            for(let key in items){ 
                if(items[key].opacity){
                    items[key].opacity = false;
                    store.commit('SET_ITEM', { key, value: false, property: 'opacity' });
                }
                //     if(group.children[i].userData.Description.includes(items[j].id)){
                //         if(items[j].opacity){
                //             group.children[i].material.opacity = 1;
                //             group.children[i].material.transparent = false;
                //             group.children[i].material.needsUpdate = true;
                //         }
                //     }
                // }
            }
        }
        for(let objectClass of objectClasses){
            if(!items[objectClass].visibility){
                items[objectClass].visibility = true;
                store.commit('SET_ITEM', { objectClass, value: true, property: 'visibility' });
                camera.layers.toggle(items[objectClass].layer);
                raycaster.layers.toggle(items[objectClass].layer);
            }
        }
        let allOn = true;
        for(let i = 0; i < levelNames.length; i++){
            if(levelNames[i].visible === false){
                allOn = false;
            }
        }
        if(!allOn){
            for(let i = 0; i < levelNames.length; i++){
                if(levelNames[i].name !== floor){
                    levelNames[i].visible = false;
                }
                else{
                    levelNames[i].visible = true;
                }
            }
            group.traverse(function(object){
                if(object.userData.Description){
                    if(object.userData.level !== floor){
                        object.material.visible = false;
                    }
                    else{
                        object.material.visible = true;
                    }
                }
            });
        }
        for(let key in items){
            if(items[key].visibility === true){
                items[key].opacity = true;
                store.commit('SET_ITEM', { key, value: true, property: 'opacity' });
            }
        }
        group.traverse(function(object){
            for(let i = 0; i < levelNames.length; i++){
                if(levelNames[i].visible === true){
                    if(object.userData.level === levelNames[i].name){
                        for(let key in items){
                            if(items[key].visibility){
                                if(object.userData.Description.includes(items[key].id)){
                                    object.material.opacity = currentTransparencyOutside / 10;
                                    object.material.transparent = true;
                                    object.material.needsUpdate = true;
                                }
                            }
                        }
                    }
                }
            }
        });
        for(let object of objects){
            object.material.color.set(0xe834eb);
            object.material.opacity = 1;
            object.material.transparent = false;
            object.needsUpdate = true;
            firstTime = false;
        }
    }
    return {firstTime, isColor}
}
export { calling };